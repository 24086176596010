const programParserForApi = (data) => {
  const { _id, items, ...rest } = data;

  const parsedItems = items.map((item) => ({
    ...item,
    item_id: item.item_id.value,
    onModel: item.onModel.model,
  }));

  const body = {
    ...rest,
    items: parsedItems,
  };

  return {
    _id,
    body,
    data,
  };
};

export default programParserForApi;
