// These fonts are GoogleFonts and so are able to be WebFont Optimised by Next.js
// https://nextjs.org/docs/basic-features/font-optimization
const PoppinsLightRegularMediumSemiBold =
  "https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap";
const TekoRegularMedium =
  "https://fonts.googleapis.com/css2?family=Teko:wght@400;500&display=swap";

export const WebFontUrls = [
  PoppinsLightRegularMediumSemiBold,
  TekoRegularMedium,
];
