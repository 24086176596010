import React from "react";

function SvgComponent(props) {
  return (
    <svg id="icon-Pilates-Ball" viewBox="0 0 1024 1024" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M527.583 16.696v0c-117.55 0.286-225.239 42.275-309.109 111.947l0.796-0.643-5.565 4.452-22.817 20.035s0 0 0 0c-91.884 88.62-148.945 212.811-148.945 350.33 0 268.673 217.802 486.475 486.475 486.475 131.154 0 250.186-51.901 337.677-136.285l-0.146 0.14c7.235-7.235 13.357-14.47 20.035-21.704l13.357-14.47 8.348-11.13c64.087-79.869 103.163-182.206 104.068-293.641l0.001-0.203c0.065-2.962 0.103-6.453 0.103-9.953 0-130.237-51.599-248.423-135.47-335.228l0.133 0.138c-88.356-91.694-211.834-149.020-348.708-150.259l-0.231-0.002zM527.583 935.513c-0.413 0.001-0.901 0.002-1.39 0.002-237.895 0-430.748-192.852-430.748-430.748 0-104.328 37.090-199.994 98.803-274.53l-0.578 0.719c48.027 151.81 129.772 281.344 236.824 384.869l0.254 0.244c97.42 101.508 218.227 179.81 353.806 226.307l6.264 1.867c-71.792 56.893-163.682 91.27-263.601 91.27-0.067 0-0.134 0-0.201 0h0.011zM861.496 775.235l-13.913 15.026c-2.988 4.126-6.093 7.76-9.461 11.131v0c-145.2-43.348-269.218-120.412-367.624-222.362l-0.237-0.247c-109.493-104.647-190.081-238.661-229.139-389.399l-1.261-5.732 10.017-8.904 6.678-7.791 12.243-8.904c40.573 157.196 120.45 292.118 228.604 398.346l0.127 0.124c99.68 98.246 223.537 172.231 361.828 212.211l6.033 1.493c-1.115 1.44-2.053 3.094-2.738 4.875l-0.045 0.133zM904.904 709.565v0c-2.138 3.1-4.352 6.738-6.369 10.499l-0.309 0.631c-141.845-37.233-263.957-107.928-361.918-203.304l0.179 0.174c-105.158-103.646-181.886-235.867-217.596-384.074l-1.117-5.491c60.436-34.322 132.76-54.551 209.809-54.551 237.895 0 430.748 192.852 430.748 430.748 0 0.2 0 0.4 0 0.599v-0.031c0 0.099 0 0.216 0 0.333 0 74.987-19.387 145.446-53.421 206.638l1.108-2.171z"></path>
    </svg>
  );
}

export default SvgComponent;
