import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      width="1rem"
      height="1rem"
      viewBox="0 0 981 1024"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path d="M968.082 949.443l-246.389-246.389c69.642-74.34 112.415-174.591 112.415-284.832 0-230.334-186.722-417.056-417.056-417.056s-417.056 186.722-417.056 417.056c0 230.334 186.722 417.056 417.056 417.056 0.206 0 0.412 0 0.618 0h-0.032c0.039 0 0.085 0 0.131 0 88.976 0 171.38-28.138 238.799-76.001l-1.278 0.861 251.049 251.049c7.78 7.265 18.26 11.725 29.782 11.725 24.127 0 43.686-19.559 43.686-43.686 0-11.522-4.46-22.001-11.748-29.807l0.023 0.025zM743.245 417.638c0 179.827-145.779 325.606-325.606 325.606s-325.606-145.779-325.606-325.606c0-179.827 145.779-325.606 325.606-325.606v0c179.694 0.331 325.276 145.912 325.606 325.575v0.032z" />
    </svg>
  );
}

export default SvgComponent;
