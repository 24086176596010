import React from "react";
import { Box } from "../Box";
import { Text } from "../Text";

export const InputTextErrorAdornment: React.FC = () => {
  return (
    <Box
      bg="secondary"
      borderRadius={"md"}
      width={24}
      height={24}
      alignItems="center"
      justifyContent="center"
      alignContent="center"
    >
      <Text variant="subtitle" color="white">
        !
      </Text>
    </Box>
  );
};
