import { useCallback } from "react";
import { SetupIntent } from "@stripe/stripe-js";
import { useStoreActions } from "../../../../store";
import { useLocale } from "../../../hooks/useLocale";
import { usePayments } from "../../Payments/components/PaymentContext";
import { useGetUserSubscriptionData } from "../../Users/hooks/useUserSubscriptionRequest";
import {
  useCreateSetupIntent,
  usePurchaseSubscription,
} from "./useStripeRequest";
import {
  CardPaymentAction,
  CardSetupAction,
  PurchaseSubscriptionParams,
} from "../types";
import { waitForUserSubscriptionToActivate } from "../waitForUserSubscriptionToActivate";
import {
  getAffiliateDataFromCookie,
  getAffiliateDataFromQueryString,
} from "../../../utils/affiliate";
import { useUser } from "../../../hooks";
import { useToggle } from "../../toggle/useToggle";

export const useStripeActions = () => {
  const locale = useLocale();
  const { stripe, elements } = usePayments();
  const user = useUser();
  const featureRevenueCatIsSubscribedWeb = useToggle(
    "featureRevenueCatIsSubscribedWeb",
  );

  const updateSubscriptionData = useStoreActions(
    (actions) => actions.auth.updatedSubscriptionData,
  );

  const { mutateAsync: fetchUserSubscriptionData } =
    useGetUserSubscriptionData();

  const { mutateAsync: purchaseSubscriptionRequest } =
    usePurchaseSubscription();

  const { mutateAsync: createSetupIntent } = useCreateSetupIntent();

  const setupCard = useCallback(
    async ({ cardElement }: CardSetupAction) => {
      const { clientSecret } = await createSetupIntent();
      return stripe.confirmCardSetup(clientSecret, {
        payment_method: { card: cardElement },
      });
    },
    [stripe, createSetupIntent],
  );

  const confirmCardPayment = useCallback(
    async ({ paymentMethod, clientSecret }: CardPaymentAction) => {
      return stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethod,
      });
    },
    [stripe],
  );

  const makeSubscriptionPurchase = async ({
    setupIntent,
    discountCode,
    selectedPlanId,
    referral,
  }: Omit<PurchaseSubscriptionParams, "cardElement"> & {
    setupIntent: SetupIntent;
  }) => {
    const paymentMethodId = setupIntent.payment_method as string;

    const affiliate =
      getAffiliateDataFromQueryString() || getAffiliateDataFromCookie();

    const { subscription, clientSecret } = await purchaseSubscriptionRequest({
      iso: locale.iso,
      paymentMethodId,
      planId: selectedPlanId,
      ...(discountCode && { coupon: discountCode }),
      referral,
      affiliate,
    });

    if (subscription.status === "incomplete" && clientSecret) {
      // Incomplete subscriptions are subscriptions without trials
      // these payment needs to be confirmed from the frontend
      // as per the "paymentIntent flow"
      const { error } = await stripe.confirmPayment({
        clientSecret,
        redirect: "if_required",
        confirmParams: {
          payment_method: paymentMethodId,
          return_url: `https://${window.location.host}/checkout-success`,
        },
      });

      if (error) return { error };
    }

    const { success, userSubscription } =
      await waitForUserSubscriptionToActivate(
        fetchUserSubscriptionData,
        user?.revenueCatUserId,
        featureRevenueCatIsSubscribedWeb,
      );
    if (success) {
      await updateSubscriptionData({ user_subscription: userSubscription });
    }

    return { subscription };
  };

  const purchaseSubscriptionWithCard = async ({
    cardElement,
    ...params
  }: PurchaseSubscriptionParams) => {
    const { setupIntent, error } = await setupCard({
      cardElement,
    });

    if (error) return { error };

    return makeSubscriptionPurchase({ setupIntent, ...params });
  };

  const purchaseSubscriptionWithElements = async (
    params: Omit<PurchaseSubscriptionParams, "cardElement">,
  ) => {
    const { error: submitError } = await elements.submit();
    if (submitError) {
      return { error: submitError };
    }

    const { clientSecret: setupIntentSecret } = await createSetupIntent();

    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      clientSecret: setupIntentSecret,
      redirect: "if_required",
      confirmParams: {
        return_url: `https://${window.location.host}/checkout-success`,
      },
    });

    if (error) return { error };

    return makeSubscriptionPurchase({ setupIntent, ...params });
  };

  return {
    purchaseSubscriptionWithCard,
    purchaseSubscriptionWithElements,

    setupCard,
    confirmCardPayment,

    isStripeLoading: !stripe || !elements,
  };
};
