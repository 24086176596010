import React from "react";

function SvgComponent(props) {
  return (
    <svg x={0} y={0} viewBox="0 0 101.7 101.1" xmlSpace="preserve" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M95.1 60.3L65.7 59c-2-.1-3.8.6-5.2 1.8-1.1 1-1.7 2.4-1.8 3.9v.7h-.3c-7.2-.4-9.8 6.7-12.5 14.3-2.3 6.4-4.9 13.7-10.8 16.9-4.3 2.3-10.7 2-16-.7-5.6-2.8-9.2-7.8-10.1-14.1-1.6-11.4 3-18 11.5-30.1l.9-1.3c.8-1.2 1.6-2.4 2.3-3.6 1.6.8 3.4 1.4 5.2 1.8 8.2 2 15.7 2.2 27.4-3 1 2 3.3 3.3 5.8 3.3.7 0 1.5-.1 2.3-.3l28-8.7c1.9-.6 3.4-1.8 4.2-3.5.7-1.4.8-2.9.3-4.3l-.8-2.4c-1-3.1-4.8-4.7-8.4-3.6l-28 8.7c-1.9.6-3.4 1.8-4.2 3.5-.7 1.4-.8 2.9-.3 4.3l.1.3c-11.1 5-17.9 4.9-25.7 2.9-1.5-.4-3-.9-4.5-1.6 2.4-4.8 4.2-9.9 5.8-14.8 3-9 2.1-18.3-2.2-23.8-3.2-4.1-8.2-6-14.4-5.5C8 .6 1.1 4.6.1 12.4c-.7 5.9 1.7 12.6 7.3 20 3.2 4.3 7.9 9.5 13.8 12.9-.6 1.1-1.3 2.2-2.1 3.3l-.9 1.3C9.6 62.3 4.4 69.7 6.2 82.2c1 7.3 5.2 13.1 11.7 16.3 3.3 1.7 7.1 2.5 10.6 2.5 3 0 5.9-.6 8.2-1.9 6.9-3.7 9.7-11.6 12.2-18.5 2.8-7.8 4.7-12.5 9.5-12.3.2 0 .3 0 .5-.1.5 2.8 3.2 5 6.6 5.2l29.3 1.3h.3c1.8 0 3.6-.7 4.8-1.8 1.1-1 1.7-2.4 1.8-3.9v-2.6c.1-3.2-2.9-5.9-6.6-6.1zM58.3 39.6c.5-.9 1.4-1.6 2.5-2l28-8.7c.5-.1.9-.2 1.4-.2 1.5 0 2.8.7 3.2 1.9l.8 2.4c.3.8 0 1.5-.2 1.9-.5.9-1.4 1.6-2.5 2l-28 8.7c-2 .6-4.1-.2-4.6-1.7l-.8-2.4c-.2-.8 0-1.5.2-1.9zm-48.5-9c-5-6.7-7.3-12.9-6.7-17.9.8-6.1 6.4-9.3 11.7-9.7h1.7c4.4 0 7.7 1.5 10 4.4 3.6 4.6 4.3 13.1 1.7 21-1.6 4.8-3.3 9.7-5.6 14.3-5.4-3.2-9.8-8-12.8-12.1zM98.7 69c0 .8-.5 1.4-.8 1.8-.7.7-1.8 1.1-3 1l-29.3-1.3c-2.1-.1-3.8-1.5-3.8-3.2v-2.6c0-.8.5-1.4.8-1.8.7-.6 1.7-1 2.8-1h.2l29.3 1.3c2.1.1 3.8 1.5 3.8 3.2V69z" />
    </svg>
  );
}

export default SvgComponent;
