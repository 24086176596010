export type AppStage = "local" | "staging" | "production";

export const URLS: Record<AppStage, string> = {
  local: "http://localhost:3000",
  staging: "https://app.staging.kicapp.com",
  production: "https://app.kicapp.com",
};

export const getBaseDomain = ({ appStage }: { appStage: AppStage }) =>
  URLS[appStage].split("//")[1].split(":")[0];

export const API_URLS: Record<AppStage, string> = {
  local: "http://localhost:7070",
  staging: "https://api.rebuild.test.keepitcleaner.com.au",
  production: "https://api.rebuild.keepitcleaner.com.au",
};
