import axios from "axios";

import { KIC_API } from "../makeSendRequest";
import { TokenResponse } from "./types";

export const refreshAuthToken = (refreshToken: string) => {
  if (!fetchTokenPromise) {
    fetchTokenPromise = fetchNewToken(refreshToken);
  }

  return fetchTokenPromise.then((response) => {
    fetchTokenPromise = null;
    return response;
  });
};

let fetchTokenPromise: Promise<TokenResponse> | null = null;

const fetchNewToken = async (refreshToken: string) => {
  const { data } = await axios.post<TokenResponse>(
    `${KIC_API}/api/v2/users/token`,
    {
      token: refreshToken,
    },
  );

  return data;
};
