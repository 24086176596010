import { useRouter } from "next/router";

export const useUrlParam = ({
  paramName,
  defaultValue,
}: {
  paramName: string;
  defaultValue?: string;
}) => {
  const router = useRouter();
  return (router.query[paramName] as string) ?? defaultValue;
};
