export type AuthState = {
  authData: any;
  subscriptions: any[];
  unauthGlobals: any;
  selectedSubscription: any;
  promoCode: string;
  subscriptionStatus: any;
  subscriptionData: {
    user_subscription: any;
    stripe: any;
    plan: any;
  };
};

const initialState: AuthState = {
  authData: {},
  subscriptions: [],
  unauthGlobals: {},
  selectedSubscription: {},
  promoCode: "",
  subscriptionStatus: {},
  subscriptionData: {
    user_subscription: {},
    stripe: {},
    plan: {},
  },
};

export default initialState;
