import React from "react";

import { Box, ColorThemeTokens, Text, TextVariantToken } from "../../../../ui";
import { Discount, Plan } from "../../../../api";
import { usePlanPrice } from "../../../Payments/hooks/usePlanPrice";

const InnerPlanPrice: React.FC<{
  plan?: Plan;
  discount?: Discount;
  variant?: TextVariantToken;
  fontSize?: number | number[];
  color?: ColorThemeTokens;
  withDecimals?: boolean;
}> = ({
  plan,
  discount,
  withDecimals = false,
  variant = "intro",
  fontSize,
  color,
}) => {
  const { formattedDiscountPrice, formattedOriginalPrice } = usePlanPrice({
    plan,
    discount,
    withDecimals,
  });

  const fontStyles = {
    variant,
    fontSize,
    color,
  };

  return !discount ? (
    <Text {...fontStyles}>{formattedOriginalPrice}</Text>
  ) : (
    <Box alignItems="flex-end">
      <Text
        {...fontStyles}
        color="contentContrastLow"
        textDecoration="line-through"
      >
        {formattedOriginalPrice}
      </Text>

      <Text {...fontStyles}>{formattedDiscountPrice}</Text>
    </Box>
  );
};

export const PlanPrice = React.memo(InnerPlanPrice);
