import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      x={0}
      y={0}
      viewBox="0 0 100.8 100"
      xmlSpace="preserve"
      width="1rem"
      height="1rem"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path d="M88.4 68.7c-5.1-1.8-7.1-3.2-9.8-8.5-3.5-6.9-7.3-14.8-8.5-21.7-.3-1.9-.4-4-.5-6.3-.2-3.9-.3-7.9-1.8-11.5-.9-2.2-2.2-3.6-4-4.3-3-1.1-5.9.5-8 1.7-.3.1-.5.3-.7.4-1.4.7-2.5 1.7-3.7 2.6-1.2 1-2.6 2.2-3.4 2.1-1.6-.1-5.1-1.6-7.8-3.3-1.5-1-2.7-2.9-4.2-5.4l-.6-1.1c-1.1-1.8-3.7-6-8.4-6.2-3.7-.1-6.5 2.3-8.3 4.1-1.8 1.8-2.9 3.6-3.9 5.2-.9 1.5-1.7 2.7-2.8 3.8-.7.6-1.7 1.4-2.8 2.3-3.5 2.7-7.5 5.8-8.5 9.5-3.2 12.3 8.2 19.5 17.4 25.2 2 1.2 3.8 2.4 5.5 3.6 5.8 4.1 11 7.6 17 11.7 3.5 2.3 7.2 4.9 11.5 7.8 11.3 7.7 18.6 11.5 29.2 12.1.7 0 1.4.1 2 .1 12.4 0 15.2-6.9 15.7-11.2.8-5.2-3.3-10.1-10.6-12.7zM12.2 26.6c1.2-.9 2.3-1.8 3.2-2.6 1.6-1.5 2.6-3.2 3.6-4.8.9-1.5 1.8-2.9 3.2-4.3 1.1-1.1 2.8-2.6 4.5-2.6h.1c1.4 0 2.7 1.1 4.3 3.8l.6 1c1.8 2.9 3.3 5.5 5.8 7.1 2.2 1.4 6.9 3.9 10.2 4.1 2.7.1 4.9-1.7 6.9-3.3.9-.8 1.9-1.6 2.8-2 .3-.1.5-.3.8-.4 1.2-.6 3.1-1.7 4-1.4.3.1.7.6 1 1.4 1.1 2.8 1.3 6.4 1.4 9.8v1l-10.4 4.2c-1.3.5-1.9 2-1.4 3.3.4 1 1.3 1.6 2.3 1.6.3 0 .6-.1.9-.2l8.9-3.6c0 .3.1.5.1.8.4 2.2 1 4.6 1.8 6.9l-7.1 2.9c-1.3.5-1.9 2-1.3 3.3.4 1 1.3 1.5 2.3 1.5.3 0 .6-.1 1-.2l6.9-2.9c1.6 4 3.6 7.9 5.4 11.6 3.5 6.7 6.7 8.8 12.6 10.9 4.9 1.7 7.8 4.6 7.4 7.4 0 .2-.1.5-.1.7-1 .2-6.9 1.5-13.7-.2-6.9-1.7-12.5-6.2-19.6-11.9l-2.2-1.8c-8.6-6.9-16.7-13-24.6-18.8-1.9-1.4-3.4-2.5-4.8-3.5-3.9-2.9-6.1-4.5-10.5-8.3-4.1-3.5-7.1-7.3-8.3-8.9.7-.6 1.4-1.1 2-1.6zm69.4 60.9C73 87 67 84.5 55 76.3c-4.3-3-8.1-5.5-11.6-7.8-6-4.1-11.2-7.6-17-11.6-1.8-1.2-3.7-2.4-5.7-3.7C10.5 46.7 3.4 41.5 5.5 33.4c.2-.6.6-1.2 1.1-1.9 1.5 1.9 4.6 5.7 8.8 9.3 4.6 3.9 6.8 5.6 10.8 8.5 1.3 1 2.9 2.1 4.8 3.5 7.8 5.8 15.9 11.9 24.5 18.7l2.2 1.8c7.6 6.1 13.5 10.9 21.5 12.9 3.1.7 5.9 1 8.4 1-1.6.3-3.5.5-6 .3z" />
    </svg>
  );
}

export default SvgComponent;
