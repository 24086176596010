import React from "react";

const EmailIcon = ({ alt, ...props }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="1024"
    height="1024"
    viewBox="0 0 1024 1024"
    {...props}
  >
    <title>{alt}</title>

    <path d="M890.435 180.313h-732.383c-0.001 0-0.001 0-0.002 0-53.788 0-97.391 43.604-97.391 97.391 0 0.196 0.001 0.391 0.002 0.587v-0.030 485.843c0 53.788 43.604 97.391 97.391 97.391v0h732.383c53.788 0 97.391-43.604 97.391-97.391v0-485.843c0.001-0.165 0.002-0.361 0.002-0.557 0-53.788-43.604-97.391-97.391-97.391-0.001 0-0.001 0-0.002 0v0zM890.435 235.965c0.154-0.002 0.336-0.004 0.518-0.004 5.469 0 10.65 1.233 15.281 3.435l-0.216-0.092-386.226 307.2-373.426-308.313c1.755-0.252 3.783-0.395 5.843-0.395s4.088 0.144 6.072 0.421l-0.229-0.026zM890.435 805.287h-732.383c-23.052 0-41.739-18.687-41.739-41.739v0-477.496l402.922 333.913 410.157-328.348v473.6c-0.835 21.403-17.666 38.62-38.828 40.063l-0.129 0.007z"></path>
  </svg>
);

export default EmailIcon;
