import urlSlug from "url-slug";

const ingredientParserForApi = (data) => {
  const { _id, categories, title, internalName } = data;

  const categoryIds = categories.map((category) => category._id);

  const body = {
    title: title.trim(),
    internalName: internalName.trim(),
    name: urlSlug(title),
    categories: categoryIds,
  };

  return {
    _id,
    body,
    data,
  };
};

export default ingredientParserForApi;
