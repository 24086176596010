import { SeriesSchema } from "../../features/Series/schemas";
import { SendRequest } from "../makeSendRequest";

type ListPaginatedSeries = {
  results: SeriesSchema[];
  hasNext?: boolean;
  hasPrevious?: boolean;
  next: string | null;
  previous: string | null;
};

export type ListSeriesRequestQueryParams = {
  limit?: number;
  search?: string;
  next?: string;
};

export const createSeriesApi = (sendRequest: SendRequest) => ({
  fetchSeries: async (id: string) => {
    return await sendRequest<SeriesSchema>({
      method: "get",
      path: `series/${id}`,
      version: "v3",
    });
  },
  list: async ({ next, limit, search }: ListSeriesRequestQueryParams) => {
    const params = new URLSearchParams();

    if (next) {
      params.append("next", next);
    }

    if (limit) {
      params.append("limit", limit.toString());
    }

    if (search) {
      params.append("search", search);
    }

    const queryString = params.toString();

    const response = await sendRequest<ListPaginatedSeries>({
      method: "get",
      path: `series${params.size > 0 ? `?${queryString}` : ""}`,
      version: "v3",
    });

    return response;
  },

  deleteSeries: async (id: string) =>
    sendRequest<{ result: boolean }>({
      method: "delete",
      path: `series/${id}`,
      version: "v3",
    }),
  createSeries: async (series: SeriesSchema) =>
    sendRequest<SeriesSchema & { _id: string }>({
      method: "post",
      path: "series",
      version: "v3",
      data: series,
    }),
  updateSeries: async (id: string, series: Partial<SeriesSchema>) => {
    return sendRequest<boolean>({
      method: "put",
      path: `series/${id}`,
      version: "v3",
      data: series,
    });
  },
});
