import React from "react";

import { useAuth } from "../../AuthWrapper/hooks";

import { Container, Name, Wrapper } from "./styles";
import { useApiStage } from "../../../hooks";
import { StageNames, getEnvConfig } from "../../../utils/appConfig/envConfig";
import { ApiStage, DatabaseStage } from "../../../api";

const verifyStage = (
  webStage: StageNames,
  apiStage: ApiStage,
  databaseStage: DatabaseStage,
) => {
  if (
    databaseStage === "production" &&
    apiStage === "production" &&
    webStage === "production"
  )
    return "production";

  if (databaseStage === "development") {
    if (apiStage === "staging" && webStage === "staging") return "staging";
    if (apiStage === "development" && webStage === "local")
      return "development";
  }
};

const AdminHeader = () => {
  const {
    user: { firstName },
    logout,
  } = useAuth();

  const env = getEnvConfig();
  const { data } = useApiStage();
  const stage = verifyStage(env?.appStage, data?.stage, data?.databaseStage);
  const displayStage =
    stage?.toUpperCase() ||
    `⚠️ web: ${env?.appStage?.toUpperCase()} - api: ${data?.stage?.toUpperCase()} - db: ${data?.databaseStage?.toUpperCase()} ⚠️`;
  const color = !stage ? "orange" : stage === "production" ? "red" : "green";

  return (
    <Container>
      <Wrapper>
        <Name style={{ color }}>{displayStage}</Name>
        <Name>{firstName}</Name>
        <Name onClick={logout}>Log out</Name>
      </Wrapper>
    </Container>
  );
};

export default AdminHeader;
