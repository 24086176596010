import React from "react";
import { SpaceProps } from "styled-system";
import { Box } from "../Box";
import { Theme } from "../theme";

type DividerProps = SpaceProps<Theme>;

export const Divider: React.FC<DividerProps> = (props) => (
  <Box
    borderColor="borderLight"
    borderTopWidth="sm"
    borderTopStyle="solid"
    width="100%"
    my="md"
    {...props}
  />
);
