import { useMemo } from "react";
import { Plan } from "../../../api";
import { useLocale } from "../../../hooks/useLocale";

type PlanLocaleConfig = {
  plan?: Plan;
};

export const usePlanLocale = ({ plan }: PlanLocaleConfig) => {
  const locale = useLocale();
  return useMemo(
    () => ({ planLocale: locale?.getPlanLocale(plan) }),
    [plan, locale],
  );
};
