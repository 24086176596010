import Cookies from "js-cookie";
import { isString } from "lodash";

const COOKIE_NAME = "affiliateData";
const COOKIE_EXPIRATION_DAYS = 30;

export type AffiliateData =
  | {
      platform: "AWIN";
      awc: string;
    }
  | {
      platform: "CommissionFactory";
      cfclick: string;
    };
export type AffiliatePlatform = AffiliateData["platform"];

export const getAffiliateDataFromQueryString = (): AffiliateData => {
  const urlParams = new URLSearchParams(window.location.search);

  const awc = urlParams.get("awc");
  if (awc) {
    return {
      platform: "AWIN",
      awc,
    };
  }

  const cfclick = urlParams.get("cfclick");
  if (cfclick) {
    return {
      platform: "CommissionFactory",
      cfclick,
    };
  }

  return null;
};

export const getAffiliateDataFromCookie = (): AffiliateData => {
  try {
    const cookieString = Cookies.get(COOKIE_NAME);
    const cookieObject = JSON.parse(cookieString);

    if (cookieObject.platform === "AWIN" && isString(cookieObject.awc)) {
      return { platform: cookieObject.platform, awc: cookieObject.awc };
    }

    if (
      cookieObject.platform === "CommissionFactory" &&
      isString(cookieObject.cfclick)
    ) {
      return {
        platform: cookieObject.platform,
        cfclick: cookieObject.cfclick,
      };
    }
  } catch {}

  return null;
};

export const refreshAffiliateCookie = () => {
  const affiliateData = getAffiliateDataFromQueryString();
  if (affiliateData)
    Cookies.set(COOKIE_NAME, JSON.stringify(affiliateData), {
      expires: COOKIE_EXPIRATION_DAYS,
    });
};

export const clearAffiliateCookie = () => {
  Cookies.remove(COOKIE_NAME);
};
