import { getEnvConfig } from "./appConfig/envConfig";

const env = getEnvConfig();

export const initBranchSdk = () => {
  (window as any).branch.init(env.kicweb_branch_key);
  return (window as any).branch;
};

/**
 * @returns {Promise<{data: string}>}
 */
export const getBrowserFingerprint = () =>
  new Promise((resolve, reject) => {
    const branch = initBranchSdk();

    if (!branch?.getBrowserFingerprintId) {
      reject(new Error("cannot get browser fingerprint"));
    }

    branch.getBrowserFingerprintId((error, data) => {
      if (data) {
        resolve({ data });
      } else {
        reject(new Error(error));
      }
    });
  });
