import { KIC_API } from "../../../src/api/index";
import addToPosts from "../../../src/utils/actionModels/addToPosts";
import createPost from "../../../src/utils/actionModels/createPost";
import fetchPost from "../../../src/utils/actionModels/fetchPost";
import fetchPosts from "../../../src/utils/actionModels/fetchPosts";
import removeFromPosts from "../../../src/utils/actionModels/removeFromPosts";
import removePost from "../../../src/utils/actionModels/removePost";
import setState from "../../../src/utils/actionModels/setState";
import updatePost from "../../../src/utils/actionModels/updatePost";
import parser from "../../../src/utils/parsersForEndpoints/ingredient";

const endpoint = `${KIC_API}/api/v1/ingredients`;

const actions = {
  // actions
  addToPosts: addToPosts(),
  removeFromPosts: removeFromPosts(),
  setState: setState(),

  // thunks
  createIngredient: createPost({
    key: "createIngredient",
    endpoint,
    parser,
  }),
  fetchIngredient: fetchPost({ key: "fetchIngredient", endpoint }),
  fetchIngredients: fetchPosts({ key: "fetchIngredients", endpoint }),
  removeIngredient: removePost({ key: "removeIngredient", endpoint }),
  updateIngredient: updatePost({ key: "updateIngredient", endpoint, parser }),
};

export default actions;
