import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { StyledModal } from "./styles";
import PurchaseSubscription from "../profilePage/membership/Modals/PurchaseSubscription";
import { getEnvConfig } from "../../../utils/appConfig/envConfig";
import { CheckoutProvider } from "../../../features/Checkout/CheckoutContext";

const env = getEnvConfig();
const stripePromise = loadStripe(env.kic_stripe_publishable);

const Paywall = ({ isOpen, toggleModal }) => {
  return (
    <CheckoutProvider>
      <StyledModal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        closeTimeoutMS={500}
      >
        <PurchaseSubscription
          stripePromise={stripePromise}
          title="Upgrade to Premium"
          onFinish={toggleModal}
          analyticsSource="paywall"
        />
      </StyledModal>
    </CheckoutProvider>
  );
};

export default Paywall;
