import { loadStripe, Stripe } from "@stripe/stripe-js";
import { getAppConfig } from "../../utils/appConfig/appConfig";

let stripePromise: Promise<Stripe>;
export const getStripe = async () => {
  if (!stripePromise) {
    const appConfig = await getAppConfig();
    stripePromise = loadStripe(appConfig.kic_stripe_publishable);
  }
  return stripePromise;
};
