import Cookies from "js-cookie";

const COOKIE_NAME = "partnerData";
const COOKIE_EXPIRATION_DAYS = 30;

const PARTNERS = ["nib", "mecca"] as const;

export type Partner = (typeof PARTNERS)[number];
export type PartnerData = {
  partner: Partner;
};

export const getPartnerFromQueryString = (): PartnerData => {
  const urlParams = new URLSearchParams(window.location.search);

  const partner = urlParams.get("partner");
  if (PARTNERS.includes(partner as Partner)) {
    return {
      partner: partner as Partner,
    };
  }

  return null;
};

export const getPartnerDataFromCookie = (): PartnerData => {
  const cookieString = Cookies.get(COOKIE_NAME);
  const cookieObject =
    typeof cookieString === "string" &&
    (JSON.parse(cookieString) as PartnerData);

  if (PARTNERS.includes(cookieObject.partner as Partner)) {
    return { partner: cookieObject.partner };
  }

  return null;
};

export const refreshPartnerCookie = () => {
  const partnerData = getPartnerFromQueryString();
  if (partnerData)
    Cookies.set(COOKIE_NAME, JSON.stringify(partnerData), {
      expires: COOKIE_EXPIRATION_DAYS,
    });
};

export const clearPartnerCookie = () => {
  Cookies.remove(COOKIE_NAME);
};
