import React from "react";

import { Container, StyledIcon, Text } from "./styles";

interface Props {
  error: Error;
  info: React.ErrorInfo;
  clearError: () => void;
}

const ErrorPage = () => (
  <Container>
    <StyledIcon name="kic-icon" />
    <Text>Sorry! An unexpected error occurred</Text>
  </Container>
);

export const ErrorBoundaryPage = ({ info }: Props) => (
  <Container>
    <StyledIcon name="kic-icon" />
    <Text>An error occurred on client {info}</Text>
  </Container>
);

export default ErrorPage;
