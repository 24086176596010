import { SendRequest } from "../makeSendRequest";
import {
  ExerciseMedia,
  ExerciseMediaResponse,
  InsertExerciseMedia,
} from "./types";

export const createExerciseMediaApi = (sendRequest: SendRequest) => ({
  list: async (search?: string) => {
    return sendRequest<{ result: ExerciseMedia[] }>({
      method: "get",
      path: "exercise-media",
      version: "v3",
      params: {
        search,
      },
    });
  },
  view: async (id: string) =>
    sendRequest<ExerciseMediaResponse>({
      method: "get",
      path: `exercise-media/${id}/swaps`,
      version: "v3",
    }),
  delete: async (id: string) =>
    sendRequest<{ result: boolean }>({
      method: "delete",
      path: `exercise-media/${id}`,
      version: "v3",
    }),
  createOrUpdate: async (exerciseMedia: Partial<InsertExerciseMedia>) =>
    sendRequest<ExerciseMedia>({
      method: "put",
      path: "exercise-media",
      version: "v3",
      data: exerciseMedia,
    }),
});
