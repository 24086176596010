import { useStoreActions, useStoreRehydrated, useStoreState } from "easy-peasy";
import React, { useState } from "react";
import { Box, Button, Text } from "../../ui";

const CookieConsentPopup = () => {
  const rehydrated = useStoreRehydrated();

  // store state
  const hasShownConsent = useStoreState(
    (state) => state.profile.hasShownConsent,
  );

  // store actions
  const setShownConsent = useStoreActions(
    (actions) => actions.profile.setShownConsent,
  );
  const setAllowTracking = useStoreActions(
    (actions) => actions.profile.setAllowTracking,
  );

  // component states
  const [show, setShow] = useState(!hasShownConsent);

  const onClickAccept = () => {
    setShow(false);
    setShownConsent(true);
  };

  const onClickDecline = () => {
    setShow(false);
    setShownConsent(true);
    setAllowTracking(false);
  };

  return (
    rehydrated &&
    show && (
      <Box position="fixed" bottom="2rem" width={"100%"} zIndex={1}>
        <Box flex={1} alignItems={"center"} mx="lg">
          <Box
            backgroundColor="backgroundPrimary"
            p="sm"
            py="md"
            borderRadius="md"
            maxWidth={[359, 600, 848, 1000]}
            boxShadow="sm"
            mx="sm"
          >
            <Box
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              flexWrap={["wrap", "nowrap"]}
            >
              <Box>
                <Text flex={2} variant="labelMedium" mb="sm">
                  Your privacy
                </Text>
                <Text variant="body">
                  We use cookies to personalise your journey and improve your
                  overall experience. By using Kic, you agree to our{" "}
                  <a href="/legal">Privacy Policy</a>.
                </Text>
              </Box>
              <Box flexDirection="row" mt={["md", "none"]}>
                <Button
                  mx={"sm"}
                  data-cy="reject-policy"
                  px="md"
                  text="Reject"
                  onClick={onClickDecline}
                  variant="buttonFilledLight"
                />
                <Button
                  data-cy="privacy-accept-button"
                  px="md"
                  text="Accept"
                  onClick={onClickAccept}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  );
};

export default CookieConsentPopup;
