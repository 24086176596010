import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 100.8 100.4"
      xmlSpace="preserve"
      width="1rem"
      height="1rem"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path d="M70.1 55.3c-2-2.1-4.4-3.8-7-5.1-.9-.5-1.9-1-3.1-1.6-3.9-1.9-7.9-3.6-11.7-5.1-5.8-2.4-12.1-4.8-19.9-7.8-2.7-1-6.2-2.3-9.7-3.6-.9-.3-1.8-.1-2.5.5s-1 1.6-.8 2.5c.7 2.9 1.5 6 2.5 10.1 2.1 8.1 3.9 14.6 5.6 20.6 1.1 3.9 2.4 8 3.8 12.1.4 1.2.8 2.3 1.2 3.2 1 2.7 2.4 5.3 4.3 7.5.2.2.4.5.6.7 4.5 5 10.6 7.9 17.3 8.3h1.4c6.2 0 12.1-2.3 16.7-6.4 10.3-9.2 11.1-25.1 1.9-35.4-.2-.1-.4-.3-.6-.5zm-4.6 32.3c-4 3.6-9.1 5.4-14.5 5.1-5.3-.3-10.3-2.6-13.8-6.6-.2-.2-.3-.4-.5-.6a20.6 20.6 0 01-3.5-6.1v-.1c-.4-.9-.7-1.9-1.2-3.1-1.4-4-2.6-8-3.7-11.9-1.7-6-3.5-12.5-5.6-20.5-.5-2-1-3.8-1.4-5.5 1.9.7 3.8 1.4 5.3 2 7.8 2.9 14 5.4 19.8 7.7 3.7 1.5 7.6 3.1 11.4 5 1.2.6 2.1 1 2.9 1.5 0 0 .1 0 .1.1 2.1 1 4 2.4 5.7 4.1.2.2.3.4.5.5 7.4 8.3 6.7 21-1.5 28.4zM85.4 22.1c-.6-4.6-3-8.7-6.7-11.5-.2-.1-.4-.3-.5-.4-1.7-1.2-3.5-2-5.4-2.5-.7-.2-1.5-.4-2.2-.6-2.8-.7-5.6-1.3-8.2-1.9-4.1-.8-8.5-1.6-13.9-2.5-2.3-.4-4.5-.8-6.8-1.1-.9-.1-1.8.2-2.4 1s-.7 1.7-.3 2.5c1 2.1 1.9 4.2 2.9 6.2 2.3 5 4.3 9 6.2 12.7 1.2 2.4 2.5 4.9 4 7.4.4.7.8 1.3 1.2 2 1 1.7 2.4 3.3 3.9 4.5.2.1.3.3.5.4 3 2.3 6.7 3.5 10.4 3.5.8 0 1.6-.1 2.4-.2 4.6-.6 8.7-3 11.5-6.7 2.8-3.6 4-8.2 3.4-12.8zM78.1 32c-2 2.6-4.9 4.3-8.2 4.8-3.3.5-6.5-.4-9.2-2.4-.1-.1-.2-.2-.4-.3-1.1-.9-2.1-2-2.8-3.3 0 0 0-.1-.1-.1-.4-.6-.8-1.2-1.1-1.8-1.4-2.4-2.7-4.8-3.9-7.2-1.9-3.7-3.8-7.6-6.2-12.6-.3-.6-.6-1.3-.9-1.9l2.1.3c5.4.9 9.7 1.7 13.8 2.5 2.6.5 5.3 1.1 8 1.8.7.2 1.4.4 2 .6h.1c1.4.4 2.7 1 3.9 1.8.1.1.2.2.4.3 2.6 2 4.3 4.9 4.8 8.2.5 3.4-.4 6.7-2.3 9.3z" />
      <path d="M51.6 57c-1.3-.4-2.7.3-3.1 1.6-.4 1.3.3 2.7 1.6 3.1 7 2.3 10.8 10.1 8.4 17.5-.4 1.3.3 2.7 1.6 3.1.3.1.5.1.8.1 1.1 0 2-.7 2.4-1.7 3.1-9.9-2.1-20.6-11.7-23.7z" />
    </svg>
  );
}

export default SvgComponent;
