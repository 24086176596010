import React from "react";

function SvgComponent(props) {
  return (
    <svg height="512pt" viewBox="0 0 512 512" width="512pt" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M255.941 331.332c-91.05 0-178.863-24.395-255.941-70.793.734 42.145 11.64 81.805 30.39 116.629 144.087 59.777 307.02 59.777 451.106 0 18.746-34.82 29.656-74.484 30.39-116.629-77.077 46.398-164.89 70.793-255.945 70.793zm0 0" />
      <path d="M510.207 226.2a254.193 254.193 0 00-28.375-90.731 373.187 373.187 0 01-84.156 47.617c-45.133 18.3-92.82 27.582-141.735 27.582-48.914 0-96.597-9.281-141.73-27.582a373.187 373.187 0 01-84.156-47.617 254.193 254.193 0 00-28.375 90.73c153.879 99.942 354.648 99.942 508.527 0zm0 0M59.434 420.059C106.39 476.246 176.988 512 255.94 512c78.957 0 149.555-35.754 196.512-91.941-63.66 21.296-130.086 31.949-196.512 31.949-66.425 0-132.851-10.653-196.507-31.95zm0 0M372.16 27.844C337.281 10.043 297.785 0 255.941 0s-81.34 10.043-116.214 27.844c71.261 42.718 161.168 42.718 232.433 0zm0 0" />
      <path d="M466.055 109.742a257.398 257.398 0 00-65.094-64.715C358.617 74.257 308.492 90 255.94 90c-52.55 0-102.675-15.742-145.015-44.973a257.477 257.477 0 00-65.098 64.715c123.613 94.324 296.617 94.324 420.227 0zm0 0" />
    </svg>
  );
}

export default SvgComponent;
