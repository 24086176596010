import { SendRequest } from "../makeSendRequest";
import { ExploreDocument, ExploreUpdate } from "./types";

export const createExploreSectionApi = (sendRequest: SendRequest) => ({
  fetchAll: async () => {
    const response = await sendRequest<{ exploreTiles: ExploreDocument[] }>({
      method: "get",
      path: `explore/data`,
      params: {
        noFilters: "true",
      },
      version: "v3",
    });

    return response;
  },
  update: async (id: string, data: Partial<ExploreUpdate>) => {
    const response = await sendRequest<{
      success: true;
    }>({
      method: "put",
      path: `explore/${id}`,
      version: "v3",
      data,
    });

    return response;
  },
  updatePosition: async (id: string, data: Record<"position", string>) => {
    const response = await sendRequest<{
      success: true;
    }>({
      method: "put",
      path: `explore/${id}`,
      version: "v3",
      data,
    });

    return response;
  },
  delete: async (id: string) => {
    const response = await sendRequest<{
      success: true;
    }>({
      method: "delete",
      path: `explore/${id}`,
      version: "v3",
    });

    return response;
  },
  create: async (data: ExploreDocument) => {
    const response = await sendRequest<{
      success: true;
      _id: string;
    }>({
      method: "post",
      path: `explore`,
      version: "v3",
      data,
    });

    return response;
  },
});
