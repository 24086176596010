import { SendRequest } from "../makeSendRequest";

import { formatParams } from "./format";
import { RecipeListParams, Recipe, Recipes } from "./types";

export const createRecipesProvider = (sendRequest: SendRequest) => ({
  list: (params: RecipeListParams) =>
    sendRequest<Recipes>({
      method: "get",
      path: "post/recipes",
      params: formatParams(params),
    }),
  get: (id: Recipe["id"]) =>
    sendRequest<Recipe>({
      method: "get",
      path: `post/recipes/${id}`,
    }),
});
