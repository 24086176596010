import { useMutation } from "react-query";
import { useCreateAPIClient } from "../../../hooks";
import { logEvent } from "../../../utils/monitoring";
import { useAuth } from "../../../components/AuthWrapper/hooks";

export const useDeleteProfile = () => {
  const api = useCreateAPIClient();
  const { logout } = useAuth();

  return useMutation({
    mutationKey: ["profile", "delete"],
    mutationFn: api.users.deleteProfile,
    onSuccess: () => {
      logEvent({
        eventName: "AccountDeleted",
      });
      logout();
    },
  });
};
