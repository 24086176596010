import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 690 1024" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M561.858 1017.288c0.959 0 0.959 0 0 0h66.157c33.558 0 60.404-26.846 60.404-60.404v-935.79c0-13.423-11.506-24.929-24.929-24.929h-2.876c-0.959 0-1.918 0-1.918 0-0.959 0-1.918 0-2.876 0-15.341 0.959-61.363 2.876-111.221 21.094-70.951 25.888-118.891 85.333-118.891 146.697v303.94c0 13.423 11.506 24.929 24.929 24.929h52.734v464.060c-0.959 33.558 25.888 60.404 58.487 60.404zM474.607 442.966v-279.011c0-39.311 35.476-80.539 86.292-99.715 29.723-10.547 58.487-15.341 77.663-16.3v908.944c0 5.753-4.794 10.547-10.547 10.547h-65.199c-5.753 0-10.547-4.794-10.547-10.547v-488.989c0-13.423-11.506-24.929-24.929-24.929h-52.734z" />
      <path d="M111.221 1.918c0 0 0 0 0 0-13.423 0-24.929 10.547-24.929 24.929v219.566c0 13.423 11.506 24.929 24.929 24.929 0 0 0 0 0 0 13.423 0 24.929-11.506 24.929-24.929v-219.566c0-14.382-10.547-24.929-24.929-24.929z" />
      <path d="M300.105 396.944v-370.097c0-13.423-11.506-24.929-24.929-24.929 0 0 0 0 0 0-13.423 0-24.929 11.506-24.929 24.929v291.476h-197.513v-291.476c0-13.423-11.506-24.929-24.929-24.929 0 0 0 0 0 0-13.423 0-24.929 11.506-24.929 24.929v370.097c0 25.888 26.846 58.487 58.487 59.446v504.33c0 37.393 30.682 68.075 68.075 68.075h43.146c37.393 0 68.075-30.682 68.075-68.075v-504.33c25.888-1.918 59.446-30.682 59.446-59.446zM237.783 405.573h-22.052c-6.712 0-13.423 2.876-18.217 7.67s-7.67 11.506-7.67 18.217v529.258c0 9.588-8.629 18.217-18.217 18.217h-44.105c-9.588 0-18.217-8.629-18.217-18.217v-529.258c0-6.712-2.876-13.423-7.67-18.217s-11.506-7.67-18.217-7.67h-23.011c-2.876-0.959-8.629-6.712-9.588-9.588v-26.846h198.472v26.846c-1.918 2.876-7.67 7.67-11.506 9.588z" />
      <path d="M191.76 1.918c0 0 0 0 0 0-14.382 0-24.929 11.506-24.929 24.929v219.566c0 13.423 11.506 24.929 24.929 24.929 0 0 0 0 0 0 13.423 0 24.929-11.506 24.929-24.929v-219.566c0-14.382-10.547-24.929-24.929-24.929z" />{" "}
    </svg>
  );
}

export default SvgComponent;
