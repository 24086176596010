import { useMemo } from "react";
import { layout, space } from "styled-system";
import { omitProps, pickProps } from "../utils";
import { InputTextProps } from "./InputText";

export function useInputTextStyles(props: Partial<InputTextProps>) {
  const boxStyles = useMemo(
    () => pickProps({ styleFnPropsToPick: [layout, space], props }),
    [props],
  );
  const inputStyles = useMemo(
    () => omitProps({ styleFnPropsToOmit: [layout, space], props }),
    [props],
  );
  return { boxStyles, inputStyles };
}
