import state from "./admin.payments.state";
import actions from "./admin.payments.actions";
import computators from "./admin.payments.computators";
import listeners from "./admin.payments.listeners";

export default {
  ...state,
  ...actions,
  ...computators,
  ...listeners,
};
