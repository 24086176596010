import { useMutation } from "react-query";

import { useCreateAPIClient } from "../../../hooks";

export const useGetUserSubscriptionData = () => {
  const apiClient = useCreateAPIClient();

  return useMutation({
    mutationKey: ["user", "subscription"],
    mutationFn: apiClient.users.getUserSubscription,
  });
};
