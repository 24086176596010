import React from "react";

import Icon from "../../icons";

import { InputText, InputTextProps } from "../InputText";

export type SearchProps = InputTextProps;

export const Search: React.FC<SearchProps> = (props) => {
  return (
    <InputText
      type="search"
      endAdornment={<Icon name="search" size="imd" />}
      {...props}
    />
  );
};
