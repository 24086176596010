import { getEnvConfig, EnvConfig } from "./envConfig";
import { SocialConfig, getSocialConfig } from "./socialConfig";
import { UnleashConfig, getUnleashConfig } from "./unleashConfig";

export type AppConfig = ReturnType<typeof _makeAppConfig>;

let _appConfig;
export const getStaticAppConfig = () => {
  return _appConfig;
};

export const getAppConfig = (): AppConfig => {
  const envConfig = getEnvConfig();
  const socialConfig = getSocialConfig();
  const unleashConfig = getUnleashConfig({ envConfig });

  _appConfig = _makeAppConfig({ envConfig, socialConfig, unleashConfig });
  return _appConfig;
};

export const _makeAppConfig = ({
  envConfig,
  socialConfig,
  unleashConfig,
}: {
  envConfig: EnvConfig;
  socialConfig: SocialConfig;
  unleashConfig: UnleashConfig;
}) => ({
  ...envConfig,
  ...socialConfig,
  unleashConfig,
});
