import { DependencyList, useEffect, useRef } from "react";

export const useDebounce = (
  callback: () => void,
  timeout: number,
  deps?: DependencyList,
) => {
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  const clearDebounce = () => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
      debounceRef.current = null;
    }
  };

  useEffect(() => {
    clearDebounce();
    debounceRef.current = setTimeout(callback, timeout);

    return clearDebounce;
  }, deps);
};
