export const userLoginLandingPage = "/dashboard/masterclasses";

export const DIFFICULTY = {
  intro: "KICSTART",
  intermediate: "KICFIT",
};

export const DIFFICULTY_OPTIONS = Object.entries(DIFFICULTY).map(
  ([key, value]) => ({
    value: key,
    label: value,
  }),
);

export const CMS_DIFFICULTY_OPTIONS = [
  {
    label: "None",
    value: "none",
  },
  ...DIFFICULTY_OPTIONS,
];

export const MEDIA_SORT_FILTERS = {
  "latest": {
    value: "latest",
    label: "Latest",
    sortField: "inserted",
    sortOrder: "desc",
  },
  "oldest": {
    value: "oldest",
    label: "Oldest",
    sortField: "inserted",
    sortOrder: "asc",
  },
  "largest-size": {
    value: "largest-size",
    label: "Largest (size)",
    sortField: "size",
    sortOrder: "desc",
  },
  "largest-width": {
    value: "largest-width",
    label: "Largest (width)",
    sortField: "width",
    sortOrder: "desc",
  },
  "largest-height": {
    value: "largest-height",
    label: "Largest (height)",
    sortField: "height",
    sortOrder: "desc",
  },
};

export const PLACEHOLDER = {
  textInput: "Placeholder Text",
  durationInput: -1,
  videoInput: "Placeholder Text",
  workoutTypeSelect: [{ _id: "strength", title: "Strength" }],
  trainerSelect: {
    label: "Danny Kennedy",
    value:
      process.env.NODE_ENV === "development"
        ? "5f1e59be6eec401e8c8a8bb7"
        : "5f1e59be6eec401e8c8a8bb7",
  },
  difficultySelect: "intro",
  recipeServeInput: "0",
  recipeTimeSelect: { value: "under-10", label: "Under 10 mins" },
  recipeIngredient: {
    ingredient: {
      label: "placeholder",
      value:
        process.env.NODE_ENV === "development"
          ? "5f600844b560510016f0e4c2"
          : "5f603019a2c2d100117dbdbe",
    },
    amount: "0",
    unit: "placeholder",
  },
  breakdownSet: {
    type: "amrap",
    rounds: 1,
    duration_seconds_rest: 0,
    duration_seconds_work: 0,
  },
  breakdownExercise: {
    video:
      "https://player.vimeo.com/external/449570650.hd.mp4?s=d05580184f31c2299a570ea00a808caf4ad97098&profile_id=175",
  },
};
