import { SubscriptionStatus, UserSubscription } from "../../api";
import { RevenueCatUserId } from "../subscriptions/types";
import { revenueCatClient } from "../subscriptions/utils/revenueCatClient";
import { customerInfoHasEntitlement } from "../subscriptions/utils/utils";

const CHECK_INTERVAL_MS = 3000;
const MAX_POLL_COUNT = 5;

const SUCCESSFUL_STATUSES: SubscriptionStatus[] = ["active", "trialing"];

/**
 * Poll for the subscription's update after payment is complete
 * and until stripe's web hook updates the subscription's status
 */
export const waitForUserSubscriptionToActivate = (
  fetchUserSubscriptionData: () => Promise<UserSubscription>,
  revenueCatUserId: RevenueCatUserId,
  featureRevenueCatIsSubscribedWeb: boolean,
) => {
  let callCount = 0;

  return new Promise<{ success: boolean; userSubscription: UserSubscription }>(
    (resolve) => {
      const pollForStatus = async () => {
        callCount++;

        const [userSubscription, customerInfo] = await Promise.all([
          fetchUserSubscriptionData(),
          revenueCatClient.sync(revenueCatUserId, true),
        ]);

        const successStore = SUCCESSFUL_STATUSES.includes(
          userSubscription.status,
        );
        const successRevenueCat = customerInfoHasEntitlement(customerInfo);

        const success = featureRevenueCatIsSubscribedWeb
          ? successRevenueCat
          : successStore;

        if (success) {
          return resolve({ userSubscription, success: true });
        }

        if (callCount >= MAX_POLL_COUNT) {
          return resolve({ userSubscription, success: false });
        }

        setTimeout(pollForStatus, CHECK_INTERVAL_MS);
      };

      pollForStatus();
    },
  );
};
