import { compose, styleFn } from "styled-system";

/**
 *
 * @param color HEX code for a color
 * @param opacity Fraction of 1, eg. 0.75 (for 75% opacity)
 */
export const opacity = (color: string, opacity: number) => {
  return `${color}${Math.floor(opacity * 255).toString(16)}`;
};

export const pickProps = ({
  styleFnPropsToPick,
  props,
}: {
  styleFnPropsToPick: styleFn[];
  props: Record<string, any>;
}) => {
  const composedStyleFns = compose(...styleFnPropsToPick);
  const regex = new RegExp(`^(${composedStyleFns.propNames.join("|")})$`);

  const pickedProps = Object.entries(props)
    .filter(([key]) => regex.test(key))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as Record<string, unknown>);

  return pickedProps;
};

export const omitProps = ({
  styleFnPropsToOmit,
  props,
}: {
  styleFnPropsToOmit: styleFn[];
  props: Record<string, any>;
}) => {
  const composedStyleFns = compose(...styleFnPropsToOmit);
  const regex = new RegExp(`^(${composedStyleFns.propNames.join("|")})$`);

  const pickedProps = Object.entries(props)
    .filter(([key]) => !regex.test(key))
    .reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {} as Record<string, unknown>);

  return pickedProps;
};
