import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 1024 1024" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M681.739 363.965l-185.878 176.417c-4.822 4.748-7.81 11.347-7.81 18.643s2.988 13.896 7.807 18.64l0.003 0.003c4.748 4.822 11.347 7.81 18.643 7.81s13.896-2.988 18.64-7.807l0.003-0.003 186.435-176.417c3.946-4.627 6.347-10.676 6.347-17.286 0-14.753-11.96-26.713-26.713-26.713-6.193 0-11.893 2.107-16.424 5.644l0.059-0.045z" />
      <path d="M434.643 55.652h154.713c14.753 0 26.713-11.96 26.713-26.713v0c0.059-0.667 0.093-1.443 0.093-2.226 0-14.753-11.96-26.713-26.713-26.713-0.033 0-0.065 0-0.098 0h-154.708c-0.002 0-0.004 0-0.006 0-14.446 0-26.157 11.711-26.157 26.157 0 0.196 0.002 0.391 0.006 0.586l-0.001-0.029c-0.095 0.835-0.148 1.803-0.148 2.783 0 14.446 11.711 26.157 26.157 26.157 0.052 0 0.104 0 0.156 0h-0.008z" />
      <path d="M512 89.6c-257.566 0-466.365 208.799-466.365 466.365s208.799 466.365 466.365 466.365c257.371 0 466.048-208.482 466.365-465.778v-0.031c0-257.679-208.731-466.605-466.336-466.922h-0.030zM512 966.122c-226.83-0.001-410.712-183.883-410.712-410.713s183.882-410.713 410.713-410.713c226.831 0 410.713 183.882 410.713 410.713 0 0.391-0.001 0.783-0.002 1.174v-0.061c-0.947 226.224-184.4 409.286-410.683 409.6h-0.031z" />
    </svg>
  );
}

export default SvgComponent;
