import { CSSProperties } from "react";
import styled from "styled-components";
import {
  compose,
  border,
  space,
  color,
  layout,
  position,
  shadow,
  flexbox,
  background,
  BorderProps,
  SpaceProps,
  ColorProps,
  LayoutProps as _LayoutProps,
  PositionProps,
  ShadowProps,
  FlexboxProps,
  borderRadius,
  system,
  ResponsiveValue,
  ThemeValue,
  BackgroundProps,
} from "styled-system";

import { Theme } from "../theme";

export type BoxInnerProps = SpaceProps<Theme> &
  BorderProps<Theme> &
  ColorProps<Theme> &
  _LayoutProps<Theme> &
  PositionProps<Theme> &
  ShadowProps<Theme> &
  BackgroundProps<Theme> &
  FlexboxProps<Theme> & {
    gap?: ResponsiveValue<ThemeValue<"space", Theme>, Theme>;
    cursor?: CSSProperties["cursor"];
    whiteSpace?: CSSProperties["whiteSpace"];
  };

const gap = system({
  gap: {
    scale: "space",
    property: "gap",
  },
});

const cursor = system({
  cursor: {
    property: "cursor",
  },
});

const whiteSpace = system({
  whiteSpace: {
    property: "whiteSpace",
  },
});

export const Box = styled.div<BoxInnerProps>(
  compose(
    border,
    space,
    color,
    layout,
    position,
    shadow,
    flexbox,
    borderRadius,
    gap,
    cursor,
    background,
    whiteSpace,
  ),
);

Box.defaultProps = {
  display: "flex",
  flexDirection: "column",
  cursor: "inherit",
};

export type BoxProps = BoxInnerProps &
  React.HTMLAttributes<any> & {
    as?: React.ElementType;
  };
