import React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 101 101.1"
      xmlSpace="preserve"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path d="M97.8 23.7c1.8 0 3.2-1.4 3.2-3.2v-10c0-1.8-1.4-3.2-3.2-3.2H86v-.2C86 3.2 82.9 0 79 0s-7.1 3.2-7.1 7.1v.2H60.2c-1.8 0-3.2 1.4-3.2 3.2v10c0 1.8 1.4 3.2 3.2 3.2h11.1V77H60.2c-1.8 0-3.2 1.4-3.2 3.2v10c0 1.8 1.4 3.2 3.2 3.2H72v.1c0 3.9 3.2 7.1 7.1 7.1s7.1-3.2 7.1-7.1v-.1h11.7c1.8 0 3.2-1.4 3.2-3.2v-10c0-1.8-1.4-3.2-3.2-3.2H86.4V23.7h11.4zM74.9 7.1C74.9 4.8 76.8 3 79 3s4 1.8 4 4.1v.2h-8.1v-.2zM83 93.6c0 2.2-1.8 4.1-4.1 4.1s-4.1-1.8-4.1-4.1v-.1h8.1c.2 0 .1 0 .1.1zM97.8 80c.1 0 .2.1.2.2v10c0 .1-.1.2-.2.2H60.2c-.1 0-.2-.1-.2-.2v-10c0-.1.1-.2.2-.2h37.6zm-23.4-3V23.7h9.1V77h-9.1zM60.2 20.7c-.1 0-.2-.1-.2-.2v-10c0-.1.1-.2.2-.2h37.5c.1 0 .2.1.2.2v10c0 .1-.1.2-.2.2H60.2zM40.8 7.7H29v-.2C29 3.6 25.8.4 21.9.4s-7.1 3.2-7.1 7.1v.2H3.2C1.4 7.7 0 9.1 0 10.9v10c0 1.8 1.5 3.2 3.2 3.2h11.1v53.3H3.2c-1.8 0-3.2 1.4-3.2 3.2v10c0 1.8 1.5 3.2 3.2 3.2H15v.1c0 3.9 3.2 7.1 7.1 7.1s7.1-3.2 7.1-7.1v-.1h11.7c1.8 0 3.2-1.4 3.2-3.2v-10c0-1.8-1.4-3.2-3.2-3.2H29.4V24.1h11.3c1.8 0 3.2-1.4 3.2-3.2v-10c.1-1.8-1.4-3.2-3.1-3.2zm-22.9-.2c0-2.2 1.8-4.1 4.1-4.1 2.2 0 4.1 1.8 4.1 4.1v.2H18c-.1-.1-.1-.1-.1-.2zM26.1 94c0 2.2-1.8 4.1-4.1 4.1-2.2 0-4.1-1.8-4.1-4.1v-.1H26l.1.1zm14.7-13.5c.1 0 .2.1.2.2v10c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-10c0-.1.1-.2.2-.2h37.6zm-23.4-3V24.1h9.1v53.3h-9.1zM41 20.9c0 .1-.1.2-.2.2H3.2c-.1 0-.2-.1-.2-.2v-10c0-.1.1-.2.2-.2h37.5c.1 0 .2.1.2.2v10z" />
    </svg>
  );
}

export default SvgComponent;
