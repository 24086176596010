import { SendRequest } from "../makeSendRequest";
import {
  RecommendationWithContent,
  Screens,
  RecommendationInput,
  RecommendationCreateInput,
} from "./types";

export const createRecommendationApi = (sendRequest: SendRequest) => ({
  fetchById: async (id: string) => {
    const response = await sendRequest<RecommendationWithContent>({
      method: "get",
      path: `recommendation/content/${id}`,
      version: "v3",
    });

    return response;
  },
  listByScreen: async (screen: Screens) => {
    const response = await sendRequest<{
      recommendations: RecommendationWithContent[];
    }>({
      method: "get",
      path: `recommendation/content/by-screen?screen=${screen}`,
      version: "v3",
    });

    return response;
  },
  updateRecommendation: async (
    id: string,
    data: Partial<RecommendationInput>,
  ) => {
    const response = await sendRequest<{
      success: true;
    }>({
      method: "put",
      path: `recommendation/${id}`,
      version: "v3",
      data,
    });

    return response;
  },
  updatePosition: async (
    id: string,
    data: {
      newPosition: number;
      screen: Screens;
    },
  ) => {
    const response = await sendRequest<{
      success: true;
    }>({
      method: "put",
      path: `recommendation/positions/update`,
      version: "v3",
      data: {
        ...data,
        recommendationId: id,
      },
    });

    return response;
  },
  deleteRecommendation: async (id: string) => {
    const response = await sendRequest<{
      success: true;
    }>({
      method: "delete",
      path: `recommendation/${id}`,
      version: "v3",
    });

    return response;
  },
  createRecommendation: async (data: RecommendationCreateInput) => {
    const response = await sendRequest<{
      success: true;
      _id: string;
    }>({
      method: "post",
      path: `recommendation`,
      version: "v3",
      data,
    });

    return response;
  },
});
