import React from "react";

import { Plan, Discount } from "../../../../api";
import { usePlanPrice } from "../../../Payments/hooks/usePlanPrice";
import { Text, ColorThemeTokens } from "../../../../ui";

const InnerPlanDuration: React.FC<{
  plan: Plan;
  discount?: Discount;
  color?: ColorThemeTokens;
  showMonthlyPrice?: boolean;
}> = ({
  plan,
  discount,
  color = "contentContrastLow",
  showMonthlyPrice = false,
}) => {
  const { monthlyFormattedPrice } = usePlanPrice({ plan, discount });

  const billingCycle =
    plan.duration_unit &&
    {
      month: () =>
        plan.duration === 3 ? ("quarterly" as const) : ("monthly" as const),
      year: () => "yearly" as const,
    }[plan.duration_unit]();

  return (
    <Text variant="bodySmall" color={color}>
      {!showMonthlyPrice || billingCycle === "monthly" ? (
        <>Billed {billingCycle}</>
      ) : (
        <>
          {monthlyFormattedPrice}
          /month billed {billingCycle}
        </>
      )}
    </Text>
  );
};

export const PlanDuration = React.memo(InnerPlanDuration);

const InnerPromotionDuration: React.FC<Plan> = ({
  duration_unit,
  duration,
}) => {
  const promoDuration =
    duration_unit &&
    {
      month: () => (duration === 3 ? "quarter" : "month"),
      year: () => "year",
    }[duration_unit]();

  return (
    <Text variant="bodySmall" color="contentContrastLow" mr="sm">
      First {promoDuration}
    </Text>
  );
};

export const PromotionDuration = React.memo(InnerPromotionDuration);
