import React from "react";
import {
  ThemeProvider as _ThemeProvider,
  useTheme as _useTheme,
} from "styled-components";
import { ThemeColorMode } from "./palette/palette";
import { createTheme, LegacyTheme, Theme } from "./theme";

export const ThemeProvider: React.FC<{
  themeColorMode?: ThemeColorMode;
  legacyTheme?: LegacyTheme;
}> = ({ children, themeColorMode, legacyTheme }) => {
  const colorTheme = themeColorMode ?? "light";
  const theme = { ...legacyTheme, ...createTheme(colorTheme) };

  return <_ThemeProvider theme={theme}>{children}</_ThemeProvider>;
};

export const useTheme: () => Theme & LegacyTheme = _useTheme as any;
