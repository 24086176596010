import { opacity } from "../utils";

export type ThemeColorMode = "light" | "dark";
export type ColorThemeTokens = keyof typeof ColorThemeLight;
export type ColorTheme = typeof ColorThemeLight;

export const palette = {
  facebookBlue: "#1877F2",

  purpleXDark: "#322B6B",
  purpleDark: "#514C90",
  purple: "#757CF4",
  purpleLight: "#B9BAF8",
  purpleXLight: "#E7EDFF",

  pink: "#E35971",
  pinkLight: "#F2A5B5",
  pinkXLight: "#FFF5F5",
  pinkDark: "#BF3B56",
  pinkXDark: "#6C133E",

  tealDark: "#248380",
  teal: "#79D9CF",
  tealLight: "#AFE0DD",
  tealXLight: "#E8F9F7",
  tealXDark: "#043E47",

  orange: "#C77D33",
  yellow: "#FCD255",
  yellowLight: "#F9E1A2",
  yellowXLight: "#FFF7DE",
  orangeXDark: "#943205",

  grey110: "#111111",
  grey100: "#212120",
  grey90: "#30302F",
  grey80: "#575754",
  grey60: "#B7B7AC",
  grey40: "#D5D5CB",
  grey20: "#E9E9E0",
  grey10: "#F3F3EE",
  grey05: "#F7F7F3",

  white: "#ffffff",
  black: "#000000",

  negativeDark: "#D92A0F",
  negativeLight: "#F05D38",

  positiveDark: "#008552",
  positiveLight: "#92D08D",
};

const ColorThemeLight = {
  backgroundBrand: palette.tealXDark,
  primaryOverlay: opacity(palette.teal, 0.16),
  primaryContrast: palette.grey100,

  secondary: palette.pink,
  secondaryOverlay: opacity(palette.pink, 0.16),

  borderLight: opacity(palette.grey100, 0.16),
  borderDark: opacity(palette.grey100, 0.48),
  borderXDark: opacity(palette.grey100, 0.8),

  backgroundPrimary: palette.white,
  backgroundContrastHigh: palette.grey100,
  backgroundTertiary: palette.grey20,
  backgroundSecondary: palette.grey10,
  backgroundSemanticYellow: palette.yellowXLight,
  backgroundSemanticPink: palette.pinkXLight,
  backgroundSemanticPurple: palette.purpleXLight,
  backgroundTeal: palette.tealXLight,
  backgroundNegative: palette.negativeDark,
  backgroundPositive: palette.positiveDark,
  backgroundTealHover: "#033239",
  backgroundSandHover: "#E7E7E2",

  contentContrastMid: palette.grey80,
  contentContrastXLow: palette.grey40,
  contentNegative: palette.negativeDark,
  contentPositive: palette.positiveDark,
  contentEmber: palette.orangeXDark,
  contentPink: palette.pinkXDark,
  contentTeal: palette.tealXDark,
  contentPurple: palette.purpleXDark,
  contentContrastHighInverse: palette.white,
  contentContrastHigh: palette.grey100,
  contentContrastLow: palette.grey60,
  contentAlwaysLight: palette.white,

  white: palette.white,
  black: palette.black,
  transparent: "#00000000",
  facebookBlue: palette.facebookBlue,
};

export const ColorThemeDark = {
  backgroundBrand: palette.tealXDark,
  primaryOverlay: opacity(palette.teal, 0.16),
  primaryContrast: palette.grey100,

  secondary: palette.pink,
  secondaryOverlay: opacity(palette.pink, 0.16),

  borderLight: opacity(palette.grey05, 0.16),
  borderDark: opacity(palette.grey100, 0.48),
  borderXDark: opacity(palette.grey100, 0.8),

  backgroundPrimary: palette.grey10,
  backgroundSemanticYellow: palette.yellowXLight,
  backgroundSemanticPink: palette.pinkXLight,
  backgroundSemanticPurple: palette.purpleXLight,
  backgroundTeal: palette.tealLight,
  backgroundContrastHigh: palette.white,
  backgroundTertiary: palette.grey80,
  backgroundSecondary: palette.grey10,
  backgroundNegative: palette.negativeDark,
  backgroundPositive: palette.positiveDark,
  backgroundTealHover: "#033239",
  backgroundSandHover: "#E7E7E2",

  contentContrastMid: palette.grey80,
  contentContrastXLow: palette.grey40,
  contentNegative: palette.negativeDark,
  contentPositive: palette.positiveDark,
  contentEmber: palette.orangeXDark,
  contentPink: palette.pinkXDark,
  contentTeal: palette.tealXDark,
  contentPurple: palette.purpleXDark,
  contentContrastHighInverse: palette.white,
  contentContrastHigh: palette.grey100,
  contentContrastLow: palette.grey60,
  contentAlwaysLight: palette.white,

  white: palette.white,
  black: palette.black,
  transparent: "#00000000",
  facebookBlue: palette.facebookBlue,
};

export const ColorThemeMap: Record<ThemeColorMode, ColorTheme> = {
  light: ColorThemeLight,
  dark: ColorThemeDark,
};
