import styled from "styled-components";

import Icon from "../../icons";

export const Container = styled.div`
  align-items: center;
  border: 1px solid;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;

export const StyledIcon = styled(Icon)`
  fill: ${(props) => props.theme.brandColor};
  height: 10rem;
  margin: 0 0 4rem 0;
  width: 30rem;
`;

export const Text = styled.h1`
  color: ${(props) => props.theme.primaryFontColour};
  font-weight: 600;
`;
