import { SendRequest } from "../makeSendRequest";
import { z } from "zod";

const MuxAssetSchema = z.object({
  status: z.enum([
    "preparing",
    "ready",
    "errored",
    "waiting",
    "upload_cancelled",
  ]),
  duration: z.number(),
  muxId: z.string(),
  resolution: z.string(),
  aspectRatio: z.string(),
  playbackIds: z
    .array(
      z.object({
        policy: z.enum(["signed", "public"]),
        id: z.string(),
      }),
    )
    .optional(),
  tracks: z
    .array(
      z.object({
        type: z.enum(["audio", "video", "text"]).optional(),
        id: z.string().optional(),
        name: z.string().optional(),
      }),
    )
    .optional(),
  uploadId: z.string(),
  fileName: z.string(),
});

export type MuxAsset = z.infer<typeof MuxAssetSchema>;
export type MuxAssetFilter = {
  next?: string | null;
  previous?: string | null;
  search?: string;
  status?: MuxAsset["status"];
};
type PaginatedResults = {
  previous?: string;
  hasPrevious?: boolean;
  next?: string;
  hasNext?: boolean;
  results: MuxAsset[];
};

export const createMuxApi = (sendRequest: SendRequest) => ({
  uploadUrl: async (name: string) => {
    const response = await sendRequest<{ url: string }>({
      method: "post",
      path: "mux/upload",
      version: "v3",
      data: { name },
    });

    return response;
  },
  listAssets: async ({ next, previous, search, status }: MuxAssetFilter) => {
    const response = await sendRequest<PaginatedResults>({
      method: "get",
      path: "mux/assets",
      version: "v3",
      params: {
        ...(next && { next }),
        ...(previous && { previous }),
        ...(search && { search }),
        ...(status && { status }),
      },
    });

    return response;
  },
  viewByMuxId: async (muxId: string) =>
    sendRequest<MuxAsset | null>({
      method: "get",
      path: `mux/assets/${muxId}`,
      version: "v3",
    }),
  viewByMuxPolicyId: async (policyId: string) =>
    sendRequest<MuxAsset | null>({
      method: "get",
      path: `mux/assets/by-policy/${policyId}`,
      version: "v3",
    }),
  createTimedUrl: async (payload: { muxId: string; end: string }) =>
    sendRequest<{ url: string }>({
      method: "post",
      path: `mux/url`,
      version: "v3",
      data: payload,
    }),
});
