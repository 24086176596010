import React, { useState } from "react";
import * as monitoring from "../../../../utils/monitoring";

import { Modal, Button, Box } from "../../../../ui";
import { CheckSection } from "./CheckSection";

import { bumpMedicalCheckCopy } from "./data";
import { updateBUMPStoredConsent } from "./helpers";
import { Text, Separator, Flex, Markdown } from "./styles";

export interface BUMPMedicalCheckModalProps {
  type: "prenatal" | "postnatal";
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const Checks = {
  medicalOne: false,
  medicalTwo: false,
  marketing: false,
};

export const BUMPMedicalCheckModal = ({
  type,
  isOpen,
  onClose,
  onContinue,
}: BUMPMedicalCheckModalProps) => {
  const copy = bumpMedicalCheckCopy[type];
  const [state, setState] = useState(Checks);

  const makeToggleCheck = (key: keyof typeof Checks) => () =>
    setState({ ...state, [key]: !state[key] });

  return (
    <Modal
      isOpen={isOpen}
      close={() => {
        setState(Checks);
        onClose();
      }}
    >
      <Box
        flexDirection="column"
        padding="md"
        maxWidth="600px"
        margin="xs"
        backgroundColor="backgroundSecondary"
        borderRadius="lg"
      >
        <Text mb="md">Approval Required</Text>
        <Text sub>{copy.header}</Text>
        {copy.markdown && <Markdown>{copy.markdown}</Markdown>}

        <Separator />

        <Flex direction="column">
          <Flex direction="column" mb="lg">
            <CheckSection
              text={copy.consent1}
              isChecked={state.medicalOne}
              onClick={makeToggleCheck("medicalOne")}
            />

            <CheckSection
              text={copy.consent2}
              isChecked={state.medicalTwo}
              onClick={makeToggleCheck("medicalTwo")}
            />

            <CheckSection
              text={copy.emailOptIn}
              isChecked={state.marketing}
              onClick={makeToggleCheck("marketing")}
            />
          </Flex>
          <Flex justifyContent="center">
            <Button
              variant="buttonFilledBrand"
              onClick={() => {
                monitoring.logUserAttribute(
                  "BumpEmailedOptedIn",
                  state.marketing,
                );
                if (state.marketing) {
                  monitoring.logEvent({
                    eventName: "BumpEdmSubscribed",
                    bump_medical_check_type: type,
                  });
                }
                updateBUMPStoredConsent({ [type]: true });
                onContinue();
              }}
              text="Continue"
              disabled={!state.medicalOne || !state.medicalTwo}
            />
          </Flex>
        </Flex>
      </Box>
    </Modal>
  );
};
