import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width={1979.6}
      height={749}
      viewBox="1202.2 -327 1979.6 749"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path d="M1933.1-327h-180v5.8h46.4v737.4h-46.4v5.8h287.4V218.8h-5.8c.5 91-17.7 196.9-119 197.4h-29v-737.4h46.4v-5.8zm-504.4 226.4h-5.8v46.4c-1.3 72.9-43.7 86.5-87.1 87.1v-354.2h29c83.9.5 120.2 67 119 197.4h5.8V-327h-287.4v5.8h49.4v737.4h-49.4v5.8h287.4V218.8h-5.8c1.2 91-16.9 196.9-119 197.4h-29V38.8c43.4.4 85.8 14 87.1 87.1v43.5h5.8v-270zm1689.2 0h-5.8v46.4c0 72.9-42.3 86.5-87.1 87.1v-354.2h31.9c82.4.5 118.7 67 119 197.4h5.8V-327h-290.3v5.8h49.4v737.4h-49.4v5.8h290.3V218.8h-5.8c-.4 91-18.5 196.9-119 197.4H3025V38.8c44.8.4 87.1 14 87.1 87.1v43.5h5.8v-270zM2503.1-327h-180v5.8h46.4v737.4h-46.4v5.8h287.4V218.8h-5.8c.2 91-17.9 196.9-119 197.4h-29v-737.4h46.4v-5.8z" />
    </svg>
  );
}

export default SvgComponent;
