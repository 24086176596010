import React from "react";
import styled from "styled-components";
import {
  space,
  color,
  typography,
  textShadow,
  layout,
  background,
  border,
  SpaceProps,
  ColorProps,
  TypographyProps,
  TextShadowProps,
  LayoutProps,
  BackgroundProps,
  BorderProps,
  compose,
} from "styled-system";
import { css } from "@styled-system/css";

import { Theme } from "../theme";

import { Box, BoxProps } from "../Box";
import { Text } from "../Text";

export interface RadioProps extends BoxProps {
  label?: string;
  name?: string;
  checked?: boolean;
  onChange: (e) => void;
  value: string;
}

type ReactInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type _RadioProps = SpaceProps<Theme> &
  TypographyProps<Theme> &
  ColorProps<Theme> &
  TextShadowProps<Theme> &
  LayoutProps<Theme> &
  BackgroundProps<Theme> &
  BorderProps<Theme> &
  ReactInputProps;

const _Radio = styled.input<_RadioProps>(
  compose(space, color, typography, textShadow, layout, background, border),
  css({
    "appearance": "none",
    "backgroundColor": "transparent",
    "transform": "translateY(-0.5px)",
    "display": "grid",
    "placeContent": "center",
    "cursor": "pointer",
    "borderRadius": "50%",

    "&::before": {
      content: "''",
      backgroundImage: "url('/static/images/check.svg')",
      display: "inline-flex",
      backgroundSize: "12px 12px",
      width: "12px",
      height: "12px",
      transform: "scale(0)",
      transition: "120ms transform ease-in-out",
    },

    "&:checked::before": {
      transform: "scale(1)",
    },

    "&:checked": {
      borderStyle: "none",
      backgroundColor: "contentContrastHigh",
    },
  }),
);

export const Radio: React.FC<RadioProps> = ({
  name,
  label,
  checked,
  onChange,
  children,
  value,
  ...props
}) => {
  if (label && children)
    throw new Error(
      `You can only use label or children, but not both! (name: ${name})`,
    );
  if (!label && !children)
    throw new Error(
      `You must have either a label or children! (name: ${name})`,
    );

  return (
    <label>
      <Box flexDirection="row" alignItems="center" p={["xs", "sm"]} {...props}>
        <Box>
          <_Radio
            borderColor="borderDark"
            borderWidth="sm"
            borderRadius="xs"
            borderStyle="solid"
            height={20}
            width={20}
            type="radio"
            id={name}
            name={name}
            aria-label={name}
            checked={checked}
            onChange={onChange}
            value={value}
            m="none"
            mr="sm"
          />
        </Box>

        {label && <Text variant="bodySmall">{label}</Text>}
        {children}
      </Box>
    </label>
  );
};
