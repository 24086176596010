import { formatCategories } from "./helpers";

const breakdownParserForApi = (data) => {
  const {
    _id,
    image_lede: imageLede,
    thumbnail,
    publish_date: publishDate,
    type_specific: typeSpecific,
    ...rest
  } = data;

  const imageUrl = imageLede?.url || null;
  const thumbnailUrl = thumbnail?.url || null;
  const pubDateSeconds = Math.floor(publishDate.getTime() / 1000);
  const equipmentNames = typeSpecific.equipment.map((equip) => equip._id);

  const body = {
    ...rest,
    image_lede: imageUrl,
    thumbnail: thumbnailUrl,
    publish_date: pubDateSeconds,
    type_specific: {
      ...typeSpecific,
      categories: formatCategories(typeSpecific.categories),
      equipment: equipmentNames,
    },
  };

  return {
    _id,
    body,
    data,
  };
};

export default breakdownParserForApi;
