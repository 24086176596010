import { useEffect, useState, DependencyList } from "react";

export const useDebounceValue = <T>(
  value: T,
  delay: number,
  dependencies: DependencyList = [],
): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    if (!delay) return setDebouncedValue(value);

    const handler = setTimeout(() => setDebouncedValue(value), delay);
    return () => clearTimeout(handler);
  }, [delay, value, ...dependencies]);

  return debouncedValue;
};
