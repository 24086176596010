import { format } from "date-fns";

const parseIntroStart = ({
  date,
  difficulty,
  dateToBeFormatted,
}: {
  date?: string;
  difficulty?: string;
  dateToBeFormatted?: Date;
}) => {
  let difficultyString = "";
  let dateString = "";

  if (date) {
    if (date === "1970-01-01") {
      difficultyString = "intermediate";
    } else {
      difficultyString = "intro";
    }
  }

  if (difficulty) {
    if (difficulty === "intermediate") {
      dateString = "1970-01-01";
    } else {
      dateString = format(dateToBeFormatted || new Date(), "yyyy-MM-dd");
    }
  }

  return {
    date: dateString,
    difficulty: difficultyString,
  };
};

export default parseIntroStart;
