import { thunkOn } from "easy-peasy";

const listeners = {
  onLogOut: thunkOn(
    (actions, storeActions) => storeActions.auth.logOut,
    (actions, target) => {
      actions.clearState();
    },
  ),
};

export default listeners;
