import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { Box, Button, LoadingIndicator, Text } from "../../../../../ui";
import {
  ApplyCouponInput,
  PaymentSummary,
  PlansList,
  usePlans,
} from "../../../../../features/Checkout";
import { PaymentElements } from "../../../../../features/Checkout/components/PaymentElements";

import { ErrorMsg, StyledIcon } from "./styles";
import { useReferralParam } from "../../../../../features/Checkout/hooks/useReferralParam";
import { useStoreState } from "../../../../../../store";
import { formatFreeTrialLabel } from "../../../../../features/Checkout/checkoutUtils";
import { useStripeActions } from "../../../../../features/Checkout/hooks";

type PurchaseFinishProps = {
  referralCampaignId?: string;
  planName: string;
  hasPurchased: boolean;
};

type PurchaseSubscriptionProps = {
  onFinish: (props: PurchaseFinishProps) => void;
  analyticsSource: "signup" | "profile";
  title?: string;
  showCloseIcon?: boolean;
};

const PurchaseSubscription: React.FC<PurchaseSubscriptionProps> = ({
  onFinish,
  title = "Choose your plan",
  showCloseIcon = true,
}) => {
  const childRef = useRef<{ onSubmitForm: VoidFunction }>(null);

  const subscription = useStoreState(
    (state) => state.auth.subscriptionData?.user_subscription?.subscription,
  );

  const [subError, setSubError] = useState("");
  const [cardError, setCardError] = useState("");
  const [discountError, setDiscountError] = useState("");
  const [isPurchasing, setIsPurchasing] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState(subscription?._id);
  const [promoCode, setPromoCode] = useState("");

  const { plans, applyDiscount, discountsLoading, plansLoading } = usePlans({
    setDiscountError,
  });

  const selectedPlan = useMemo(
    () => plans.find((p) => p._id === selectedPlanId),
    [plans, selectedPlanId],
  );

  const hasTrial = useMemo(
    () => selectedPlan?.trial_duration > 0,
    [selectedPlan?.trial_duration],
  );

  const { campaignId, referrerEmail, clearParams } = useReferralParam();

  const { purchaseSubscriptionWithCard, isStripeLoading } = useStripeActions();

  const onSelectPlan = useCallback(
    (planId) => {
      if (planId === selectedPlanId) return;

      setSelectedPlanId(planId);
      setPromoCode(plans.find((p) => p._id === planId)?.discount?.code ?? "");
      setDiscountError("");
    },
    [plans, selectedPlanId, setSelectedPlanId, setPromoCode, setDiscountError],
  );

  useEffect(() => {
    if (!plans.length || selectedPlanId) return;
    setSelectedPlanId(plans[0]._id);
  }, [plans, selectedPlanId]);

  if (plansLoading) {
    return (
      <Box flex={1} height="100%" justifyContent="center">
        <LoadingIndicator size="large" />
      </Box>
    );
  }
  const handleSubmit = async ({ cardElement }) => {
    if (!selectedPlanId) {
      setSubError("Please choose a subscription.");
      return { isSuccessful: false };
    }

    setIsPurchasing(true);

    const { error } = await purchaseSubscriptionWithCard({
      selectedPlanId,
      discountCode: selectedPlan?.discount?.code,
      referral: {
        campaignId,
        referrerEmail,
      },
      cardElement,
    });

    setIsPurchasing(false);

    if (error) {
      setCardError(error.message);
      return { isSuccessful: false };
    }

    onFinish({
      referralCampaignId: campaignId,
      planName: selectedPlan.name,
      hasPurchased: true,
    });

    clearParams();

    return { isSuccessful: true };
  };

  if (plansLoading) {
    return (
      <Box flex={1} height="100%" justifyContent="center">
        <LoadingIndicator size="large" />
      </Box>
    );
  }

  return (
    <Box
      bg="backgroundPrimary"
      display="flex"
      flex={1}
      alignItems="center"
      px="xs"
      py={["md", null, "lg"]}
      data-cy="paywall-modal"
    >
      <Box minWidth={["100%", "432px", "500px"]}>
        <Box mb="sm" alignItems="center">
          <Text variant="intro">{title}</Text>
          <Text
            variant="body"
            color={hasTrial ? "contentContrastMid" : "backgroundPrimary"}
          >
            {hasTrial
              ? `Your first ${formatFreeTrialLabel({
                  plan: selectedPlan,
                })} are free, no strings attached.`
              : "_"}
          </Text>
        </Box>

        {showCloseIcon && (
          <StyledIcon
            name="times"
            onClick={() =>
              onFinish({
                referralCampaignId: campaignId,
                planName: selectedPlan.name,
                hasPurchased: false,
              })
            }
          />
        )}

        <PlansList
          {...{
            plans,
            selectedPlanId,
            onSelectPlan,
            mb: "lg",
          }}
        />

        <Box mb="md">
          <Box mb="sm" alignItems="center">
            <Text variant="intro">Billing details</Text>
            <Text
              variant="body"
              color={hasTrial ? "contentContrastMid" : "backgroundPrimary"}
            >
              {hasTrial
                ? "You'll only be charged at the end of your trial, cancel anytime."
                : "_"}
            </Text>
          </Box>

          {subError && <ErrorMsg noMargin>{subError}</ErrorMsg>}
          <PaymentElements onSubmit={handleSubmit} ref={childRef} />

          <Box bg="backgroundTertiary" mt="md" height={1} flex="0 1 1" />
        </Box>

        <Box gap="md" mb="md">
          <PaymentSummary
            {...{
              plan: selectedPlan,
              discount: selectedPlan?.discount,
            }}
          />
          <ApplyCouponInput
            {...{
              promoCode,
              setPromoCode,
              onSubmit: () => applyDiscount(selectedPlanId, promoCode),
              isLoading: discountsLoading,
              error: discountError,
            }}
          />
        </Box>

        <Box gap="xs">
          <Button
            text={
              hasTrial
                ? `Start ${formatFreeTrialLabel({
                    plan: selectedPlan,
                    hyphenate: true,
                  })} Free Trial`
                : "Pay Now"
            }
            onClick={() => childRef.current?.onSubmitForm()}
            variant="buttonFilledBrand"
            isLoading={isPurchasing || isStripeLoading}
            px="lg"
            alignSelf="center"
            data-cy="submit-pay-btn"
          />

          {cardError && <ErrorMsg>{cardError}</ErrorMsg>}

          {referrerEmail && (
            <Text variant="bodySmall" textAlign="center">
              You are being referred by: {referrerEmail} 🎉
            </Text>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(PurchaseSubscription);
