import React from "react";
import { Box, BoxProps } from "../Box/Box";
import { Text } from "../Text/Text";

type TagProps = {
  title: string;
} & BoxProps;

export const Tag: React.FC<TagProps> = ({ title, ...props }) => {
  return (
    <Box
      backgroundColor="white"
      borderRadius="full"
      overflow="hidden"
      py="xs"
      px="sm"
      {...props}
    >
      <Text variant="imageTag" textAlign="center">
        {title}
      </Text>
    </Box>
  );
};
