import { useMutation } from "react-query";
import { useCreateAPIClient } from "../../../hooks";

export const useLogFeatureFlagConfiguration = () => {
  const apiClient = useCreateAPIClient();

  return useMutation({
    mutationKey: ["logFeatureFlagConfiguration"],
    mutationFn: async (data: JSON) => {
      return apiClient.users.logFeatureFlagConfiguration(data);
    },
  });
};
