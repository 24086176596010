import { useRouter } from "next/router";
import { useMemo } from "react";
import { useSubscriptionInfo } from "../features/subscriptions/hooks/useSubscriptionInfo";
import { useUser } from "./user";
import { subscriptionInfoHasEntitlement } from "../features/subscriptions/utils/utils";

// TODO: Define Paywall routes on the actual pages, and don't define with static urls

const paywalledRoutes = [
  "/masterclasses/[id]",
  "/dashboard/masterclasses",
  "/dashboard/recipes",
];

export const usePaywall = () => {
  const { pathname } = useRouter();
  const subscriptionInfo = useSubscriptionInfo();
  const user = useUser();
  const isLoggedIn = !!user?.revenueCatUserId;
  const isFetchingSubscription =
    isLoggedIn &&
    subscriptionInfo?.revenueCatUserId !== user?.revenueCatUserId &&
    !subscriptionInfo?.customerInfo;
  const hasEntitlement = subscriptionInfoHasEntitlement(subscriptionInfo);

  const showPaywall = useMemo(
    () =>
      !isFetchingSubscription &&
      !hasEntitlement &&
      paywalledRoutes.includes(pathname),
    [isFetchingSubscription, hasEntitlement, pathname],
  );

  return { showPaywall };
};
