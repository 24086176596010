import { SendRequest } from "../makeSendRequest";

import { TagName, Tag } from "./types";

export const createTagsProvider = (sendRequest: SendRequest) => ({
  get: (name: TagName) =>
    sendRequest<Tag>({
      version: "v2",
      method: "get",
      path: `tags/${name}`,
    }),
});
