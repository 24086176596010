import axios from "axios";
import { thunk } from "easy-peasy";

import { KIC_API } from "../../../src/api/index";
import setState from "../../../src/utils/actionModels/setState";
import programParserForApi from "../../../src/utils/parsersForEndpoints/program";
import { logAxiosError } from "../../../src/utils/utils";

const actions = {
  // actions
  setState: setState(),

  // thunks
  createDay: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          `${KIC_API}/api/v2/content`,
          payload,
          {
            headers,
          },
        );

        const { data } = response;

        return { data };
      } catch (error) {
        logAxiosError(error, "createDay");
        return error.response.data;
      }
    },
  ),
  fetchDay: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(
          `${KIC_API}/api/v2/content/${payload}`,
          {
            headers,
          },
        );

        return response;
      } catch (error) {
        logAxiosError(error, "fetchDay");
        return error.response;
      }
    },
  ),
  fetchDays: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { pageNumber, limit, day, difficulty, search } = payload;
        const isIntro = difficulty === "intro";

        const headers = { Authorization: `Bearer ${token}` };
        const pageQuery = `?page=${pageNumber || 1}`;
        const limitQuery = limit ? `&limit=${limit}` : "";
        const dayQuery = day || search ? `&day=${day || search}` : "";
        const difficultyQuery = difficulty ? `&plan=${difficulty}` : "";

        const response = await axios.get(
          `${KIC_API}/api/v2/content${pageQuery}${limitQuery}${dayQuery}${difficultyQuery}`,
          {
            headers,
          },
        );

        const { data, settings } = response.data;

        actions.setState({
          key: isIntro ? "intro" : "intermediate",
          value: data,
        });
        actions.setState({
          key: isIntro ? "introSettings" : "intermediateSettings",
          value: settings,
        });

        return data;
      } catch (error) {
        logAxiosError(error, "fetchDays");
        return error.response.data;
      }
    },
  ),
  removeDay: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        await axios.delete(`${KIC_API}/api/v2/content/${payload}`, {
          headers,
        });
      } catch (error) {
        logAxiosError(error, "removeDay");
      }
    },
  ),
  updateDay: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };
        const { _id, body } = programParserForApi(payload);

        const response = await axios.put(
          `${KIC_API}/api/v2/content/${_id}`,
          body,
          { headers },
        );

        const { data } = response;

        return { data };
      } catch (error) {
        logAxiosError(error, "updateDay");
        return error.response.data;
      }
    },
  ),
};

export default actions;
