import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 99.5 98.9"
      xmlSpace="preserve"
      width="1rem"
      height="1rem"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path d="M99.5 49.7C99.5 22.3 77.2 0 49.7 0S0 22.3 0 49.7c0 24.8 18.2 45.4 42 49.1V64.1H29.3V49.7H42v-11c0-12.5 7.4-19.4 18.8-19.4 5.4 0 11.1 1 11.1 1v12.2h-6.3c-6.2 0-8.1 3.8-8.1 7.8v9.3h13.8L69.1 64H57.5v34.8c23.8-3.7 42-24.2 42-49.1z" />
    </svg>
  );
}

export default SvgComponent;
