import styled from "styled-components";
import InsigniaIcon from "../../../icons/Insignia";

export const Container = styled.div`
  align-items: center;
  background: ${(props) => props.theme.headerBackground};
  display: flex;
  flex-direction: row;
  min-height: 4rem;
  justify-content: flex-end;
  padding: 0 1rem;
  width: 100%;
`;

export const Insignia = styled(InsigniaIcon)`
  cursor: pointer;
  fill: #fff;
  height: 2rem;
  width: 2rem;
`;

export const Name = styled.h3`
  color: #ffffff;
  margin: 0 0 0 1rem;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
