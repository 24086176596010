import styled from "styled-components";
import ReactMarkdown from "react-markdown";

export const Text = styled.div<{ sub?: boolean; mb?: Space; mr?: Space }>`
  color: ${(props) => props.theme.primaryFontColour};
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: ${(props) => getSpace(props.mb)};
  margin-right: ${(props) => getSpace(props.mr)};
  ${(props) =>
    props.sub &&
    `
    font-size: 1.5rem;
    font-weight: 400;
  `};
`;

export const Separator = styled.div`
  margin: 14px 0;
  border-bottom: 1px solid ${(props) => props.theme.containerBorderColour};
  width: 100%;
`;

export const Flex = styled.div<{
  direction?: "column" | "row";
  alignItems?: "flex-start" | "center" | "flex-end";
  justifyContent?: "flex-start" | "center" | "flex-end";
  mb?: Space;
  mr?: Space;
}>`
  display: flex;
  flex-direction: ${(props) => props.direction ?? "row"};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  margin-bottom: ${(props) => getSpace(props.mb)};
  margin-right: ${(props) => getSpace(props.mr)};
`;

export const Checkbox = styled.div`
  border: 0.1rem solid #d4d4d4;
  cursor: pointer;
  margin: 0 1rem 0 0;
  padding: 0.2rem;
`;

export const Check = styled.div<{
  checked?: boolean;
}>`
  background: ${(props) => (props.checked ? props.theme.brandColour : "#fff")};
  /* display: flex; */
  height: 1rem;
  width: 1rem;
`;

export const Option = styled.div<{ top?: boolean }>`
  align-items: center;
  align-self: flex-start;
  display: flex;
  ${(props) => props.top && "margin: 0 0 2rem 0"};
`;

type Space = "xs" | "sm" | "md" | "lg";
const getSpace = (unit: Space) =>
  ({
    xs: "8px",
    sm: "12px",
    md: "16px",
    lg: "28px",
  }[unit] || 0);

export const Markdown = styled(ReactMarkdown)`
  font-size: ${(p) => p.theme.fontSizeSmall};
  color: ${(p) => p.theme.primaryFontColour};
`;
