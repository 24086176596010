import { SendRequest } from "../makeSendRequest";
import {
  ExploreProgramGroupDocument,
  ExploreProgramGroupInsert,
  ExploreProgramGroupUpdate,
  ExploreProgramGroupWithPrograms,
} from "./types";

export const createExploreProgramGroupApi = (sendRequest: SendRequest) => ({
  list: async () => {
    const response = await sendRequest<ExploreProgramGroupDocument[]>({
      method: "get",
      path: `explore-program-group`,
      version: "v3",
    });

    return response;
  },
  create: async (data: ExploreProgramGroupInsert) => {
    const response = await sendRequest<{
      success: true;
      _id: string;
    }>({
      method: "post",
      path: "explore-program-group",
      version: "v3",
      data,
    });

    return response;
  },
  updateExploreProgramGroup: async (
    id: string,
    data: Partial<ExploreProgramGroupUpdate>,
  ) => {
    const response = await sendRequest<{
      success: true;
    }>({
      method: "put",
      path: `explore-program-group/${id}`,
      version: "v3",
      data,
    });

    return response;
  },
  fetchById: async (id: string) => {
    const response = await sendRequest<ExploreProgramGroupWithPrograms>({
      method: "get",
      path: `explore-program-group/${id}`,
      version: "v3",
    });

    return response;
  },
  deleteExploreProgramGroup: async (id: string) => {
    const response = await sendRequest<{
      success: true;
    }>({
      method: "delete",
      path: `explore-program-group/${id}`,
      version: "v3",
    });

    return response;
  },
  updatePosition: async (id: string, data: Record<string, string>) => {
    const response = await sendRequest<{
      success: true;
    }>({
      method: "put",
      path: `explore-program-group/${id}`,
      version: "v3",
      data,
    });

    return response;
  },
});
