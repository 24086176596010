import { animated } from "react-spring";
import styled from "styled-components";

import Icon from "../../../../icons";
import { media } from "../../../../styles/mediaQueries";

export const Frame = styled.div`
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  /* vertical-align: middle; */
  color: #000;
  fill: white;
  /* padding: 1rem 0 1rem 0; */
`;

export const Wrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  margin: 0 0 1rem 0;
`;

export const Title = styled.div`
  color: ${(props) =>
    props.selected
      ? props.theme.sideBarItemSelectedTextColour
      : props.theme.sideBarItemTextColour};
  /* font-size: 1.6rem; */
  font-weight: 600;
  margin: 0 0 0 1rem;
  display: none;

  ${(props) =>
    props.small &&
    `
    font-size: 1.4rem;
  `};

  ${media.tablet`
    display: block;
    font-size: 1.4rem;
    margin: 0 0 0 .7rem;

    ${(props) =>
      props.small &&
      `
      font-size: 1.2rem;
    `}
  `};
`;

export const StyledIcon = styled(Icon)`
  fill: ${(props) =>
    props.selected
      ? props.theme.sideBarItemSelectedTextColour
      : props.theme.sideBarItemTextColour};
  height: 2rem;
  width: 2rem;
  flex-shrink: 0;

  ${(props) =>
    props.small &&
    `
    height: 1.5rem;
    width: 1.5rem;
  `};

  ${media.tablet`
    height: 1.8rem;
    width: 1.8rem;

    ${(props) =>
      props.small &&
      `
      height: 1.3rem;
      width: 1.3rem;
    `};
  `};
`;

export const Content = styled(animated.div)`
  will-change: transform, opacity, height;
  overflow: hidden;
  margin: 0 0 0 0.5rem;
`;
