import { useStoreState } from "easy-peasy";
import { useToggle } from "../../toggle/useToggle";
import { useUser } from "../../../hooks";
import { useSubscriptionInfo } from "./useSubscriptionInfo";
import { subscriptionInfoHasEntitlement } from "../utils/utils";

export const useIsSubscribed = () => {
  const user = useUser();
  const subscriptionInfo = useSubscriptionInfo();

  const revenueCatUserIdValid =
    user?.revenueCatUserId === subscriptionInfo?.revenueCatUserId;
  const hasEntitlement = subscriptionInfoHasEntitlement(subscriptionInfo);
  const isSubscribedRevenueCat = revenueCatUserIdValid && hasEntitlement;

  const isSubscribedStore = useStoreState(
    (s) => s.computators.hasValidSubscription,
  );

  const featureRevenueCatIsSubscribedWeb = useToggle(
    "featureRevenueCatIsSubscribedWeb",
  );

  return featureRevenueCatIsSubscribedWeb
    ? isSubscribedRevenueCat
    : isSubscribedStore;
};
