import { useRouter } from "next/router";
import { getEnvConfig, StageNames } from "../utils/appConfig/envConfig";

const marketingRedirects = {
  "/about": "/about-us",
  "/about/our-squad": "/about-us",
  "/about/our-recipes": "/discover/#recipes",
  "/about/the-program": "/our-program",

  "/blogs": "/discover/#blog",
  "/blogs/[id]": "/discover/#blog",

  "/faqs": "/faq",
  "/contact-us": "/contact",
};

export const marketingUrls: Record<StageNames, string> = {
  local: "https://staging.keepitcleaner.com",
  staging: "https://staging.keepitcleaner.com",
  production: "https://keepitcleaner.com",
};

export const marketingUrl = marketingUrls[getEnvConfig().appStage];

export const useMarketingRedirect = () => {
  const router = useRouter();

  const redirectUrl = marketingUrl;
  const redirectRoute = marketingRedirects[router.route];

  if (redirectUrl && redirectRoute) {
    location.replace(`${redirectUrl}${redirectRoute}`);
  }

  return {
    redirectUrl,
    redirectRoute,
  };
};
