import { useContext } from "react";
import { SubscriptionInfoWrappedContext } from "../components/SubscriptionInfoProvider";

export const useSubscriptionInfo = () => {
  const subscriptionInfoWrapped = useContext(SubscriptionInfoWrappedContext);

  if (!subscriptionInfoWrapped) {
    throw new Error(
      "subscriptionInfo has to be used within <SubscriptionInfoProvider>",
    );
  }

  return subscriptionInfoWrapped.subscriptionInfo;
};
