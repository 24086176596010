import styled from "styled-components";
import { animated } from "react-spring";
import Icon from "../../../../icons";

export const Container = styled(animated.div)`
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 2;
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
`;

export const Brand = styled(Icon)`
  fill: ${(props) => props.theme.brandColour};
  height: 3rem;
  width: 8rem;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  fill: ${(props) => props.theme.authIconColour};
  height: 2rem;
  width: 2rem;
`;

export const Tabs = styled.div`
  flex: 1;
  padding: 0 1.5rem;
  margin: 2rem 0 0 0;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 1.5rem;
`;
