const initialState = {
  goals: [],
  goalsPageSettings: {},
  mealTypes: [],
  mealTypesPageSettings: {},
  ingredientCategories: [],
  ingredientCategoriesPageSettings: {},
  roles: [],
  rolesPageSettings: {},
  titles: [],
  titlesPageSettings: {},
};

export default initialState;
