export type ProfileState = typeof initialState;

const initialState = {
  userData: {
    accepted_terms: null,
    agreed_communications: null,
    device_tokens: [],
    date_of_birth: "",
    email: "",
    first_name: "",
    ftue: {},
    goal: {},
    hasUsedApp: null,
    inserted: null,
    kicmoji: {},
    last_name: "",
    meal_plan: "",
    meal_type: {},
    roles: [],
    sex: "",
    subscription: "",
    subscription_expiration: "",
    subscription_source: "",
    subscription_status: null,
    titles: [],
    updated: null,
    whitelist: false,
    __v: null,
  },
  hasShownConsent: false,
  allowTracking: true,
};

export default initialState;
