import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="1rem"
      height="1rem"
      viewBox="270 200 1000 1000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <title>{props.alt ?? ""}</title>

      <g
        transform="translate(0.000000,1101.000000) scale(0.100000,-0.100000)"
        stroke="none"
      >
        <path
          d="M4177 9086 c-3 -7 -27 -16 -54 -20 -26 -3 -60 -12 -75 -20 -15 -8
-40 -17 -57 -21 -16 -3 -35 -13 -42 -21 -6 -8 -16 -14 -22 -14 -20 0 -149 -94
-207 -150 -31 -30 -76 -78 -99 -107 -43 -54 -141 -216 -141 -234 0 -6 -6 -23
-14 -37 -20 -40 -54 -161 -60 -212 -3 -24 -10 -46 -15 -48 -15 -5 -15 -5059
-1 -5064 6 -2 15 -30 20 -63 30 -196 152 -421 312 -576 57 -55 185 -149 203
-149 5 0 16 -6 24 -13 20 -17 96 -44 176 -62 33 -7 61 -18 63 -24 3 -8 345
-11 1168 -11 1100 0 1165 -1 1170 -18 13 -43 91 -182 129 -232 139 -181 359
-331 520 -356 25 -3 52 -13 60 -20 20 -21 4480 -21 4500 0 8 7 37 16 67 20 29
4 57 11 63 15 5 5 28 14 49 21 41 13 139 67 189 104 206 151 384 440 412 668
4 34 13 59 21 62 21 8 21 5038 0 5055 -7 6 -17 34 -20 62 -17 125 -107 332
-195 449 -63 83 -197 211 -252 240 -13 7 -26 16 -29 20 -6 7 -69 37 -145 69
-27 12 -72 25 -100 30 -27 5 -51 14 -53 20 -3 8 -339 11 -1173 11 -983 0
-1169 2 -1169 14 0 7 -19 49 -41 92 -46 88 -142 215 -216 285 -104 100 -284
199 -390 215 -24 3 -50 13 -58 20 -20 21 -4510 21 -4518 0z m4533 -565 c25
-14 56 -33 69 -43 23 -17 -1 -18 -756 -18 -602 0 -782 -3 -791 -12 -7 -7 -36
-18 -65 -24 -71 -16 -149 -44 -168 -61 -8 -7 -19 -13 -24 -13 -18 0 -146 -94
-202 -148 -109 -105 -190 -227 -259 -387 -24 -54 -54 -168 -54 -200 0 -18 -7
-38 -15 -45 -13 -11 -15 -127 -15 -936 l0 -924 -90 0 -90 0 0 390 0 390 -669
0 c-368 0 -676 -3 -685 -6 -14 -5 -16 -49 -16 -390 l0 -384 -505 0 -505 0 2
1228 3 1227 23 59 c62 158 197 287 332 316 19 4 1025 7 2235 6 l2200 -1 45
-24z m3062 -644 c114 -56 208 -166 254 -297 l24 -70 0 -1220 0 -1220 -1375 0
-1375 0 0 390 0 390 -675 0 c-634 0 -676 -1 -683 -17 -4 -10 -7 -186 -7 -391
l0 -372 -505 0 -505 0 0 1223 0 1222 24 65 c60 160 199 295 331 320 19 4 1025
7 2235 6 l2200 -1 57 -28z m-5948 -1852 c4 -20 4 -192 0 -383 -4 -191 -4 -365
0 -385 l7 -37 299 0 300 0 0 -200 0 -200 -299 0 -298 0 -7 -112 c-3 -62 -3
-274 0 -470 l6 -358 -251 0 -251 0 0 470 0 470 -730 0 -730 0 2 198 3 197 728
3 727 2 0 420 0 420 244 0 244 0 6 -35z m3054 -1003 c-4 -219 -4 -409 -1 -423
l5 -26 1581 1 1582 1 3 -197 2 -198 -1585 0 -1585 0 0 -470 0 -470 -244 0
-245 0 -7 57 c-4 31 -4 187 0 345 4 158 4 344 0 413 l-7 125 -730 0 -730 0 6
107 c4 58 7 146 7 195 l0 88 724 0 725 0 7 22 c4 12 5 155 4 318 -2 162 -3
343 -2 403 l2 107 247 0 246 0 -5 -398z m-3984 -708 c3 -9 6 -211 6 -450 l0
-434 675 0 675 0 0 450 0 450 90 0 90 0 0 -770 0 -771 -1107 3 -1108 3 -60 29
c-71 35 -189 150 -221 217 -64 132 -59 70 -62 693 -2 313 -1 575 2 583 4 11
97 13 510 13 450 0 505 -2 510 -16z m3054 -1076 l2 -448 675 0 675 0 0 444 0
444 193 6 c105 4 723 6 1372 4 l1180 -3 3 -565 c2 -549 2 -567 -18 -627 -52
-154 -124 -236 -275 -313 l-50 -25 -2220 0 -2220 0 -57 28 c-65 32 -208 172
-208 204 0 5 -5 14 -12 21 -6 6 -22 44 -36 84 -24 73 -24 75 -29 620 -3 300
-3 553 0 562 6 15 56 16 514 14 l508 -3 3 -447z"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
