import state from "./admin.ingredients.state";
import actions from "./admin.ingredients.actions";
import computators from "./admin.ingredients.computators";
import listeners from "./admin.ingredients.listeners";

export default {
  ...state,
  ...actions,
  ...computators,
  ...listeners,
};
