import axios from "axios";
import { thunk } from "easy-peasy";

import { logAxiosError } from "../utils";

const createPost = ({ key, endpoint, parser }) =>
  thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    await getStoreActions().auth.checkTokenExpiration();

    const { token } = getStoreState().auth.authData;

    try {
      const { body } = parser(payload);

      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.post(endpoint, body, {
        headers,
      });

      const { data } = response;

      actions.addToPosts(data);

      return { data };
    } catch (error) {
      logAxiosError(error, key);
      return error.response.data;
    }
  });

export default createPost;
