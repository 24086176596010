import React, { useContext } from "react";
import { IsoCountry } from "../../api";

type Location = { Iso: IsoCountry };

const LocationContext = React.createContext<Location>({ Iso: "AU" });
export const useLocation = () => useContext(LocationContext);

export const LocationProvider: React.FC<{
  iso: string;
}> = ({ children, iso }) => {
  const location = { Iso: (iso ?? "AU") as IsoCountry };

  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
};
