import { UserSubscription } from "../api";
import { hasEpochExpired } from "./utils";

export const hasValidSubscription = (subscription?: UserSubscription) => {
  if (!subscription) {
    return false;
  }

  if (subscription.whitelist) {
    return true;
  }

  if (["past_due", "grace_period"].includes(subscription.status)) {
    return !hasEpochExpired(
      subscription.grace_period_subscription_expiration || 0,
    );
  }

  const defaultIsValid = !hasEpochExpired(
    subscription.subscription_expiration || 0,
  );

  return defaultIsValid;
};
