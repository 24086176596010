import { Masterclass } from "../../../../api";

export const requiresBUMPMedicalCheck = ({
  type_specific: { categories },
}: Masterclass) =>
  categories.pregnancy && !getBUMPStoredConsent()[categories.pregnancy[0]];

export const updateBUMPStoredConsent = (consent: BUMPStoredConsent) =>
  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({ ...getBUMPStoredConsent(), ...consent }),
  );

export const getBUMPStoredConsent = (): BUMPStoredConsent =>
  JSON.parse(localStorage.getItem(STORAGE_KEY) ?? "{}");

const STORAGE_KEY = "@KIC:BUMPMedicalConsent";
type BUMPStoredConsent = { prenatal?: boolean; postnatal?: boolean };
