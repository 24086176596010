import { SendRequest } from "../makeSendRequest";

type ThumbnailResponse = {
  thumbnail: string;
};

export const createVimeoApi = (sendRequest: SendRequest) => ({
  fetchThumbnail: async (videoId: string) => {
    const response = await sendRequest<ThumbnailResponse>({
      method: "get",
      path: `vimeo/${videoId}`,
      version: "v3",
    });

    return response;
  },
});
