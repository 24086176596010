import React, { useMemo } from "react";

import { Discount, Plan } from "../../../../api";
import { Box, BoxProps, Text } from "../../../../ui";

import { PlanDuration, PromotionDuration } from "./PlanDuration";
import { PlanPrice } from "../PlanPrice/PlanPrice";
import { useLocale } from "../../../../hooks/useLocale";
import { usePlanLocale } from "../../../SubscriptionPlans/hooks/usePlanLocale";

interface PaymentSummaryProps extends BoxProps {
  plan?: Plan;
  discount?: Discount;
}

const InnerPaymentSummary: React.FC<PaymentSummaryProps> = ({
  plan,
  discount,
  ...props
}) => {
  const trial = plan?.trial_duration > 0;

  if (!plan) return null;

  return (
    <Box flexDirection="column" data-cy="checkout-payment-summary" {...props}>
      {trial && (
        <>
          <LineItem
            data-cy="paymentSummaryTrial"
            isFree
            plan={plan}
            discount={discount}
            text={`First ${plan?.trial_duration} ${plan?.trial_duration_unit}s`}
            mb="xs"
          />

          {!discount && (
            <LineItem
              data-cy="paymentSummaryAfterTrial"
              plan={plan}
              text="After trial"
              durationType="plan"
            />
          )}
        </>
      )}

      {discount && !discount?.permanent && (
        <>
          <LineItem
            data-cy="paymentSummaryPromotion"
            plan={plan}
            discount={discount}
            text="Promotion period"
            durationType="promotion"
            mb="xs"
          />

          <LineItem
            data-cy="paymentSummaryAfterPromotion"
            plan={plan}
            text="After promotion"
            durationType="plan"
          />
        </>
      )}

      {((!trial && !discount) || discount?.permanent) && (
        <LineItem
          data-cy="paymentSummaryTotal"
          plan={plan}
          discount={discount}
          text="Total"
          durationType="plan"
        />
      )}
    </Box>
  );
};

export const PaymentSummary = React.memo(InnerPaymentSummary);

const InnerLineItem: React.FC<
  BoxProps & {
    plan?: Plan;
    discount?: Discount;
    durationType?: "none" | "plan" | "promotion";
    text: string;
    isFree?: boolean;
  }
> = ({ plan, discount, durationType = "none", text, isFree, ...rest }) => {
  const locale = useLocale();
  const { planLocale } = usePlanLocale({ plan });
  const formattedFreePrice = useMemo(() => {
    return locale.formatPrice({
      price: 0,
      currency: planLocale.currency,
      forceDecimals: true,
    });
  }, [planLocale, locale]);

  return (
    <Box {...rest} flexDirection="row" justifyContent="space-between">
      <Box flexDirection="row" alignItems="baseline">
        <Text variant="intro" mr={durationType ? "sm" : "none"}>
          {text}
        </Text>

        {durationType === "plan" && <PlanDuration {...{ plan }} />}
        {durationType === "promotion" && <PromotionDuration {...plan} />}
      </Box>

      {isFree ? (
        <Text variant="intro">{formattedFreePrice}</Text>
      ) : (
        <PlanPrice {...{ plan, discount }} withDecimals />
      )}
    </Box>
  );
};

const LineItem = React.memo(InnerLineItem);
