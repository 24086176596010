import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 18.4 18.4" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M16.31 5.19H2.06a2.09 2.09 0 000 4.17h1.18V12a1.58 1.58 0 103.16 0V9.36h5.44V12A1.58 1.58 0 1015 12V9.36h1.31a2.09 2.09 0 000-4.17zM5.68 9.36V12A.86.86 0 114 12V9.36zm8.6 0V12a.86.86 0 11-1.72 0V9.36zm2-.73H2.06a1.36 1.36 0 010-2.71h14.25a1.36 1.36 0 010 2.71z" />
    </svg>
  );
}

export default SvgComponent;
