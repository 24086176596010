import React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 99.7 99.7"
      xmlSpace="preserve"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path d="M99.6 48.4V48c0-.6-.1-1.3-.1-1.9v-.5c0-.5-.1-1.1-.2-1.6 0-.3-.1-.6-.1-.9-.1-.4-.1-.8-.2-1.2-.1-.5-.2-.9-.2-1.4 0-.2-.1-.4-.1-.6-1.8-9-6.2-17.7-13.1-24.8l-2.2-2.2c-.6-.5-1.1-1-1.7-1.5-.2-.2-.4-.3-.6-.5-.4-.3-.8-.7-1.3-1-.4-.3-.8-.6-1.2-.8-.3-.2-.5-.4-.8-.5-.5-.4-1.1-.7-1.7-1.1-.1-.1-.2-.2-.4-.2-.7-.4-1.3-.8-2-1.1 0 0-.1 0-.1-.1-.6-.4-1.3-.7-2-1.1 0 0-.1 0-.1-.1-.6-.3-1.3-.6-1.9-.9-.2 0-.3 0-.4-.1-.5-.2-1.1-.4-1.7-.7-.2-.1-.4-.2-.7-.3-.5-.2-.9-.3-1.4-.5-.3-.1-.6-.2-1-.3h-.1c-.7-.2-1.3-.4-2-.6-.2 0-.3-.1-.5-.1-.6-.1-1.1-.3-1.7-.4-.2 0-.4 0-.7-.1-.6-.1-1.1-.2-1.7-.3-.2 0-.4-.1-.6-.1L55 .2c-.3 0-.6-.1-.8-.1-.5 0-.9-.1-1.4-.1h-3-.1C37.3 0 25.5 4.6 16.4 13l-.1.1c-.3.3-.6.5-.9.8l-.1.1C10.5 18.6 6.6 24.2 4 30.4c-.1.1-.1.3-.2.4C1.3 36.8 0 43.2 0 49.9c0 27.5 22.3 49.8 49.8 49.8 13.5 0 25.8-5.4 34.7-14.2l.2-.2.8-.8c.3-.4.7-.7 1-1.1l.2-.2c6.8-7.5 10.9-16.5 12.3-26 0-.2.1-.5.1-.7.1-.5.1-1 .2-1.5s.1-1 .1-1.4c0-.3 0-.6.1-.8 0-.6.1-1.2.1-1.9v-.5-2zM84 81.8l-.9.9C67.8 78.4 54 70 42.9 58.5c-13-13-22.1-28.6-24.9-42.9 5.1-4.7 11.2-8.3 18-10.4 1.2 12.7 8.9 27.3 21 39.3 11.2 11.2 24.9 18.8 37 20.7-2.1 6-5.4 11.5-9.5 16.1-.2.2-.4.3-.5.5zM39.3 4.3c.5-.1 1-.2 1.6-.3 2.9-.6 5.9-.9 8.9-.9 4.8 0 9.4.7 13.7 2.1-2.2 6.5 1.3 16.2 9 23.9 6.1 6.1 13.5 9.7 19.6 9.7 1.1 0 2.1-.1 3.1-.4.9 3.7 1.4 7.6 1.4 11.6 0 3.2-.3 6.3-.9 9.3v.2c-.1.6-.3 1.2-.4 1.8-.1.2-.1.4-.2.7 0 .1-.1.3-.1.4-11.6-1.7-24.9-9-35.8-19.9C47.4 30.6 39.9 16.5 39 4.4c.1-.1.2-.1.3-.1zm55 31.2c-5.4 1.2-13.4-2.2-19.7-8.6-6.8-6.8-10.2-15.4-8.3-20.8 1.7.6 3.3 1.3 4.8 2.2 3.9 2 7.5 4.5 10.6 7.5l1.8 1.8.1.1c4.8 5 8.5 11 10.7 17.8zM15.5 18.1c3.3 14.3 12.4 29.7 25.3 42.5C51.7 72 65.4 80.5 80.6 85.1c-4.9 4.3-10.7 7.6-17.1 9.5-11.8-1.4-25.5-8.9-36.6-20C13.5 61.2 5.6 44.4 6.7 31.7c2.1-5.1 5.1-9.7 8.8-13.6zM3 49.9c0-3.4.4-6.7 1.1-9.9C6 52 13.4 65.4 24.7 76.8c9.5 9.5 20.9 16.5 31.5 19.5-2.1.3-4.2.5-6.4.5C24 96.7 3 75.7 3 49.9z" />
    </svg>
  );
}

export default SvgComponent;
