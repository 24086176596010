/* eslint-disable operator-assignment */
/* eslint-disable no-param-reassign */
import axios from "axios";
import { action, thunk, Thunk, Action } from "easy-peasy";
import { StoreModel } from "../..";

import { KIC_API } from "../../../src/api/index";
import { logAxiosError } from "../../../src/utils/utils";
import initialState from "./admin.users.state";
import { EasyUserModel } from "./admin.users.store";

type UserModelThunk<Payload> = Thunk<EasyUserModel, Payload, any, StoreModel>;

export type UserActions = {
  addSubscriptionToUserList: Action<EasyUserModel, any>;
  createUserSubscription: UserModelThunk<any>;
  fetchUsersSubscriptions: UserModelThunk<any>;
  updateUserSubscription: UserModelThunk<any>;

  addUser: UserModelThunk<{ email: string; password: string }>;
  registerUser: UserModelThunk<any>;
  fetchUsers: UserModelThunk<any>;
  fetchUser: UserModelThunk<any>;
  deleteUser: UserModelThunk<any>;
  updateUser: UserModelThunk<any>;
  setUsers: Action<EasyUserModel, any>;
  appendToUsers: Action<EasyUserModel, any>;

  clearState: Action<EasyUserModel, void>;
  deletedUser: Action<EasyUserModel, any>;

  updatedUser: Action<EasyUserModel, any>;
  migrateUserData: UserModelThunk<any>;
  migrateUserSubscription: UserModelThunk<any>;
  setPageSettings: Action<EasyUserModel, any>;
  setPageNumber: Action<EasyUserModel, any>;
};

const actions: UserActions = {
  addSubscriptionToUserList: action<EasyUserModel, any>((state, payload) => {
    const { id, data } = payload;
    state.users = state.users.map((user) =>
      user._id === id ? { ...user, subscription: data } : user,
    );
  }),
  addUser: thunk<EasyUserModel, any, any, StoreModel>(
    async (actions, payload) => {
      const { email, password } = payload;

      const { error, user } = await actions.registerUser({ email, password });

      if (error) {
        return { error };
      }

      actions.appendToUsers(user);

      return { id: user._id };
    },
  ),
  appendToUsers: action<EasyUserModel, any>((state, payload) => {
    state.users.unshift(payload);
  }),
  clearState: action<EasyUserModel>((state) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = value;
    }
  }),
  createUserSubscription: thunk<EasyUserModel, any, any, StoreModel>(
    async (actions, payload, { getStoreState, getStoreActions }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          `${KIC_API}/api/v1/usersubscriptions`,
          payload,
          { headers },
        );

        return response.data;
      } catch (error) {
        logAxiosError(error, "createUserSubscription");
        return error.response.data;
      }
    },
  ),
  deleteUser: thunk<EasyUserModel, any, any, StoreModel>(
    async (actions, payload, { getStoreState, getStoreActions }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.delete(
          `${KIC_API}/api/v2/users/${payload}`,
          { headers },
        );

        actions.deletedUser(payload);

        return response.data;
      } catch (error) {
        return error.response.data;
      }
    },
  ),
  deletedUser: action<EasyUserModel, any>((state, payload) => {
    state.users = state.users.filter((user) => user._id !== payload);
  }),
  fetchUsers: thunk<EasyUserModel, any, any, StoreModel>(
    async (actions, payload, { getStoreState, getStoreActions }) => {
      await getStoreActions().auth.checkTokenExpiration();
      const { token } = getStoreState().auth.authData;

      try {
        const { pageNumber, search, limit } = payload;

        const headers = { Authorization: `Bearer ${token}` };
        const requestUrl = `${KIC_API}/api/v2/users/?page=${pageNumber}${
          limit ? `&limit=${limit}` : ""
        }${search ? `&search=${encodeURIComponent(search)}` : ""}`;

        const response = await axios.get(requestUrl, { headers });

        actions.setUsers(response.data.data);
        actions.setPageSettings(response.data.settings);

        return response.data;
      } catch (error) {
        logAxiosError(error, "fetchUsers");
        return error.response.data;
      }
    },
  ),
  fetchUser: thunk<EasyUserModel, any, any, StoreModel>(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(`${KIC_API}/api/v2/users/${payload}`, {
          headers,
        });

        return response;
      } catch (error) {
        logAxiosError(error, "fetchUser");
        return error.response;
      }
    },
  ),
  fetchUsersSubscriptions: thunk<EasyUserModel, any, any, StoreModel>(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(
          `${KIC_API}/api/v3/user-subscription/list-for-user?userId=${payload}`,
          { headers },
        );

        return response.data;
      } catch (error) {
        logAxiosError(error, "fetchUsersSubscriptions");
        return error.response.data;
      }
    },
  ),
  migrateUserData: thunk<EasyUserModel, any, any, StoreModel>(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          `${KIC_API}/api/v1/manualmigrate/userdata`,
          payload,
          { headers },
        );

        return response.data;
      } catch (error) {
        logAxiosError(error, "migrateUserData");
        return error.response.data;
      }
    },
  ),
  migrateUserSubscription: thunk<EasyUserModel, any, any, StoreModel>(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          `${KIC_API}/api/v1/manualmigrate/subscription`,
          payload,
          { headers },
        );

        return response.data;
      } catch (error) {
        logAxiosError(error, "migrateUserSubscription");
        return error.response.data;
      }
    },
  ),
  registerUser: thunk<EasyUserModel, any, any, StoreModel>(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          `${KIC_API}/api/v2/users/register`,
          payload,
          { headers },
        );

        return response.data;
      } catch (error) {
        logAxiosError(error, "registerUser");
        return error.response.data;
      }
    },
  ),
  setPageSettings: action<EasyUserModel, any>((state, payload) => {
    state.pageSettings = payload;
  }),
  setPageNumber: action<EasyUserModel, any>((state, payload) => {
    state.pageNumber = payload;
  }),
  setUsers: action<EasyUserModel, any>((state, payload) => {
    state.users = payload;
  }),
  updateUser: thunk<EasyUserModel, any, any, StoreModel>(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { _id, ...rest } = payload;

        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(
          `${KIC_API}/api/v2/users/${_id}`,
          rest,
          { headers },
        );

        actions.updatedUser(response.data);

        return response;
      } catch (error) {
        logAxiosError(error, "updateUser");
        return error.response.data;
      }
    },
  ),
  updateUserSubscription: thunk<EasyUserModel, any, any, StoreModel>(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        // TODO: remove subId when release usersv2
        const { _id, subId, ...rest } = payload;
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(
          `${KIC_API}/api/v1/usersubscriptions/${_id || subId}`,
          rest,
          { headers },
        );

        return response.data;
      } catch (error) {
        logAxiosError(error, "updateUserSubscription");
        return error.response.data;
      }
    },
  ),
  updatedUser: action<EasyUserModel, any>((state, payload) => {
    state.users = state.users.map((user) =>
      user._id === payload._id ? payload : user,
    );
  }),
};

export default actions;
