import getConfig from "next/config";

export type EnvConfig = {
  kicweb_api: string;
  kic_stripe_publishable: string;
  kicweb_branch_key: string;
  kicweb_bugsnag_key: string;
  kic_web_segment_write_key: string;
  kic_web_pixel_id: string;
  appStage: StageNames;
  APP_VERSION: string;
  kicweb_hotjar_site_id: number;
  kicweb_tiktok_web_pixel_code: string;
  kicweb_facebook_app_id: string;
  kic_web_captcha_site_key: string;
  kic_web_unleash_client_key: string;
  kic_web_google_oauth_client_id: string;
  revenueCat: { apiKey: string; userUrl: string };
  mixpanel: { userUrl: string };
  braze: { userUrl: string };
};

export type StageNames = "production" | "staging" | "local";

export const getEnvConfig = (): EnvConfig => {
  const config = getConfig();
  if (!config) {
    console.error(
      "No Next.js config detected! Ignore this, if you're running tests/Storybook!",
    );
    return {} as EnvConfig;
  }

  const { app_stage, ...envConfig } = config.publicRuntimeConfig;
  const appStage = _makeStageName(app_stage);
  return {
    appStage,
    ...envConfig,
    revenueCat: makeRevenueCatConfig(app_stage),
    mixpanel: makeMixpanelConfig(app_stage),
    braze: makeBrazeConfig(app_stage),
  } as EnvConfig;
};

const _makeStageName = (appStage: string): StageNames => {
  if (appStage === "production") {
    return "production";
  } else if (appStage === "staging") {
    return "staging";
  }
  return "local";
};

const makeRevenueCatConfig = (appStage: StageNames) => ({
  apiKey:
    appStage === "production"
      ? "rcb_nwbJvMRyMrNNUYXxlssUfjjTORsM"
      : "rcb_sb_zNRwTOXKVWXjmzgWsmEtpwvKF",
  userUrl: "https://app.revenuecat.com/customers/97b10739/",
});

const makeMixpanelConfig = (appStage: StageNames) => ({
  userUrl:
    appStage === "production"
      ? "https://eu.mixpanel.com/project/2998169/view/3516965/app/profile#distinct_id="
      : "https://eu.mixpanel.com/project/2999792/view/3518568/app/profile#distinct_id=",
});

const makeBrazeConfig = (appStage: StageNames) => ({
  userUrl:
    appStage === "production"
      ? "https://dashboard-05.braze.com/users/user_search/643655bc1e5d26004a7d045d?query="
      : "https://dashboard-05.braze.com/users/user_search/643655bdc61b57004a3ff323?query=",
});
