import { computed } from "easy-peasy";

import { hasValidSubscription } from "../../src/utils/hasValidSubscription";

const computators = {
  // auth
  isLoggedIn: computed(
    [(state, storeState) => storeState.auth.authData.token],
    (token) => !!token,
  ),
  visibleSubscriptions: computed(
    [(state, storeState) => storeState.auth.subscriptions],
    (subscriptions) =>
      subscriptions
        .filter((sub) => sub.active)
        .sort((a, b) => a.price - b.price),
  ),
  hasValidSubscription: computed(
    [
      // eslint-disable-next-line camelcase
      (state, storeState) => storeState.auth.subscriptionData.user_subscription,
      (state, storeState) => storeState.profile.userData.whitelist,
    ],
    (userSubscription, whitelist) =>
      hasValidSubscription(userSubscription || {}) || whitelist,
  ),

  // profile
  orderedRoles: computed(
    [(state, storeState) => storeState.profile.userData?.roles],
    (roles) => (roles ? roles.sort((a, b) => b.strength - a.strength) : []),
  ),

  // tags
  parsedGoals: computed(
    [(state, storeState) => storeState.tags.goals],
    (goals) => goals.map((goal) => ({ value: goal._id, label: goal.title })),
  ),
  parsedMealTypes: computed(
    [(state, storeState) => storeState.tags.mealTypes],
    (mealTypes) =>
      mealTypes.map((type) => ({ value: type._id, label: type.title })),
  ),
  parsedRoles: computed(
    [(state, storeState) => storeState.tags.roles],
    (roles) =>
      roles.map((role) => ({
        value: role._id,
        label: role.title,
      })),
  ),
  parsedTitles: computed(
    [(state, storeState) => storeState.tags.titles],
    (titles) =>
      titles.map((title) => ({
        value: title._id,
        label: title.name,
      })),
  ),

  // payment
  parsedSubscriptions: computed(
    [(state, storeState) => storeState.payments.subscriptions],
    (subscriptions) =>
      subscriptions.map((subscription) => ({
        value: subscription._id,
        label: subscription.name,
      })),
  ),
};

export default computators;
