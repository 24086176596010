import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { NotifiableError } from "@bugsnag/core";
import {
  getAnalytics as getFirebaseAnalytics,
  setAnalyticsCollectionEnabled,
  setCurrentScreen as setFirebaseCurrentScreen,
  setUserProperties as setFirebaseUserProperties,
  setUserId as setFirebaseUserId,
} from "firebase/analytics";
import { initializeApp, getApps } from "firebase/app";
import {
  getId as getFirebaseId,
  getInstallations as getFirebaseInstallations,
} from "@firebase/installations";
import Cookies from "js-cookie";

import firebaseConfig from "../../config/firebase-config.json";
import * as pixel from "./pixel";
import * as branch from "./branch";
import * as hotjar from "./hotjar";
import { getEnvConfig } from "./appConfig/envConfig";
import {
  getTikTokUserClickId,
  getTikTokUserPixelId,
  initTikTokPixel,
  removeInitTikTokPixel,
} from "./tiktok";
import { SegmentEvents } from "./analytics/SegmentEvents";
import { EventProperties } from "./analytics/analyticEventProperties";
import { PlatformEventProperties } from "./analytics/platformAnalytics";
import { clearAffiliateCookie, refreshAffiliateCookie } from "./affiliate";
import { clearPartnerCookie, refreshPartnerCookie } from "./partner";

const env = getEnvConfig();

if (!env.kicweb_bugsnag_key)
  console.error(
    "\nenv.kicweb_bugsnag_key not found! Have you run generate-dotenv.sh?\n",
  );

if (!(Bugsnag as any)._client && env.kicweb_bugsnag_key) {
  Bugsnag.start({
    apiKey: env.kicweb_bugsnag_key,
    releaseStage: env.appStage,
    appVersion: env.APP_VERSION,

    plugins: [new BugsnagPluginReact()],
    autoDetectErrors: true,
    autoTrackSessions: true,
  });
}

type IdentifyProps = {
  _id?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  facebook_user_id?: string;
};

type WebAttribution = {
  user_agent?: string;
  browser_id?: string;
  fb_browser_fingerprint?: string;
  fb_click_fingerprint?: string;
  firebase_installation_id?: string;
  gtag_client_id?: string;
  google_click_id?: string;
  tiktok_user_pixel_id?: string;
  tiktok_user_click_id?: string;
};

const UserAttributes = {
  BumpEmailedOptedIn: "emailBumpApproved",
  DietType: "chosenDietType",
};

const SegmentUserAttributes = {
  FeatureFlags: "feature_flag_configuration",
};

let trackingEnabled = false;

export const initialise = (enableCollection = true, user: IdentifyProps) => {
  if (!getApps().length) {
    initializeApp(firebaseConfig);
    pixel.initPixel({ ...user, allowTracking: enableCollection });
  }

  if (enableCollection) refreshAffiliateCookie();
  else clearAffiliateCookie();

  if (enableCollection) {
    refreshPartnerCookie();
  } else {
    clearPartnerCookie();
  }

  setAnalyticsCollectionEnabled(getFirebaseAnalytics(), enableCollection);

  if (!window.analytics.initialized) {
    const segmentWriteKey = env.kic_web_segment_write_key;
    window.analytics._writeKey = segmentWriteKey;
    window.analytics.load(segmentWriteKey);
  }

  if (!enableCollection) {
    trackingEnabled = false;
    pixel.revokeConsent();
    removeInitTikTokPixel();
    window.analytics.reset();
    hotjar.identifyUser({ userId: null });
    Bugsnag.setUser(null);
    setFirebaseUserId(getFirebaseAnalytics(), null, { global: true });
  }

  if (enableCollection && !trackingEnabled) {
    trackingEnabled = true;

    pixel.grantConsent();
    hotjar.initializeHotjar();
    initTikTokPixel(env.kicweb_tiktok_web_pixel_code);
    identify(user);
  }
};
const globalProps: PlatformEventProperties = {
  eventName: "platform",
  platform_name: "web",
};

export const logEvent = (event: EventProperties) => {
  const { eventName } = event;

  const name = SegmentEvents[eventName];

  if (process.env.NODE_ENV === "development") {
    console.log(
      `event ${
        trackingEnabled ? "SENT" : "QUEUED"
      }: ${name} with ${JSON.stringify(event)}`,
    );
    if (!name) {
      console.warn("No Name for:", eventName);
    }
  }

  if (!trackingEnabled) {
    return;
  }

  if (SegmentEvents[eventName]) {
    const { eventName, ...properties } = {
      ...globalProps,
      ...event,
    };
    window.analytics.track(name, properties);
  }

  Bugsnag.leaveBreadcrumb(name, event);
};

export const setScreen = (screenName: string) => {
  window.analytics.page(screenName);
  setFirebaseCurrentScreen(getFirebaseAnalytics(), screenName);
  Bugsnag.leaveBreadcrumb("Screen Change", { to: screenName }, "navigation");
};

export const logUserAttribute = (
  attributeName:
    | keyof typeof UserAttributes
    | keyof typeof SegmentUserAttributes,
  value: string | number | boolean | JSON,
) => {
  if (trackingEnabled) {
    const legacyUserAttribute = UserAttributes[attributeName];
    const segmentUserAttribute = SegmentUserAttributes[attributeName];

    if (legacyUserAttribute) {
      try {
        setFirebaseUserProperties(getFirebaseAnalytics(), {
          [attributeName]: value,
        });
      } catch (e) {
        console.log("Error Sending User Attribute", e);
      }
    }

    if (segmentUserAttribute) {
      const segmentUser = window.analytics.user && window.analytics.user();

      const segmentUserID = segmentUser?.id();
      const existingValue = segmentUser?.traits()?.[segmentUserAttribute];

      if (segmentUserID && existingValue !== value) {
        const segmentUserTraits = { [segmentUserAttribute]: value };

        window.analytics.identify(segmentUser.userId, segmentUserTraits);
      }
    }
  }
};

export const identify = (user: IdentifyProps) => {
  if (trackingEnabled) {
    Bugsnag.setUser(user._id, user.email, user.first_name);
    setFirebaseUserId(getFirebaseAnalytics(), user._id, { global: true });

    if (user._id) {
      hotjar.identifyUser({ userId: user._id });
      const traits = {
        email: user.email,
        ...(user.first_name !== "KIC" &&
          user.last_name !== "Member" && {
            first_name: user.first_name,
            last_name: user.last_name,
          }),
      };
      window.analytics.identify(user._id, traits);
    }
  }
};

export const notifyError = (
  error: NotifiableError,
  params?: Record<string, unknown>,
) => {
  if (params) Bugsnag.leaveBreadcrumb("Error Parameters", params, "log");
  if (env.appStage !== "production") console.error(error);
  Bugsnag.notify(error);
};

const getBranchFingerprint = async () => {
  try {
    const fingerprint = (await branch.getBrowserFingerprint()) as {
      data: string;
    };
    return fingerprint.data;
  } catch (error) {
    notifyError(error);
  }
  return undefined;
};

const getFirebaseInstallationId = async () => {
  try {
    return getFirebaseId(getFirebaseInstallations());
  } catch (error) {
    notifyError(error);
  }
  return undefined;
};

const getGtagClientId = () => {
  try {
    return Cookies.get(
      "_ga_" +
        getFirebaseAnalytics()?.app?.options?.measurementId?.substring(2),
    );
  } catch (error) {
    notifyError(error);
  }
  return undefined;
};

const getGoogleClickId = () => {
  return Cookies.get("_gclid");
};

export const getBrowserFingerprint = async (): Promise<WebAttribution> => {
  return {
    ...(trackingEnabled && {
      user_agent: navigator?.userAgent,
      browser_id: await getBranchFingerprint(),
      fb_browser_fingerprint: pixel.getFbBrowserFingerprint(),
    }),

    fb_click_fingerprint: pixel.getFbClickFingerprint(),
    firebase_installation_id: await getFirebaseInstallationId(),
    gtag_client_id: getGtagClientId(),
    google_click_id: getGoogleClickId(),
    tiktok_user_pixel_id: getTikTokUserPixelId(),
    tiktok_user_click_id: getTikTokUserClickId(),
  };
};

export const onLogout = () => {
  if (trackingEnabled) {
    // todo: no segment logout?
  }
};

export const ErrorBoundary =
  env.appStage === "local"
    ? ({ children }) => children
    : Bugsnag.getPlugin("react")?.createErrorBoundary(React) ?? (() => null);
