import { SendRequest } from "../makeSendRequest";
import { ApiPaginatedResponse } from "../types";

type TrainerListParams = {
  limit?: number;
};

export type Trainer = {
  _id: string;
  bio: string;
  card_photo: string;
  cover_photo: string;
  avatar?: string;
  display_name: string;
  enabled: boolean;
  icon: string;
  instagram: string;
  name: string;
  photo: string;
  position: string;
  video: string;
  list: boolean;
  inserted: number;
  updated: number;
  discipline: string;
};

export const createTrainersApi = (sendRequest: SendRequest) => ({
  list: async (parameters?: TrainerListParams) =>
    sendRequest<ApiPaginatedResponse<Trainer[]>>({
      method: "get",
      path: "trainers",
      version: "v2",
      params: {
        ...parameters,
      },
    }),
});
