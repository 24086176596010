import { SendRequest } from "../makeSendRequest";
import { RunType } from "./types";

const BASE_PATH = "run-type";

export const createRunTypesApi = (sendRequest: SendRequest) => ({
  fetch: async (id: string) => {
    return await sendRequest<RunType>({
      method: "get",
      path: `${BASE_PATH}/${id}`,
      version: "v3",
    });
  },
  list: async () => {
    const response = await sendRequest<RunType[]>({
      method: "get",
      path: BASE_PATH,
      version: "v3",
    });

    return response;
  },

  delete: async (id: string) =>
    sendRequest<{ result: boolean }>({
      method: "delete",
      path: `${BASE_PATH}/${id}`,
      version: "v3",
    }),
  create: async (runType: RunType) =>
    sendRequest<RunType & { _id: string }>({
      method: "post",
      path: BASE_PATH,
      version: "v3",
      data: runType,
    }),
  update: async (id: string, runType: Partial<RunType>) => {
    return sendRequest<boolean>({
      method: "put",
      path: `${BASE_PATH}/${id}`,
      version: "v3",
      data: runType,
    });
  },
});
