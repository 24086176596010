import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      width="1rem"
      height="1rem"
      viewBox="250 450 1100 1100"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path
        transform="translate(0.000000,1749.000000) scale(0.100000,-0.100000)"
        d="M7905 13175 c-11 -2 -69 -9 -130 -15 -761 -81 -1486 -435 -2037 -996
-65 -65 -118 -124 -118 -130 0 -6 -10 -19 -21 -30 -51 -45 -212 -280 -309
-449 -224 -390 -380 -864 -420 -1280 -6 -66 -16 -136 -21 -156 -12 -48 -12
-404 0 -443 6 -17 15 -84 21 -150 30 -336 148 -738 315 -1081 320 -657 824
-1163 1480 -1485 150 -74 330 -147 461 -187 65 -20 57 -7 114 -193 199 -642
606 -1224 1130 -1616 572 -429 1220 -653 1936 -671 780 -20 1551 251 2176 764
439 361 759 828 998 1458 7 19 18 45 39 98 6 12 17 58 26 102 10 44 21 88 26
97 5 10 9 27 9 37 0 11 4 23 10 26 6 3 10 30 10 59 0 28 4 56 9 62 6 5 13 51
17 102 4 51 11 95 15 98 12 7 11 737 0 750 -5 5 -11 34 -14 64 -7 75 -45 290
-56 315 -10 21 -16 45 -32 132 -5 29 -17 68 -27 85 -11 18 -22 52 -25 74 -4
23 -16 59 -26 80 -16 31 -34 90 -32 101 0 2 -13 28 -29 59 -17 31 -30 61 -30
66 0 16 -120 257 -131 264 -8 5 -24 34 -34 64 -9 27 -22 48 -36 61 -10 8 -21
27 -24 41 -4 15 -15 34 -26 44 -10 9 -19 21 -19 26 0 5 -11 19 -25 32 -14 13
-25 28 -25 33 0 5 -9 18 -20 28 -11 10 -20 22 -20 28 0 5 -9 19 -19 31 -11 11
-76 84 -144 161 -234 264 -529 506 -831 679 -162 93 -427 212 -604 270 -45 15
-88 34 -96 41 -7 8 -16 37 -20 65 -4 28 -15 65 -25 83 -10 18 -21 51 -24 74
-4 24 -16 58 -26 76 -10 18 -22 49 -27 68 -4 19 -9 36 -10 39 -1 3 -5 15 -9
28 -4 12 -11 22 -15 22 -4 0 -15 26 -25 58 -10 31 -21 59 -25 62 -7 6 -40 75
-40 85 0 4 -9 19 -19 34 -11 15 -23 39 -26 54 -4 14 -13 31 -21 38 -7 6 -25
35 -38 63 -14 29 -32 59 -41 66 -8 7 -15 18 -15 25 0 8 -11 27 -25 43 -14 17
-25 34 -25 39 0 5 -11 20 -25 33 -14 13 -25 27 -25 32 0 23 -181 231 -334 384
-228 228 -399 363 -656 516 -399 238 -799 375 -1300 444 -95 14 -566 28 -615
19z m540 -660 c503 -65 929 -246 1325 -566 115 -92 352 -334 436 -444 64 -85
163 -233 200 -300 46 -83 114 -222 114 -233 0 -7 5 -12 10 -12 6 0 10 -8 10
-19 0 -10 7 -32 15 -47 l15 -29 -208 -6 c-437 -14 -757 -73 -1137 -211 -613
-221 -1208 -674 -1542 -1173 -187 -280 -314 -535 -426 -855 -19 -54 -81 -275
-96 -345 -43 -197 -71 -432 -78 -642 l-4 -153 -52 26 c-259 131 -568 355 -777
564 -411 410 -661 916 -736 1490 -21 164 -24 475 -5 640 70 631 363 1192 859
1646 407 373 867 590 1407 664 171 24 505 26 670 5z m2115 -2315 c74 -6 155
-14 180 -20 25 -5 95 -19 155 -31 659 -129 1224 -506 1643 -1096 12 -18 22
-36 22 -41 0 -5 7 -16 16 -23 9 -7 20 -22 25 -34 5 -11 23 -42 39 -70 29 -47
118 -225 154 -307 9 -20 16 -45 16 -57 0 -12 5 -21 10 -21 6 0 10 -8 10 -19 0
-10 7 -32 15 -47 8 -16 15 -37 15 -46 0 -9 5 -20 10 -23 6 -3 10 -19 10 -35 0
-16 5 -32 10 -35 6 -3 10 -22 10 -41 0 -19 4 -34 9 -34 5 0 12 -26 16 -57 3
-32 10 -66 15 -75 6 -10 10 -35 10 -56 0 -21 4 -42 9 -47 24 -27 44 -608 24
-690 -3 -11 -9 -54 -13 -95 -46 -421 -229 -855 -518 -1230 -103 -134 -389
-413 -532 -519 -453 -337 -977 -511 -1541 -511 -440 0 -843 96 -1188 282 -110
59 -340 212 -461 306 -164 127 -350 321 -517 537 -88 115 -247 401 -288 520
-9 28 -21 59 -26 70 -4 11 -22 65 -39 120 -158 502 -168 1015 -30 1510 143
512 451 972 894 1337 389 320 862 520 1341 567 226 23 311 25 495 11z"
      />
    </svg>
  );
}

export default SvgComponent;
