import Cookies from "js-cookie";

import { TokenType } from "./types";

export const getCookie = (type: TokenType) => Cookies.get(getCookieName(type));

export const setCookie = (
  type: TokenType,
  token: string,
  expires: number | Date,
) =>
  Cookies.set(getCookieName(type), token, {
    expires,
  });

const getCookieName = (type: TokenType) =>
  type === "auth" ? "kic_token" : "kic_refresh";
