import axios from "axios";
import { thunk } from "easy-peasy";

import { logAxiosError } from "../utils";

const fetchPost = ({ key, endpoint, requireAuth = true }) =>
  thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    await getStoreActions().auth.checkTokenExpiration();

    const { token } = getStoreState().auth.authData;

    try {
      const headers = { Authorization: `Bearer ${token}` };

      if (!requireAuth) {
        delete headers.Authorization;
      }

      const response = await axios.get(`${endpoint}/${payload}`, {
        headers,
      });

      return response;
    } catch (error) {
      logAxiosError(error, key);
      return error.response;
    }
  });

export default fetchPost;
