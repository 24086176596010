import { css } from "styled-components";

const sizes = {
  mobileS: 320,
  mobileM: 375,
  mobileL: 425,
  tablet: 768,
  laptop: 1024,
  laptopL: 1440,
  desktop: 2560,
  customGallery: 650,
  discountGrid: 982,
};

// Iterate through the sizes and create a media template
const media = Object.entries(sizes).reduce(
  (obj, [label, size]) => ({
    ...obj,
    [label]: (first, ...interpolations) => css`
      @media (min-width: ${size}px) {
        ${css(first, ...interpolations)}
      }
    `,
  }),
  {},
) as {
  [K in keyof typeof sizes]: (...args) => ReturnType<typeof css>;
};

export { media };
