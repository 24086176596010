import { SendRequest } from "../makeSendRequest";
import { Program, ProgramResponse, ProgramSelection } from "./types";

export type UpdateSortOrdersPayload = {
  sortOrders: { [programId: string]: number };
};

export const createProgramsApi = (sendRequest: SendRequest) => ({
  list: async () =>
    sendRequest<Program[]>({
      method: "get",
      path: "program/list?includeChallengeOnlyPrograms=true",
      version: "v3",
    }),
  updateSortOrders: (updatePayload: UpdateSortOrdersPayload) =>
    sendRequest<Program[]>({
      method: "put",
      path: "program/sortOrders",
      version: "v3",
      data: updatePayload,
    }),
  view: async (id: string) =>
    sendRequest<ProgramResponse>({
      method: "get",
      path: `program/${id}`,
      version: "v3",
    }),
  deleteProgram: async (id: string) =>
    sendRequest<{ result: boolean }>({
      method: "delete",
      path: `program/${id}`,
      version: "v3",
    }),
  deleteProgramSelection: async (id: string) =>
    sendRequest<{ result: boolean }>({
      method: "delete",
      path: `program-selection/${id}`,
      version: "v3",
    }),

  createOrUpdateProgram: async (program: Partial<Program>) =>
    sendRequest<Program>({
      method: "put",
      path: "program",
      version: "v3",
      data: program,
    }),
  createOrUpdateProgramSelection: async (
    programSelection: Partial<ProgramSelection>,
  ) =>
    sendRequest<ProgramSelection>({
      method: "put",
      path: "program-selection",
      version: "v3",
      data: programSelection,
    }),
});
