export type SocialConfig = {
  instagram_url: string;
  facebook_url: string;
  youtube_url: string;
};

export const getSocialConfig = () => ({
  instagram_url: "https://www.instagram.com/kic.app/",
  facebook_url: "https://www.facebook.com/kicapps",
  youtube_url: "https://www.youtube.com/channel/UCfacjXguCGmsQkNZG1GA-Og",
});
