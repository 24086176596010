import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 101 97.5"
      xmlSpace="preserve"
      width="1rem"
      height="1rem"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path d="M17.1 30.9c7.4 0 13.5-6 13.5-13.5s-6-13.5-13.5-13.5S3.6 10 3.6 17.4s6 13.5 13.5 13.5zm0-22c4.7 0 8.5 3.8 8.5 8.5s-3.8 8.5-8.5 8.5-8.5-3.8-8.5-8.5 3.8-8.5 8.5-8.5zM28 31.4c.5 1.3 2 1.9 3.3 1.4l34.6-14.3c.4-.2.9-.2 1.3 0s.7.5.9.9c.2.4.2.9 0 1.3s-.5.7-.9.9c-.1 0-.2.1-.3.2L34.2 41.5c-.2.1-.3.3-.5.4h-.1c-1.2.7-1.6 2.2-.9 3.4L43.4 64c.4.7 1 1.1 1.8 1.2.8.1 1.5-.1 2.1-.7l39.6-37.3c3.8-3.9 4.1-3.7 5.3-2.9.4.3.4 1.9-2.3 6.2C86.5 36 71.3 60 65.2 67.7l-.8 1c-5.6 7.1-9.3 11.8-18.1 13.8-5.3 1.2-10 .7-14.1-1.7-3.5-2-5-5.5-7.6-11.8l-.3-.7c-2.2-5.2-3.5-9-5.5-15.4v-.1c-1.2-3.9-3.8-12-1.3-15.2.8-1.1.6-2.7-.4-3.5-1.1-.8-2.7-.6-3.5.4-4.1 5.3-1.2 14.3.5 19.7v.1c2.1 6.6 3.4 10.4 5.7 15.8l.3.7c2.9 6.9 4.8 11.4 9.8 14.2 3.5 2 7.3 2.9 11.3 2.9 2.1 0 4.2-.3 6.4-.8 10.6-2.4 15.1-8.3 20.9-15.6l.8-1c6.2-7.9 21.6-32.1 25-37.6 1.6-2.6 6-9.6.9-13-4.9-3.3-8.3.2-11.6 3.5l-37.2 35-8-13.9 31.1-18.8c3.3-1.5 4.8-5.3 3.3-8.7-.7-1.6-2-2.9-3.6-3.6-1.7-.7-3.5-.6-5.1 0L29.4 28.2c-1.3.5-1.9 1.9-1.4 3.2zM93.3 88.6H8c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5h85.2c1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.4-2.5z" />
    </svg>
  );
}

export default SvgComponent;
