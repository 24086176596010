import React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 287 100" {...props}>
      <title>{props.alt ?? ""}</title>
      <style type="text/css">
        {`.st0{fill:none;stroke:${props.fill};stroke-width:9;stroke-miterlimit:10;}`}
      </style>
      <path
        className="st0"
        d="M248,6H90.6H42v0.1c17.1,1.6,30.6,20.5,30.6,43.5c0,23-13.5,41.9-30.6,43.5v0.1h206c18.2,0,33-19.5,33-43.6
		C281,25.5,266.2,6,248,6z"
      />
      <ellipse className="st0" cx="39.4" cy="49.7" rx="33" ry="43.8" />
    </svg>
  );
}

export default SvgComponent;
