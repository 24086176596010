import jwt from "jsonwebtoken";

export const isTokenExpired = (token: string, advanceSeconds = 0) =>
  !token || decodeToken(token).expiry <= Date.now() + advanceSeconds * 1000;

export const decodeToken = (token: string) => {
  const { iss, exp, iat } = jwt.decode(token, { json: true });
  return {
    issuer: iss,
    expiry: exp * 1000,
    issuedAt: iat * 1000,
  };
};
