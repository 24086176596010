import { isBoolean, isString } from "lodash";
import { UnleashClient } from "unleash-proxy-client";
import { IConfig, IToggle, IVariant } from "@unleash/proxy-client-react";

import { notifyError } from "../../utils/monitoring";
import { getAppConfig } from "../../utils/appConfig/appConfig";
import {
  InitialOfflineDefaultToggles,
  ToggleNames,
  VariantNames,
  Variants,
} from "./Toggles";

const bootstrap: IToggle[] = Object.entries(InitialOfflineDefaultToggles).map(
  ([key, value]) => {
    if (isBoolean(value))
      return {
        name: key,
        enabled: value,
        impressionData: false,
        variant: {
          name: "disabled",
          enabled: false,
          payload: undefined,
        },
      };
    return {
      name: key,
      enabled: true,
      impressionData: false,
      variant: {
        name: "default",
        enabled: true,
        payload: isString(value.payload)
          ? { type: "string", value: value.payload }
          : { type: "json", value: JSON.stringify(value.payload) },
      },
    };
  },
);

const unleashConfig: IConfig = {
  ...getAppConfig().unleashConfig,
  bootstrap,
};

export const unleashClient = new UnleashClient(unleashConfig);

export const getToggle = (toggleName: ToggleNames) =>
  unleashClient.isEnabled(toggleName);

export const getVariant = <T extends VariantNames>(toggleName: T) => {
  const variant = unleashClient.getVariant(toggleName);
  return parseVariant(variant);
};

export const parseToggle = (
  toggle: IToggle,
): boolean | { payload: unknown } => {
  if (toggle.enabled === false) return false;
  if (toggle.variant.enabled === false) return true;
  return parseVariant(toggle.variant);
};

export const parseVariant = <T extends VariantNames>(
  variant: Partial<IVariant>,
): false | Variants[T] => {
  if (!variant || variant.enabled === false) return false;

  if (variant.payload.type === "json")
    return {
      payload: JSON.parse(variant.payload.value),
    };
  if (variant.payload.type === "string" || variant.payload.type === "csv")
    return {
      payload: variant.payload.value,
    } as Variants[T];

  notifyError(
    `Unhandled type: ${variant.payload.type} for variant: ${variant.name}`,
  );
  return false;
};
