import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      data-name="Layer 1"
      viewBox="0 0 18.4 18.4"
      width="1rem"
      height="1rem"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <rect
        x={0.86}
        y={1.28}
        width={16.79}
        height={15.96}
        rx={1.34}
        fill="none"
        stroke={props.fill}
        strokeMiterlimit={10}
      />
    </svg>
  );
}

export default SvgComponent;
