import React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 -37 456.558 456" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M81.3 135.672h1.345c.992-2.313 1.992-4.512 3.035-6.55a107.526 107.526 0 0113.597-20.802L84.88 93.918H48.168V77.934h20.687L42.512 51.59l11.312-11.313 26.344 26.348V45.934h16v36.691l14.207 14.207a105.948 105.948 0 0127.633-17.816c-12.399-29.809-41.559-49.192-73.84-49.082h-32v32c-.129 32.28 19.293 61.43 49.133 73.738zm0 0M99.965 136.336c-.192.383-.375.8-.574 1.215 20.254 4.746 40.289 18.402 59.129 40.746a237.421 237.421 0 0146.878 91.2 102.41 102.41 0 011.97 36.358l148.046-30.214a59.071 59.071 0 0143.738 8.101l7.696-17.16a197.5 197.5 0 0017.32-80.406 111.6 111.6 0 00-11.793-49.84 90.674 90.674 0 00-33.543-36.8c-32.773-20.208-74.703-17.673-104.8 6.335l-9.864 7.902v-11.27a127.724 127.724 0 0137.656-90.913L290.512.277a143.615 143.615 0 00-42.344 102.227v11.27l-9.883-7.919a91.428 91.428 0 00-56.84-19.921c-34.554-.141-66.175 19.418-81.48 50.402zm0 0" />
      <path d="M251.566 61.367c-3.902-28.531-26.336-50.965-54.863-54.863 3.902 28.527 26.336 50.96 54.863 54.863zm0 0M91.367 238.688c-30.082 7.066-48.754 37.16-41.73 67.246A54.821 54.821 0 0066.68 334.55l88.336-18.024a55.927 55.927 0 00-7.496-58.812 55.923 55.923 0 00-56.153-19.028zm0 0" />
      <path d="M30.941 341.863l17.227-3.511c-18.61-23.047-21.2-55.137-6.535-80.875 14.664-25.735 43.594-39.864 72.906-35.606 29.313 4.258 53.027 26.035 59.762 54.879a72.62 72.62 0 01-.918 36.05l17.137-3.495a86.544 86.544 0 00-.672-36.176 219.825 219.825 0 00-43.555-84.555c-17.102-20.238-44-42.847-74.941-35.566-30.946 7.281-45.051 39.422-51.403 65.152a219.466 219.466 0 00-1.512 95.07 86.948 86.948 0 0012.504 28.633zm0 0M456.559 325.766l-3.297-15.664-18.399 3.882 10.657-15.527-13.2-9.043-21.953 32a7.64 7.64 0 00-.945 2.05c-4.672-19.425-22.078-33.093-42.055-33.034-2.937 0-5.867.297-8.746.883l-.797.167a79.353 79.353 0 01-1.992 28.95l-.8 3.058-15.473-4.058.8-3.047a63.433 63.433 0 001.758-21.695l-46.008 9.382a78.465 78.465 0 01-3.84 35.914l-15.07-5.379a62.62 62.62 0 003.254-27.335l-39.047 8a78.95 78.95 0 01-4.254 31.625l-15.074-5.375a62.684 62.684 0 003.55-23.032l-39.124 8a78.71 78.71 0 01-4.473 27.313l-15.082-5.465c2.11-6 3.29-12.285 3.496-18.64l-39.11 8a77.33 77.33 0 01-4.44 22.937l-15.063-5.395c1.625-4.59 2.7-9.36 3.2-14.207L7.862 362.895a9.604 9.604 0 00-7.816 10.398 9.596 9.596 0 009.719 8.64h353.066a43.199 43.199 0 0030.766-12.742l4.488-4.496a43.21 43.21 0 0012.746-30.761c0-1.184-.242-2.309-.336-3.47.184.329.39.645.621.942l29.953 32 11.68-10.941-20.246-21.602zm0 0" />
    </svg>
  );
}

export default SvgComponent;
