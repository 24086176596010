import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 512 512" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M471.15 57.65C439.49 21.99 395.79 1.54 348.19.07c-47.23-1.33-92.98 16.67-126.21 49.72-26.6 26.42-61.95 69.67-61.95 140.02v60.42l-43.73 43.73c-1.78 1.73-5.31 1.16-7.75.17a79.071 79.071 0 00-85.33 17.45C8.25 326.57 0 346.48 0 367.65s8.25 41.08 23.22 56.05c11.88 11.88 27.13 19.27 43.57 21.59 2.31 16.44 9.72 31.69 21.57 43.55 15.47 15.44 35.76 23.16 56.07 23.16 20.29 0 40.57-7.72 56.04-23.16 22.38-22.38 29.25-55.88 17.47-85.33-1-2.5-1.56-6.03.16-7.75L261.89 352h61.14c17.16 0 33.76-2.27 50.76-6.94 15.18-4.14 22.24-21.54 14.5-35.05-19.38-33.91-14.6-75.91 11.63-102.16 20.97-20.97 51.48-28.58 81.7-20.23 15.51 4.21 30.51-7.54 30.38-23.38-.38-40.09-14.5-76.95-40.85-106.59zM184.16 361.82c-15.25 15.23-19.38 38.03-10.78 59.5 4.63 11.61 1.94 24.77-6.85 33.55-12.16 12.16-32.01 12.23-44.23.02-10.28-10.3-8.59-23.72-7.37-29.02l8.5-37.33-37.29 8.59c-5.31 1.22-18.69 2.92-28.97-7.39-12.21-12.18-12.22-32 0-44.19a31.066 31.066 0 0133.6-6.84c21.41 8.53 44.29 4.44 59.48-10.81l20.46-20.46a84.98 84.98 0 0033.97 33.87l-20.52 20.51zm181.81-187.9c-33.85 33.86-45.38 83.83-32.16 129.67-3.59.27-7.16.41-10.78.41H245.2c-20.5 0-37.16-16.5-37.16-36.8v-77.39c0-41.02 14.29-72.71 47.79-105.99 57.7-57.39 139.9-38.81 179.44 5.7 12.16 13.69 20.6 29.59 25.07 46.91-34.98-1.06-69.49 12.61-94.37 37.49z" />
    </svg>
  );
}

export default SvgComponent;
