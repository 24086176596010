import React from "react";
import Lottie from "react-lottie";

import * as animationData from "../../../../public/static/lottie/spinner-teal.json";

import { Box, BoxProps, Text } from "../../../ui";
import { SignupFormInput } from "./SignupFormInput";

type PaymentSummaryProps = BoxProps & {
  promoCode: string;
  setPromoCode: (code: string) => void;
  onSubmit: (code: string) => void;
  isLoading?: boolean;
  error?: string;
};

export const ApplyCouponInput: React.FC<PaymentSummaryProps> = ({
  promoCode,
  setPromoCode,
  onSubmit,
  isLoading,
  error,
  ...props
}) => (
  <Box position="relative" onSubmit={() => onSubmit(promoCode)} {...props}>
    <SignupFormInput
      data-cy="coupon-input"
      placeholder="Add gift card or promo code..."
      value={promoCode}
      onChange={(e) => setPromoCode(e.target.value)}
      errorMessage={error}
      bg="backgroundPrimary"
    >
      {isLoading ? (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={40}
          width={40}
          style={{ position: "absolute", right: 0, top: 0 }}
        />
      ) : (
        <Text
          data-cy="coupon-apply-btn"
          variant="buttonMedium"
          color="backgroundBrand"
          css={{
            cursor: "pointer",
          }}
          onClick={() => onSubmit(promoCode)}
        >
          APPLY
        </Text>
      )}
    </SignupFormInput>
  </Box>
);
