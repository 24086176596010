import axios from "axios";
/* eslint-disable operator-assignment */
/* eslint-disable no-param-reassign */
import { action, thunk } from "easy-peasy";

import { KIC_API } from "../../../src/api/index";
import initialState from "./admin.globals.state";

const actions = {
  appendToList: action((state, payload) => {
    const { key, data } = payload;
    state[key].unshift(data);
  }),
  clearState: action((state) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = value;
    }
  }),
  createGlobal: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          `${KIC_API}/api/v1/globals`,
          payload,
          {
            headers,
          },
        );

        actions.appendToList({ key: "globals", data: response.data });

        return response.data;
      } catch (error) {
        return error.response.data;
      }
    },
  ),
  fetchGlobals: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { pageNumber, search, limit } = payload;

        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(
          `${KIC_API}/api/v1/globals?page=${pageNumber}${
            limit ? `&limit=${limit}` : ""
          }${search ? `&search=${search}` : ""}`,
          {
            headers,
          },
        );
        const { data } = response;

        actions.setState({ key: "globals", data: data.data });
        actions.setState({ key: "pageSettings", data: data.settings });

        return response.data;
      } catch (error) {
        return error.response.data;
      }
    },
  ),
  fetchGlobalType: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { authenticated, name } = payload;
        const authQuery = authenticated ? "authenticated" : "unauthenticated";
        const nameQuery = name ? `/${name}` : "";

        const headers = authenticated
          ? { Authorization: `Bearer ${token}` }
          : {};

        const response = await axios.get(
          `${KIC_API}/api/v1/globals/${authQuery}${nameQuery}`,
          {
            headers,
          },
        );

        return response.data;
      } catch (error) {
        return error?.response?.data ?? { error };
      }
    },
  ),
  removeFromList: action((state, payload) => {
    const { key, id } = payload;
    state[key] = state[key].filter((item) => item._id !== id);
  }),
  removeGlobal: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.delete(
          `${KIC_API}/api/v1/globals/${payload}`,
          { headers },
        );

        actions.removeFromList({ key: "globals", id: payload });

        return response.data;
      } catch (error) {
        return error.response.data;
      }
    },
  ),
  setState: action((state, payload) => {
    const { key, data } = payload;
    state[key] = data;
  }),
  updateGlobal: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { id, ...rest } = payload;
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(
          `${KIC_API}/api/v1/globals/${id}`,
          rest,
          { headers },
        );

        actions.updateList({ key: "globals", data: response.data });

        return response.data;
      } catch (error) {
        return error.response.data;
      }
    },
  ),
  updateList: action((state, payload) => {
    const { key, data } = payload;
    const index = state.globals.findIndex((global) => global._id === data._id);
    state[key][index] = data;
  }),
};

export default actions;
