import styled from "styled-components";

import Icon from "../../../../../icons";
import { ReactModalAdapter } from "../../../../../styles/authCommonStyles";
import { media } from "../../../../../styles/mediaQueries";

export const Container = styled.div`
  max-height: fill-available;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.primaryFontColour};
  font-size: 2rem;
  font-weight: 600;
  margin: 0 0 4rem 0;
  text-align: center;

  ${media.tablet`
    font-size: 2.5rem;
  `};
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const ErrorMsg = styled.p<{ noMargin?: boolean }>`
  color: ${(props) => props.theme.errorRed};
  font-size: 1.2rem;
  margin: 1rem 0;

  ${(props) =>
    props.noMargin &&
    `
    margin: 0;
  `}
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  fill: ${(props) => props.theme.authIconColour};
  height: 1.3rem;
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  width: 1.3rem;
`;

export const StyledModal = styled(ReactModalAdapter)`
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: center;
    padding: 2rem;
    transition: ease 0.5s;
    z-index: 10;

    &.ReactModal__Overlay--after-open {
      background-color: #0000008c;
      backdrop-filter: blur(6px);
    }
    &.ReactModal__Overlay--before-close {
      background-color: #00000000;
      backdrop-filter: blur(0px);
    }
  }

  &__content {
    align-items: center;
    background: #fbfbfb;
    border-radius: ${(props) => props.theme.borderRadius};
    box-shadow: 1px 1px 7px 1px #00000052;
    display: flex;
    flex: 1;
    flex-direction: column;
    ${(props) => props.allowChangeSubscription && "max-width: 42rem"};
    overflow: auto;
    outline: none;
    padding: 2rem;
    position: relative;
    width: 90%;
    transition: ease 0.5s;

    &.ReactModal__Content--after-open {
      opacity: 1;
    }
    &.ReactModal__Content--before-close {
      opacity: 0;
    }
  }
`;
