type Args = {
  enabled?: boolean;
  start_time?: number;
  end_time?: number;
};

export const shouldApplyAutoCoupon = ({
  enabled,
  start_time: startTime,
  end_time: endTime,
}: Args = {}) => {
  const now = Date.now();

  return enabled && now > startTime * 1000 && now < endTime * 1000;
};
