import { SendRequest } from "../makeSendRequest";
import { Masterclass } from "../types";

type MasterclassTypeSpecificFilters = {
  "type_specific.suggestedFollowup"?: string[];
  "type_specific.categories"?: string[];
};
export type MasterclassFilters = {
  search?: string;
  limit?: number;
  status?: "published" | "pending_review";
  type_specific?: MasterclassTypeSpecificFilters;
};

export const createMasterclassApi = (sendRequest: SendRequest) => ({
  list: async ({ type_specific = {}, ...filters }: MasterclassFilters = {}) => {
    const typeSpecificFilters = Object.entries(type_specific);
    const formattedTypeSpecificFilters = typeSpecificFilters.map(
      ([key, value]) => {
        return [key, `[${value.map((item) => `"${item}"`)}]`];
      },
    );
    const masterclassFilters = new Map(Object.entries(filters));

    const response = await sendRequest<{ data: Masterclass[] }>({
      method: "get",
      path: `post/masterclasses`,
      version: "v2",
      params: Object.fromEntries([
        ...masterclassFilters,
        ...formattedTypeSpecificFilters,
      ]),
    });

    return response;
  },
  listMeditations: async (filters: MasterclassFilters = {}) => {
    const masterclassFilters = new Map(Object.entries(filters));
    const searchParams = new URLSearchParams();

    masterclassFilters.forEach((filter, key) =>
      searchParams.set(key, filter.toString()),
    );

    const response = await sendRequest<{ data: Masterclass[] }>({
      method: "get",
      path: `post/masterclasses?type_specific.categories=["meditation"]&${searchParams.toString()}`,
      version: "v2",
    });

    return response;
  },
  findOne: async (id: string) => {
    const response = await sendRequest<Masterclass>({
      method: "get",
      path: `post/masterclasses/${id}`,
      version: "v2",
    });

    return response;
  },
});
