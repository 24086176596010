import React from "react";
import styled from "styled-components";
import { compose, space, layout, SpaceProps, LayoutProps } from "styled-system";

import { Theme } from "../theme";

type Props = SpaceProps<Theme> & LayoutProps<Theme>;
type ImgProps = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;

const _Image = styled.img(compose(space, layout)) as any;

export const Image: React.FC<Props & ImgProps> = (props) => {
  return <_Image {...props} />;
};
