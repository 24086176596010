import axios from "axios";
/* eslint-disable operator-assignment */
/* eslint-disable no-param-reassign */
import { action, thunk } from "easy-peasy";

import { KIC_API } from "../../src/api/index";
import { logAxiosError } from "../../src/utils/utils";
import initialState from "./tags.state";

const actions = {
  appendToList: action((state, payload) => {
    const { key, data } = payload;
    state[key].unshift(data);
  }),
  clearState: action((state) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = value;
    }
  }),
  fetchGoals: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { pageNumber, limit, search } = payload;

        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(
          `${KIC_API}/api/v1/goals/?page=${pageNumber}${
            limit ? `&limit=${limit}` : ""
          }${search ? `&search=${search}` : ""}`,
          { headers },
        );

        actions.setState({ key: "goals", data: response.data.data });
        actions.setState({
          key: "goalsPageSettings",
          data: response.data.settings,
        });
      } catch (error) {
        logAxiosError(error, "fetchGoals");
      }
    },
  ),
  fetchIngredientCategories: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { pageNumber, limit, search } = payload;

        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(
          `${KIC_API}/api/v1/ingredientcategories?page=${pageNumber}${
            limit ? `&limit=${limit}` : ""
          }${search ? `&search=${search}` : ""}`,
          { headers },
        );

        actions.setState({
          key: "ingredientCategories",
          data: response.data.data,
        });
        actions.setState({
          key: "ingredientCategoriesPageSettings",
          data: response.data.settings,
        });

        return response.data;
      } catch (error) {
        logAxiosError(error, "fetchIngredientCategories");
        return error.response.data;
      }
    },
  ),
  fetchMealTypes: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { pageNumber, limit, search } = payload;

        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(
          `${KIC_API}/api/v1/mealtypes?page=${pageNumber}${
            limit ? `&limit=${limit}` : ""
          }${search ? `&search=${search}` : ""}`,
          { headers },
        );

        actions.setState({ key: "mealTypes", data: response.data.data });
        actions.setState({
          key: "mealTypesPageSettings",
          data: response.data.settings,
        });
      } catch (error) {
        logAxiosError(error, "fetchMealTypes");
      }
    },
  ),
  fetchRoles: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { pageNumber, limit, search } = payload;

        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(
          `${KIC_API}/api/v1/roles/?page=${pageNumber}${
            limit ? `&limit=${limit}` : ""
          }${search ? `&search=${search}` : ""}`,
          { headers },
        );

        actions.setState({ key: "roles", data: response.data.data });
        actions.setState({
          key: "rolesPageSettings",
          data: response.data.settings,
        });
      } catch (error) {
        logAxiosError(error, "fetchRoles");
      }
    },
  ),
  fetchTitles: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { pageNumber, limit, search } = payload;

        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(
          `${KIC_API}/api/v1/titles/?page=${pageNumber}${
            limit ? `&limit=${limit}` : ""
          }${search ? `&search=${search}` : ""}`,
          { headers },
        );

        actions.setState({ key: "titles", data: response.data.data });
        actions.setState({
          key: "titlesPageSettings",
          data: response.data.settings,
        });
      } catch (error) {
        logAxiosError(error, "fetchTitles");
      }
    },
  ),
  removeFromList: action((state, payload) => {
    const { key, id } = payload;
    state[key] = state[key].filter((recipe) => recipe._id !== id);
  }),
  setState: action((state, payload) => {
    const { key, data } = payload;
    state[key] = data;
  }),
  updateList: action((state, payload) => {
    const { key, data } = payload;
    state[key] = state[key].map((item) =>
      item._id === data._id ? data : item,
    );
  }),
};

export default actions;
