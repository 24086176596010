import React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 96 99"
      xmlSpace="preserve"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path d="M26 19.7c.3 0 .5-.1.8-.2 3.4-2.1 6.1-3.7 8.5-5.1.7-.4 1-1.3.6-2s-1.3-1-2-.6c-2.5 1.4-5.2 3-8.6 5.1-.7.4-.9 1.4-.5 2.1.2.5.7.7 1.2.7zM16.8 69.3c.2-.8-.3-1.6-1.1-1.8-.8-.2-1.6.3-1.8 1.1-.4 1.5-.7 3.1-.8 4.6-.1.8-.1 1.5-.1 2.2 0 2.9.7 5.3 2.1 7.1 1.3 1.7 3.3 2.9 5.7 3.5h.4c.7 0 1.3-.4 1.4-1.1.2-.8-.3-1.6-1.1-1.8-1.8-.5-3.2-1.3-4.1-2.5-1-1.3-1.5-3.1-1.5-5.3 0-.6 0-1.3.1-1.9.2-1.4.4-2.8.8-4.1zM52.3 6.4c6.2-2.2 12.3-3.5 18.1-3.7.8 0 1.5-.7 1.4-1.6 0-.8-.7-1.4-1.6-1.4-6.1.2-12.5 1.6-19 3.9-.8.3-1.2 1.1-.9 1.9.2.6.8 1 1.4 1 .3 0 .4 0 .6-.1zM24.3 54.1c.3.3.7.5 1.1.5.4 0 .7-.1 1-.4 4.1-3.7 9.4-6.9 15.7-9.7.8-.3 1.1-1.2.8-2-.3-.8-1.2-1.1-2-.8-6.6 2.9-12.2 6.4-16.5 10.2-.6.7-.7 1.6-.1 2.2zM89.5 3.6c-.7-.4-1.6-.2-2.1.5-.4.7-.2 1.6.5 2.1 1.3.8 2.3 1.7 3.2 2.8 1.6 2 2.3 4.1 2.3 6.2 0 1.8-.5 3.6-1.6 5.4-.4.7-.2 1.6.5 2.1.2.1.5.2.8.2.5 0 1-.3 1.3-.7 1.4-2.3 2-4.6 2-6.9 0-2.8-1-5.5-3-8-1-1.5-2.3-2.7-3.9-3.7zM77.2 30.2c-4.8 1.7-9.8 3.1-14.7 4.4-1.1.3-2.3.6-3.4.9-.8.2-1.3 1.1-1 1.8.2.7.8 1.1 1.4 1.1.1 0 .3 0 .4-.1 1.1-.3 2.3-.6 3.4-.9 4.9-1.4 10-2.8 14.9-4.5.8-.3 1.2-1.1.9-1.9-.3-.6-1.1-1-1.9-.8zM50 85.7c.8.1 1.5-.6 1.6-1.4 0-.8-.6-1.5-1.4-1.6-3.6-.2-7.2 0-10.2.2-.8.1-1.5.8-1.4 1.6.1.8.7 1.4 1.5 1.4h.1c2.9-.2 6.4-.4 9.8-.2zM11.5 2.8C11.1 1.6 10.1.7 8.6.7c-1.6 0-2.5.9-2.9 2.1L.3 19.4c-.1.3-.3.9-.3 1.5 0 1.1.9 2 2 2s1.8-.8 2.1-2l1.1-3.5H12l1.1 3.5c.4 1.2 1.1 2 2.1 2 1.1 0 2-.9 2-2 0-.6-.2-1.2-.3-1.5L11.5 2.8zM6.3 13.6l2.2-7.8h.2l2.2 7.8H6.3zM76.2 83.7c0-3.4-2.8-6.1-6.6-6.1H64c-1.2 0-2 .8-2 2V97c0 1.2.8 2 2 2h6.1c4 0 6.9-2.9 6.9-6.4 0-4-3.4-4.9-3.4-4.9v-.2c.1 0 2.6-.9 2.6-3.8zM66 81.3h3.5c1.5 0 2.5.9 2.5 2.5 0 1.7-.9 2.6-2.6 2.6H66v-5.1zm4.2 14.1H66v-5.7h4c1.8 0 2.9 1.1 2.9 2.9 0 1.7-1 2.8-2.7 2.8z" />
    </svg>
  );
}

export default SvgComponent;
