import { DailyQuoteUpsertType } from "../../features/DailyQuote/hooks/useUpdateDailyQuote";
import { DailyQuoteUpsertManyType } from "../../features/DailyQuote/hooks/useUpsertManyDailyQuotes";
import { SendRequest } from "../makeSendRequest";
import { RoomTheme } from "../recommendation/types";

export type DailyQuoteResponse = {
  _id: string;
  author: string;
  date: string;
  inserted: number;
  quote: string;
  roomTheme: RoomTheme;
  updated: number;
};

export const createDailyQuoteApi = (sendRequest: SendRequest) => ({
  list: async (next?: string) => {
    const response = await sendRequest<{
      data: DailyQuoteResponse[];
      hasNext?: boolean;
      hasPrevious?: boolean;
      next: string | null;
      previous: string | null;
    }>({
      method: "get",
      path: `daily-quote/list${!!next ? `?next=${next}` : ""}`,
      version: "v3",
    });

    return response;
  },
  filterDateRange: async (startDate: string, endDate: string) => {
    if (!startDate || !endDate) return;
    const response = await sendRequest<DailyQuoteResponse[]>({
      method: "get",
      path: `daily-quote/by-date-range?startDate=${startDate}&endDate=${endDate}`,
      version: "v3",
    });

    return response;
  },
  findById: async (id: string) => {
    const response = await sendRequest<{ data: DailyQuoteResponse }>({
      method: "get",
      path: `daily-quote/${id}`,
      version: "v3",
    });
    return response;
  },
  upsert: async (data: DailyQuoteUpsertType) => {
    const response = await sendRequest({
      method: "put",
      path: `daily-quote/`,
      version: "v3",
      data,
    });
    return response;
  },
  upsertMany: async (data: DailyQuoteUpsertManyType) => {
    const response = await sendRequest({
      method: "post",
      path: `daily-quote/`,
      version: "v3",
      data: {
        quotes: data,
      },
    });
    return response;
  },
  delete: async (id: string) => {
    const response = await sendRequest({
      method: "delete",
      path: `daily-quote/${id}`,
      version: "v3",
    });
    return response;
  },
});
