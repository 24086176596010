import axios from "axios";
/* eslint-disable operator-assignment */
/* eslint-disable no-param-reassign */
import { thunk } from "easy-peasy";

import { KIC_API } from "../../../src/api/index";
import { logAxiosError } from "../../../src/utils/utils";

const actions = {
  createGoal: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(`${KIC_API}/api/v1/goals`, payload, {
          headers,
        });

        getStoreActions().tags.appendToList({
          data: response.data,
          key: "createGoal",
        });

        return response.data;
      } catch (error) {
        logAxiosError(error, "createMealGoal");
        return error.response.data;
      }
    },
  ),
  createIngredientCategory: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          `${KIC_API}/api/v1/ingredientcategories`,
          payload,
          { headers },
        );

        getStoreActions().tags.appendToList({
          data: response.data,
          key: "ingredientCategories",
        });

        return response.data;
      } catch (error) {
        logAxiosError(error, "createCategory");
        return error.response.data;
      }
    },
  ),
  createMealType: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          `${KIC_API}/api/v1/mealtypes`,
          payload,
          { headers },
        );

        getStoreActions().tags.appendToList({
          data: response.data,
          key: "mealTypes",
        });

        return response.data;
      } catch (error) {
        logAxiosError(error, "createMealType");
        return error.response.data;
      }
    },
  ),
  createRole: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(`${KIC_API}/api/v1/roles`, payload, {
          headers,
        });

        getStoreActions().tags.appendToList({
          data: response.data,
          key: "roles",
        });

        return response.data;
      } catch (error) {
        logAxiosError(error, "createRole");
        return error.response.data;
      }
    },
  ),
  removeGoal: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      const { id } = payload;

      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        await axios.delete(`${KIC_API}/api/v1/goals/${id}`, { headers });

        getStoreActions().tags.removeFromList({ key: "goals", id });
      } catch (error) {
        logAxiosError(error, "removeMealGoal");
      }
    },
  ),
  removeIngredientCategory: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      const { id } = payload;

      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        await axios.delete(`${KIC_API}/api/v1/ingredientcategories/${id}`, {
          headers,
        });

        getStoreActions().tags.removeFromList({
          key: "ingredientCategories",
          id,
        });
      } catch (error) {
        logAxiosError(error, "removeIngredientCategory");
      }
    },
  ),
  removeMealType: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      const { id } = payload;

      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        await axios.delete(`${KIC_API}/api/v1/mealtypes/${id}`, { headers });

        getStoreActions().tags.removeFromList({ key: "mealTypes", id });
      } catch (error) {
        logAxiosError(error, "removeMealType");
      }
    },
  ),
  removeRole: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      const { id } = payload;

      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        await axios.delete(`${KIC_API}/api/v1/roles/${id}`, { headers });

        getStoreActions().tags.removeFromList({ key: "roles", id });
      } catch (error) {
        logAxiosError(error, "removeRole");
      }
    },
  ),
  updateGoal: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      const { name, title, id } = payload;

      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(
          `${KIC_API}/api/v1/goals/${id}`,
          { name, title },
          { headers },
        );

        getStoreActions().tags.updateList({
          data: response.data,
          key: "goals",
        });

        return response.data;
      } catch (error) {
        logAxiosError(error, "updateMealGoal");
        return error.response.data;
      }
    },
  ),
  updateIngredientCategory: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      const { name, title, id } = payload;

      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(
          `${KIC_API}/api/v1/ingredientcategories/${id}`,
          { name, title },
          { headers },
        );

        getStoreActions().tags.updateList({
          data: response.data,
          key: "ingredientCategories",
        });

        return response.data;
      } catch (error) {
        logAxiosError(error, "updateIngredientCategory");
        return error.response.data;
      }
    },
  ),
  updateMealType: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      const { name, title, id } = payload;

      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(
          `${KIC_API}/api/v1/mealtypes/${id}`,
          { name, title },
          { headers },
        );

        getStoreActions().tags.updateList({
          data: response.data,
          key: "mealTypes",
        });

        return response.data;
      } catch (error) {
        logAxiosError(error, "updateMealType");
        return error.response.data;
      }
    },
  ),
  updateRole: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      const { name, title, id, strength } = payload;

      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(
          `${KIC_API}/api/v1/roles/${id}`,
          { name, title, strength },
          { headers },
        );

        getStoreActions().tags.updateList({
          data: response.data,
          key: "updateRole",
        });

        return response.data;
      } catch (error) {
        logAxiosError(error, "updateRole");
        return error.response.data;
      }
    },
  ),

  // ==========================
  // Version 2
  // ==========================

  fetchTags: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(`${KIC_API}/api/v2/tags/${payload}`, {
          headers,
        });

        return response.data;
      } catch (error) {
        logAxiosError(error, `fetchTags /${payload}`);
        return error?.response?.data ?? { error };
      }
    },
  ),
  fetchTagNames: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(`${KIC_API}/api/v2/tags/list/names`, {
          headers,
        });

        return response;
      } catch (error) {
        logAxiosError(error, "fetchTagNames");
        return error.response;
      }
    },
  ),
  updateTag: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { rootId, value } = payload;
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(
          `${KIC_API}/api/v2/tags/${rootId}`,
          value,
          {
            headers,
          },
        );

        return response;
      } catch (error) {
        logAxiosError(error, "updateTag");
        return error.response;
      }
    },
  ),
};

export default actions;
