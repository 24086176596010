import React from "react";

import { Box, InputText, InputTextProps, Text } from "../../../ui";

interface InputProps extends InputTextProps {
  "placeholder": string;
  "errorMessage"?: string;
  "data-cy": string;
}

export const SignupFormInput: React.FC<InputProps> = ({
  autoFocus,
  value,
  placeholder,
  onChange,
  errorMessage,
  type,
  children,
  ...rest
}) => {
  return (
    <Box mb="sm">
      <Box flexDirection="row">
        <Box flexGrow={1}>
          <InputText
            type={type}
            autoFocus={autoFocus}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            hasError={!!errorMessage}
            {...rest}
          />
        </Box>
        <Box alignSelf="center">{children}</Box>
      </Box>
      <Text variant="imageTag" marginLeft="md" color="secondary">
        {errorMessage}
      </Text>
    </Box>
  );
};
