import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

export const useMutableUrlParam = <T extends string>({
  paramName,
  defaultValue,
}: {
  paramName: string;
  defaultValue?: T[];
}) => {
  const router = useRouter();
  const [value, _setValue] = useState<T[]>([]);

  const rawParamValue = router.query[paramName];

  useEffect(() => {
    if (defaultValue) setValue(defaultValue);
  }, []);

  useEffect(() => {
    try {
      const queryValue = JSON.parse(`${rawParamValue}`);
      return _setValue((Array.isArray(queryValue) ? queryValue : []) as T[]);
    } catch (e) {
      return _setValue([]);
    }
  }, [JSON.stringify(rawParamValue)]);

  const setValue = useCallback(
    (newValue: T[]) =>
      router.push({
        query: {
          ...router.query,
          [paramName]: JSON.stringify(newValue),
        },
      }),
    [router.query, paramName],
  );

  return {
    value,
    setValue,
  };
};
