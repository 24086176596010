import axios from "axios";
import { thunk } from "easy-peasy";

import { logAxiosError } from "../utils";

const fetchPosts = ({ key, endpoint }) =>
  thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    await getStoreActions().auth.checkTokenExpiration();

    const { token } = getStoreState().auth.authData;

    try {
      const {
        pageNumber,
        limit,
        search,
        visibility,
        categories,
        difficulty,
        goals,
        mealTypes,
        bumpSorted = false,
        includeBUMPContent = true,
        level,
      } = payload;
      const params = {
        page: pageNumber || 1,
        ...(!!limit && { limit }),
        ...(!!search && { search }),
        ...(!!visibility && { visibility: JSON.stringify(visibility) }),
        ...(!!categories && {
          "type_specific.categories": JSON.stringify(categories),
        }),
        ...(!!difficulty && {
          "type_specific.difficulty": JSON.stringify(difficulty),
        }),
        ...(!!goals && {
          "type_specific.goals": JSON.stringify(goals),
        }),
        ...(!!mealTypes && {
          "type_specific.meal_types": JSON.stringify(mealTypes),
        }),
        ...(!!level && { "type_specific.level": JSON.stringify(level) }),
        bumpSorted,
        ...(!includeBUMPContent && { "type_specific.categories.bump": false }),
      };

      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.get(endpoint, {
        headers: token ? headers : "",
        params,
      });

      const { data, settings } = response.data;

      actions.setState({ key: "posts", value: data });
      actions.setState({ key: "settings", value: settings });

      return response.data;
    } catch (error) {
      logAxiosError(error, key);
      return error?.response?.data ?? { error };
    }
  });

export default fetchPosts;
