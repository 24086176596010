import * as React from "react";

function SvgComponent({ props }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.1152 10.0566C11.4082 10.3494 11.4083 10.8243 11.1154 11.1172C10.881 11.3517 10.7494 11.6697 10.7494 12.0012C10.7495 12.3328 10.8813 12.6507 11.1157 12.8851C11.3502 13.1195 11.6682 13.2511 11.9997 13.2511C12.3313 13.251 12.6492 13.1192 12.8836 12.8848C13.1764 12.5918 13.6513 12.5917 13.9442 12.8846C14.2372 13.1774 14.2373 13.6523 13.9444 13.9452C13.4288 14.4611 12.7293 14.7509 12 14.7511C11.2706 14.7512 10.5711 14.4616 10.0553 13.9459C9.53944 13.4303 9.24958 12.7308 9.24945 12.0015C9.24932 11.2721 9.53893 10.5726 10.0546 10.0568C10.3474 9.76382 10.8223 9.76373 11.1152 10.0566Z"
        fill="#181818"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9981 6.75C11.4375 6.74855 10.8782 6.80386 10.3288 6.91509C9.92283 6.99727 9.5271 6.73479 9.44491 6.32881C9.36273 5.92283 9.62521 5.5271 10.0312 5.44491C10.6795 5.31366 11.3395 5.24836 12.001 5.25C15.9422 5.25036 19.1536 7.46486 21.6431 11.6141C21.7856 11.8516 21.7856 12.1484 21.6431 12.3859C20.9538 13.5347 20.209 14.5367 19.4071 15.3852C19.1225 15.6862 18.6478 15.6996 18.3468 15.4151C18.0458 15.1305 18.0324 14.6558 18.3169 14.3548C18.9454 13.6899 19.5471 12.906 20.1194 12C20.1991 12.1262 20.2783 12.2548 20.3569 12.3859L21 12L20.3569 11.6141C20.2783 11.7451 20.1991 11.8737 20.1194 12C17.8563 8.41603 15.1475 6.75 12 6.75L11.9981 6.75ZM7.9576 6.93107C8.17571 7.28321 8.06707 7.74548 7.71493 7.9636C6.32958 8.82169 5.04674 10.1537 3.88061 12C6.14366 15.584 8.85254 17.25 12 17.25H12.0049C13.5172 17.26 15.0011 16.8393 16.2832 16.0372C16.6343 15.8175 17.0971 15.9241 17.3168 16.2752C17.5365 16.6263 17.4299 17.0891 17.0788 17.3088C15.556 18.2615 13.7937 18.7614 11.9975 18.75C8.05698 18.7491 4.84612 16.5346 2.35688 12.3859C2.21437 12.1484 2.21437 11.8516 2.35688 11.6141C3.67216 9.422 5.18912 7.76365 6.92507 6.6884C7.27721 6.47029 7.73948 6.57894 7.9576 6.93107Z"
        fill="#181818"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.46967 2.46967C2.76256 2.17678 3.23744 2.17678 3.53033 2.46967L21.5303 20.4697C21.8232 20.7626 21.8232 21.2374 21.5303 21.5303C21.2374 21.8232 20.7626 21.8232 20.4697 21.5303L2.46967 3.53033C2.17678 3.23744 2.17678 2.76256 2.46967 2.46967Z"
        fill="#181818"
      />
    </svg>
  );
}

export default SvgComponent;
