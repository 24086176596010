import { KIC_API } from "../../../src/api/index";
import addToPosts from "../../../src/utils/actionModels/addToPosts";
import createPost from "../../../src/utils/actionModels/createPost";
import fetchPost from "../../../src/utils/actionModels/fetchPost";
import fetchPosts from "../../../src/utils/actionModels/fetchPosts";
import removeFromPosts from "../../../src/utils/actionModels/removeFromPosts";
import removePost from "../../../src/utils/actionModels/removePost";
import setState from "../../../src/utils/actionModels/setState";
import updatePost from "../../../src/utils/actionModels/updatePost";
import parser from "../../../src/utils/parsersForEndpoints/recipe";

const endpoint = `${KIC_API}/api/v2/post/recipes`;

const actions = {
  // Actions
  addToPosts: addToPosts(),
  removeFromPosts: removeFromPosts(),
  setState: setState(),

  // Thunks
  createRecipe: createPost({
    key: "createRecipe",
    endpoint,
    parser,
  }),
  fetchRecipe: fetchPost({ key: "fetchRecipe", endpoint }),
  fetchRecipes: fetchPosts({ key: "fetchRecipes", endpoint }),
  removeRecipe: removePost({ key: "removeRecipe", endpoint }),
  updateRecipe: updatePost({
    key: "updateRecipe",
    endpoint,
    parser,
  }),
};

export default actions;
