import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 100.8 100.8"
      xmlSpace="preserve"
      width="1rem"
      height="1rem"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path d="M92.7 38.3h-2.8V26.7c0-3.4-2.8-6.2-6.2-6.2h-10c-3.4 0-6.2 2.8-6.2 6.2V43h-34V26.7c0-3.4-2.8-6.2-6.2-6.2h-10c-3.4 0-6.2 2.8-6.2 6.2v11.6H8.3C3.8 38.3.1 42 .1 46.5v8.8c0 4.5 3.7 8.2 8.2 8.2h2.8v11.6c0 3.4 2.8 6.2 6.2 6.2h10c3.4 0 6.2-2.8 6.2-6.2V58.8h34v16.3c0 3.4 2.8 6.2 6.2 6.2h10c3.4 0 6.2-2.8 6.2-6.2V63.5h2.8c4.5 0 8.2-3.7 8.2-8.2v-8.8c0-4.5-3.7-8.2-8.2-8.2zM11.1 58.5H8.3c-1.7 0-3.2-1.4-3.2-3.2v-8.8c0-1.8 1.4-3.2 3.2-3.2H11v15.2zM28.5 43v32.1c0 .6-.5 1.2-1.2 1.2h-10c-.6 0-1.2-.5-1.2-1.2V26.7c0-.6.5-1.2 1.2-1.2h10c.6 0 1.2.5 1.2 1.2V43zm38.7 10.8H33.5V48h33.7v5.8zm17.7 21.3c0 .6-.5 1.2-1.2 1.2h-10c-.6 0-1.2-.5-1.2-1.2V26.7c0-.6.5-1.2 1.2-1.2h10c.6 0 1.2.5 1.2 1.2v48.4zm11-19.8c0 1.8-1.4 3.2-3.2 3.2H90V43.3h2.7c1.7 0 3.2 1.4 3.2 3.2v8.8z" />
    </svg>
  );
}

export default SvgComponent;
