import state from "./media.state";
import actions from "./media.actions";
import computators from "./media.computators";
import listeners from "./media.listeners";

export default {
  ...state,
  ...actions,
  ...computators,
  ...listeners,
};
