import * as React from "react";

function SvgComponent({ fill, ...props }) {
  return (
    <svg
      width="128"
      height="42"
      viewBox="0 0 128 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M117.062 -0.00157248H10.6937C10.3059 -0.00157248 9.92286 -0.00157248 9.53616 0.000527516C9.21246 0.00262752 8.89133 0.00872802 8.56452 0.0138625C7.85454 0.0221594 7.14622 0.0841876 6.44573 0.199408C5.74622 0.317137 5.06863 0.539089 4.43586 0.857758C3.80387 1.17912 3.2264 1.5967 2.72441 2.09535C2.21977 2.5926 1.79912 3.16735 1.47863 3.7975C1.15726 4.42639 0.934412 5.10031 0.817798 5.79598C0.700075 6.49077 0.636727 7.19358 0.628323 7.89808C0.618511 8.22001 0.617475 8.54301 0.612305 8.86499V33.135C0.617475 33.461 0.618511 33.7768 0.628323 34.103C0.63673 34.8074 0.700078 35.5102 0.817798 36.205C0.93409 36.901 1.15695 37.5753 1.47863 38.2045C1.79898 38.8326 2.21969 39.405 2.72441 39.8995C3.2245 40.4003 3.80234 40.8182 4.43586 41.1371C5.06862 41.4566 5.74614 41.6799 6.44573 41.7995C7.14634 41.9138 7.85458 41.9758 8.56452 41.9851C8.89133 41.9922 9.21246 41.9963 9.53616 41.9963C9.92285 41.9984 10.306 41.9984 10.6937 41.9984H117.062C117.442 41.9984 117.828 41.9984 118.208 41.9963C118.53 41.9963 118.86 41.9922 119.183 41.9851C119.891 41.9763 120.598 41.9142 121.297 41.7995C121.999 41.6791 122.679 41.4559 123.315 41.1371C123.948 40.818 124.525 40.4002 125.025 39.8995C125.528 39.4031 125.95 38.8311 126.274 38.2045C126.593 37.5749 126.814 36.9006 126.929 36.205C127.047 35.5101 127.112 34.8075 127.125 34.103C127.129 33.7768 127.129 33.461 127.129 33.135C127.137 32.7535 127.137 32.3741 127.137 31.9865V10.0114C127.137 9.62684 127.137 9.2454 127.129 8.86499C127.129 8.54301 127.129 8.22001 127.125 7.89804C127.112 7.19347 127.047 6.49083 126.929 5.79594C126.814 5.10067 126.593 4.42681 126.274 3.79745C125.621 2.53438 124.586 1.50625 123.315 0.857664C122.679 0.539773 121.999 0.31788 121.297 0.199314C120.598 0.0835855 119.891 0.0215357 119.183 0.0137155C118.86 0.00859152 118.53 0.00243852 118.208 0.000391016C117.828 -0.00170898 117.442 -0.00170898 117.062 -0.00170898V-0.00157248Z"
        fill="#A6A6A6"
      />
      <path
        d="M9.54404 41.0812C9.22189 41.0812 8.90752 41.0771 8.5879 41.07C7.92578 41.0614 7.2652 41.0042 6.61159 40.8987C6.00213 40.7945 5.41173 40.6006 4.85986 40.3234C4.31305 40.0486 3.81432 39.6881 3.38277 39.256C2.94497 38.8289 2.58048 38.3336 2.30374 37.7897C2.02396 37.2422 1.83034 36.6554 1.72961 36.0496C1.62084 35.3987 1.56199 34.7406 1.55357 34.0809C1.54686 33.8594 1.53809 33.1221 1.53809 33.1221V8.86651C1.53809 8.86651 1.54743 8.14053 1.55362 7.92724C1.56168 7.26856 1.62019 6.61144 1.72864 5.96157C1.82955 5.35416 2.02333 4.76564 2.30326 4.21636C2.57897 3.67279 2.94144 3.1771 3.3766 2.74852C3.81127 2.31585 4.3116 1.95359 4.85934 1.67492C5.40995 1.39865 5.99923 1.20612 6.60746 1.10378C7.26321 0.997279 7.92608 0.939697 8.5905 0.931522L9.54455 0.918701H118.205L119.171 0.932036C119.829 0.939805 120.486 0.996874 121.136 1.10276C121.75 1.20638 122.346 1.40025 122.902 1.678C124 2.23959 124.893 3.12807 125.456 4.21893C125.731 4.76441 125.922 5.34813 126.022 5.95029C126.132 6.60549 126.193 7.26778 126.206 7.93186C126.209 8.22922 126.209 8.54863 126.209 8.86651C126.217 9.26026 126.217 9.63504 126.217 10.0129V31.988C126.217 32.3695 126.217 32.7417 126.209 33.117C126.209 33.4584 126.209 33.7712 126.204 34.0931C126.192 34.7454 126.132 35.3958 126.024 36.0393C125.925 36.6494 125.733 37.2408 125.453 37.7928C125.174 38.3309 124.812 38.8219 124.379 39.2478C123.947 39.6823 123.447 40.0448 122.899 40.3214C122.344 40.6007 121.749 40.7953 121.136 40.8987C120.482 41.0047 119.822 41.062 119.159 41.07C118.85 41.0771 118.525 41.0812 118.211 41.0812L117.064 41.0833L9.54404 41.0812Z"
        fill={fill}
      />
      <path
        d="M26.799 21.315C26.8104 20.4386 27.0448 19.5793 27.4805 18.817C27.9161 18.0548 28.5388 17.4143 29.2907 16.9553C28.8131 16.2779 28.183 15.7204 27.4504 15.3271C26.7179 14.9338 25.9031 14.7156 25.0707 14.6897C23.2952 14.5047 21.5739 15.7448 20.6691 15.7448C19.7469 15.7448 18.3538 14.7081 16.8537 14.7388C15.8835 14.7699 14.9378 15.0501 14.109 15.552C13.2802 16.054 12.5964 16.7606 12.1244 17.603C10.0795 21.1189 11.6048 26.286 13.5636 29.1279C14.5437 30.5195 15.689 32.074 17.1878 32.0189C18.6544 31.9585 19.2021 31.0902 20.9724 31.0902C22.7263 31.0902 23.2402 32.0189 24.7693 31.9838C26.343 31.9585 27.3345 30.586 28.2802 29.1812C28.9843 28.1896 29.5262 27.0937 29.8857 25.9341C28.9714 25.5501 28.1911 24.9073 27.6423 24.0859C27.0934 23.2645 26.8001 22.3008 26.799 21.315V21.315Z"
        fill="white"
      />
      <path
        d="M23.9112 12.8212C24.7693 11.7982 25.192 10.4835 25.0896 9.15601C23.7787 9.29274 22.5678 9.91492 21.6981 10.8986C21.2729 11.3791 20.9473 11.9382 20.7398 12.5438C20.5323 13.1494 20.447 13.7897 20.4889 14.4281C21.1446 14.4348 21.7932 14.2936 22.386 14.0153C22.9789 13.7369 23.5003 13.3287 23.9112 12.8212Z"
        fill="white"
      />
      <path
        d="M45.3392 28.4963H40.3344L39.1325 32.0206H37.0127L41.7532 18.9817H43.9556L48.696 32.0206H46.5401L45.3392 28.4963ZM40.8528 26.87H44.8199L42.8642 21.1504H42.8095L40.8528 26.87Z"
        fill="white"
      />
      <path
        d="M58.9314 27.2673C58.9314 30.2214 57.3392 32.1194 54.9364 32.1194C54.3278 32.1511 53.7225 32.0118 53.1898 31.7177C52.6571 31.4235 52.2186 30.9864 51.9245 30.4562H51.879V35.1648H49.9141V22.5136H51.8161V24.0947H51.8522C52.1598 23.5671 52.6057 23.1322 53.1425 22.8363C53.6792 22.5404 54.2867 22.3945 54.9003 22.4141C57.3298 22.4141 58.9314 24.3213 58.9314 27.2673ZM56.9117 27.2673C56.9117 25.3426 55.9101 24.0773 54.3819 24.0773C52.8806 24.0773 51.8708 25.3693 51.8708 27.2673C51.8708 29.1827 52.8806 30.4655 54.3819 30.4655C55.9101 30.4655 56.9117 29.2094 56.9117 27.2673Z"
        fill="white"
      />
      <path
        d="M69.4675 27.2673C69.4675 30.2214 67.8753 32.1194 65.4726 32.1194C64.8639 32.151 64.2586 32.0118 63.7259 31.7177C63.1932 31.4235 62.7547 30.9864 62.4606 30.4562H62.4151V35.1648H60.4502V22.5136H62.3522V24.0947H62.3883C62.6959 23.5671 63.1418 23.1322 63.6786 22.8363C64.2153 22.5404 64.8228 22.3945 65.4364 22.4141C67.866 22.4141 69.4675 24.3213 69.4675 27.2673ZM67.4478 27.2673C67.4478 25.3426 66.4463 24.0773 64.9181 24.0773C63.4167 24.0773 62.4069 25.3693 62.4069 27.2673C62.4069 29.1827 63.4167 30.4655 64.9181 30.4655C66.4463 30.4655 67.4478 29.2094 67.4478 27.2673H67.4478Z"
        fill="white"
      />
      <path
        d="M76.4343 28.3878C76.5799 29.6808 77.8448 30.5298 79.5733 30.5298C81.2295 30.5298 82.421 29.6807 82.421 28.5149C82.421 27.5028 81.7024 26.8968 80.0007 26.4815L78.2991 26.0744C75.8881 25.4961 74.7688 24.3764 74.7688 22.5594C74.7688 20.3097 76.743 18.7644 79.5464 18.7644C82.3209 18.7644 84.2228 20.3097 84.2869 22.5594H82.3033C82.1846 21.2582 81.1014 20.4727 79.5185 20.4727C77.9356 20.4727 76.8525 21.2674 76.8525 22.424C76.8525 23.3459 77.5443 23.8883 79.2366 24.3035L80.6832 24.6563C83.3772 25.2889 84.4965 26.3636 84.4965 28.2708C84.4965 30.7102 82.5398 32.238 79.4276 32.238C76.5158 32.238 74.5499 30.7461 74.4229 28.3877L76.4343 28.3878Z"
        fill="white"
      />
      <path
        d="M88.7353 20.2639V22.5136H90.5557V24.0589H88.7353V29.2997C88.7353 30.1138 89.0998 30.4932 89.9 30.4932C90.1161 30.4895 90.3318 30.4744 90.5464 30.4481V31.9841C90.1866 32.0509 89.8209 32.0811 89.4549 32.0743C87.5169 32.0743 86.761 31.3514 86.761 29.5078V24.0589H85.3691V22.5136H86.761V20.2639H88.7353Z"
        fill="white"
      />
      <path
        d="M91.6113 27.2673C91.6113 24.2763 93.3852 22.3967 96.1515 22.3967C98.927 22.3967 100.693 24.2762 100.693 27.2673C100.693 30.2666 98.9363 32.1379 96.1515 32.1379C93.3677 32.1379 91.6113 30.2666 91.6113 27.2673ZM98.6905 27.2673C98.6905 25.2155 97.7437 24.0045 96.1515 24.0045C94.5593 24.0045 93.6134 25.2248 93.6134 27.2673C93.6134 29.3273 94.5593 30.5291 96.1515 30.5291C97.7437 30.5291 98.6905 29.3273 98.6905 27.2673H98.6905Z"
        fill="white"
      />
      <path
        d="M102.313 22.5135H104.188V24.1315H104.233C104.36 23.6262 104.658 23.1796 105.077 22.8668C105.497 22.554 106.012 22.3941 106.536 22.414C106.762 22.4132 106.988 22.4376 107.209 22.4868V24.312C106.923 24.2253 106.625 24.1854 106.326 24.1941C106.041 24.1826 105.756 24.2326 105.492 24.3406C105.228 24.4486 104.99 24.6122 104.796 24.8199C104.601 25.0277 104.454 25.2749 104.365 25.5444C104.276 25.8139 104.246 26.0994 104.278 26.3812V32.0199H102.313L102.313 22.5135Z"
        fill="white"
      />
      <path
        d="M116.265 29.2279C116.001 30.9536 114.308 32.1379 112.143 32.1379C109.358 32.1379 107.63 30.2851 107.63 27.3125C107.63 24.3306 109.368 22.3967 112.061 22.3967C114.709 22.3967 116.375 24.2035 116.375 27.0858V27.7544H109.613V27.8723C109.582 28.2222 109.626 28.5746 109.743 28.9063C109.859 29.2379 110.046 29.5411 110.289 29.7958C110.533 30.0505 110.828 30.2508 111.155 30.3834C111.483 30.516 111.835 30.5779 112.189 30.565C112.653 30.6082 113.119 30.5014 113.517 30.2605C113.916 30.0196 114.225 29.6574 114.399 29.2279L116.265 29.2279ZM109.623 26.3906H114.409C114.426 26.076 114.378 25.7612 114.268 25.466C114.157 25.1707 113.986 24.9013 113.765 24.6748C113.545 24.4482 113.28 24.2694 112.986 24.1495C112.693 24.0295 112.378 23.9711 112.061 23.9779C111.741 23.976 111.423 24.037 111.127 24.1575C110.831 24.278 110.562 24.4555 110.335 24.6798C110.109 24.9041 109.929 25.1708 109.807 25.4644C109.684 25.7581 109.622 26.0729 109.623 26.3906V26.3906Z"
        fill="white"
      />
      <path
        d="M40.607 9.16678C41.0189 9.13742 41.4323 9.19922 41.8173 9.34773C42.2023 9.49623 42.5493 9.72774 42.8333 10.0255C43.1172 10.3233 43.3311 10.68 43.4595 11.0699C43.5879 11.4597 43.6275 11.873 43.5756 12.2799C43.5756 14.2814 42.4862 15.432 40.607 15.432H38.3281V9.16678H40.607ZM39.308 14.5459H40.4975C40.7919 14.5634 41.0865 14.5155 41.3598 14.4056C41.6332 14.2958 41.8785 14.1268 42.0778 13.9109C42.2771 13.6951 42.4254 13.4378 42.5119 13.1579C42.5985 12.8779 42.6211 12.5823 42.5781 12.2926C42.618 12.0041 42.5931 11.7103 42.5052 11.4324C42.4173 11.1545 42.2686 10.8993 42.0698 10.6852C41.8709 10.4711 41.6268 10.3033 41.355 10.1938C41.0832 10.0844 40.7904 10.036 40.4975 10.0521H39.308V14.5459Z"
        fill="white"
      />
      <path
        d="M44.6854 13.0672C44.6555 12.7565 44.6913 12.443 44.7905 12.1468C44.8897 11.8507 45.0502 11.5783 45.2616 11.3474C45.473 11.1164 45.7307 10.9318 46.0181 10.8055C46.3056 10.6793 46.6164 10.614 46.9307 10.614C47.245 10.614 47.5559 10.6793 47.8433 10.8055C48.1307 10.9318 48.3884 11.1164 48.5998 11.3474C48.8112 11.5783 48.9717 11.8507 49.0709 12.1468C49.1701 12.443 49.2059 12.7565 49.176 13.0672C49.2065 13.3782 49.1711 13.6922 49.0722 13.9888C48.9732 14.2855 48.8129 14.5583 48.6014 14.7897C48.3899 15.0212 48.1321 15.2061 47.8443 15.3327C47.5566 15.4592 47.2454 15.5246 46.9307 15.5246C46.616 15.5246 46.3048 15.4592 46.0171 15.3327C45.7294 15.2061 45.4715 15.0212 45.26 14.7897C45.0486 14.5583 44.8882 14.2855 44.7892 13.9888C44.6903 13.6922 44.6549 13.3782 44.6854 13.0672V13.0672ZM48.2095 13.0672C48.2095 12.0423 47.7459 11.443 46.9323 11.443C46.1155 11.443 45.6561 12.0423 45.6561 13.0672C45.6561 14.1003 46.1155 14.695 46.9323 14.695C47.7459 14.695 48.2095 14.0962 48.2095 13.0672H48.2095Z"
        fill="white"
      />
      <path
        d="M55.1424 15.4324H54.1676L53.1836 11.9502H53.1093L52.1294 15.4324H51.1639L49.8516 10.7043H50.8046L51.6575 14.3121H51.7277L52.7066 10.7043H53.608L54.5869 14.3121H54.6612L55.51 10.7043H56.4496L55.1424 15.4324Z"
        fill="white"
      />
      <path
        d="M57.5557 10.7048H58.4602V11.4559H58.5304C58.6495 11.1861 58.8504 10.96 59.1051 10.809C59.3598 10.658 59.6555 10.5897 59.9512 10.6135C60.1829 10.5962 60.4155 10.6309 60.6318 10.715C60.8481 10.7991 61.0426 10.9305 61.2009 11.0994C61.3592 11.2683 61.4771 11.4703 61.5461 11.6907C61.615 11.911 61.6331 12.1439 61.5991 12.3721V15.4328H60.6595V12.6064C60.6595 11.8466 60.327 11.4687 59.6321 11.4687C59.4748 11.4615 59.3178 11.488 59.1719 11.5467C59.0259 11.6053 58.8944 11.6946 58.7864 11.8084C58.6784 11.9222 58.5965 12.0578 58.5463 12.206C58.496 12.3542 58.4786 12.5114 58.4953 12.6669V15.4329H57.5557L57.5557 10.7048Z"
        fill="white"
      />
      <path
        d="M63.0947 8.85864H64.0344V15.4324H63.0947V8.85864Z"
        fill="white"
      />
      <path
        d="M65.3387 13.0656C65.3088 12.7549 65.3446 12.4413 65.4439 12.1452C65.5431 11.849 65.7036 11.5767 65.915 11.3457C66.1265 11.1147 66.3842 10.9301 66.6716 10.8038C66.9591 10.6775 67.2699 10.6123 67.5843 10.6123C67.8986 10.6123 68.2095 10.6775 68.4969 10.8038C68.7844 10.9301 69.0421 11.1147 69.2535 11.3457C69.4649 11.5767 69.6254 11.849 69.7247 12.1452C69.8239 12.4413 69.8597 12.7549 69.8298 13.0656C69.8603 13.3766 69.8249 13.6906 69.7259 13.9872C69.6269 14.2839 69.4665 14.5567 69.255 14.7881C69.0435 15.0195 68.7856 15.2045 68.4979 15.331C68.2102 15.4576 67.899 15.523 67.5843 15.523C67.2696 15.523 66.9584 15.4576 66.6706 15.331C66.3829 15.2045 66.125 15.0195 65.9135 14.7881C65.702 14.5567 65.5416 14.2839 65.4426 13.9872C65.3437 13.6906 65.3083 13.3766 65.3387 13.0656V13.0656ZM68.8628 13.0656C68.8628 12.0407 68.3992 11.4414 67.5856 11.4414C66.7688 11.4414 66.3094 12.0407 66.3094 13.0656C66.3094 14.0987 66.7688 14.6934 67.5856 14.6934C68.3992 14.6934 68.8628 14.0946 68.8628 13.0656H68.8628Z"
        fill="white"
      />
      <path
        d="M70.8184 14.0945C70.8184 13.2434 71.4565 12.7528 72.5892 12.6831L73.8788 12.6092V12.2011C73.8788 11.7018 73.5464 11.4198 72.9041 11.4198C72.3796 11.4198 72.0161 11.611 71.9118 11.9453H71.0022C71.0982 11.1332 71.8674 10.6123 72.9475 10.6123C74.1411 10.6123 74.8143 11.2024 74.8143 12.2011V15.4316H73.9098V14.7672H73.8355C73.6846 15.0055 73.4727 15.1998 73.2214 15.3303C72.9702 15.4607 72.6886 15.5225 72.4054 15.5095C72.2055 15.5302 72.0035 15.509 71.8124 15.4474C71.6213 15.3857 71.4453 15.285 71.2957 15.1517C71.1462 15.0184 71.0265 14.8554 70.9443 14.6733C70.862 14.4913 70.8191 14.2941 70.8184 14.0945V14.0945ZM73.8788 13.6905V13.2952L72.7162 13.3691C72.0605 13.4126 71.7631 13.6341 71.7631 14.0509C71.7631 14.4765 72.1349 14.7241 72.646 14.7241C72.7958 14.7392 72.9471 14.7241 73.0909 14.6799C73.2347 14.6358 73.3681 14.5633 73.4831 14.4669C73.5981 14.3704 73.6924 14.252 73.7604 14.1186C73.8284 13.9853 73.8687 13.8397 73.8788 13.6905V13.6905Z"
        fill="white"
      />
      <path
        d="M76.0518 13.0663C76.0518 11.5724 76.8251 10.6259 78.0281 10.6259C78.3256 10.6123 78.6209 10.6831 78.8795 10.83C79.138 10.9769 79.3491 11.1938 79.4881 11.4554H79.5583V8.85864H80.4979V15.4324H79.5976V14.6854H79.5232C79.3735 14.9453 79.155 15.1595 78.8915 15.3049C78.6279 15.4503 78.3294 15.5214 78.0281 15.5103C76.8169 15.5104 76.0518 14.5639 76.0518 13.0663ZM77.0224 13.0663C77.0224 14.0692 77.4984 14.6726 78.2945 14.6726C79.0864 14.6726 79.5759 14.0605 79.5759 13.0705C79.5759 12.0851 79.0813 11.4642 78.2945 11.4642C77.5035 11.4642 77.0224 12.0717 77.0224 13.0663H77.0224Z"
        fill="white"
      />
      <path
        d="M84.3846 13.0672C84.3547 12.7565 84.3905 12.443 84.4897 12.1468C84.5889 11.8507 84.7494 11.5783 84.9608 11.3474C85.1722 11.1164 85.4299 10.9318 85.7173 10.8055C86.0048 10.6793 86.3156 10.614 86.6299 10.614C86.9442 10.614 87.2551 10.6793 87.5425 10.8055C87.83 10.9318 88.0876 11.1164 88.299 11.3474C88.5105 11.5783 88.6709 11.8507 88.7701 12.1468C88.8694 12.443 88.9052 12.7565 88.8752 13.0672C88.9057 13.3782 88.8703 13.6922 88.7714 13.9888C88.6724 14.2855 88.5121 14.5583 88.3006 14.7897C88.0892 15.0212 87.8313 15.2061 87.5435 15.3327C87.2558 15.4592 86.9446 15.5246 86.6299 15.5246C86.3152 15.5246 86.004 15.4592 85.7163 15.3327C85.4286 15.2061 85.1707 15.0212 84.9592 14.7897C84.7478 14.5583 84.5874 14.2855 84.4885 13.9888C84.3895 13.6922 84.3541 13.3782 84.3846 13.0672V13.0672ZM87.9087 13.0672C87.9087 12.0423 87.4451 11.443 86.6315 11.443C85.8147 11.443 85.3553 12.0423 85.3553 13.0672C85.3553 14.1003 85.8148 14.695 86.6315 14.695C87.4451 14.695 87.9087 14.0962 87.9087 13.0672Z"
        fill="white"
      />
      <path
        d="M90.1357 10.7048H91.0403V11.4559H91.1105C91.2296 11.1861 91.4305 10.96 91.6852 10.809C91.9399 10.658 92.2356 10.5897 92.5313 10.6135C92.7629 10.5962 92.9955 10.6309 93.2119 10.715C93.4282 10.7991 93.6227 10.9305 93.781 11.0994C93.9392 11.2683 94.0572 11.4703 94.1262 11.6907C94.1951 11.911 94.2132 12.1439 94.1792 12.3721V15.4328H93.2396V12.6064C93.2396 11.8466 92.9071 11.4687 92.2122 11.4687C92.0549 11.4615 91.8979 11.488 91.7519 11.5467C91.606 11.6053 91.4745 11.6946 91.3665 11.8084C91.2585 11.9222 91.1766 12.0578 91.1263 12.206C91.0761 12.3542 91.0587 12.5114 91.0754 12.6669V15.4329H90.1357V10.7048Z"
        fill="white"
      />
      <path
        d="M99.4875 9.52661V10.7253H100.519V11.5113H99.4875V13.9425C99.4875 14.4377 99.693 14.6546 100.161 14.6546C100.28 14.6542 100.4 14.647 100.519 14.6331V15.4103C100.35 15.4403 100.179 15.4562 100.008 15.458C98.963 15.458 98.5468 15.0929 98.5468 14.1814V11.5112H97.791V10.7252H98.5468V9.52661H99.4875Z"
        fill="white"
      />
      <path
        d="M101.806 8.85864H102.737V11.4642H102.811C102.936 11.1919 103.143 10.9645 103.402 10.8129C103.662 10.6613 103.963 10.593 104.263 10.6172C104.494 10.6047 104.724 10.6429 104.938 10.7291C105.152 10.8152 105.344 10.9472 105.5 11.1155C105.657 11.2839 105.774 11.4845 105.844 11.703C105.913 11.9216 105.934 12.1527 105.903 12.3798V15.4324H104.962V12.61C104.962 11.8548 104.608 11.4724 103.944 11.4724C103.783 11.4592 103.62 11.4813 103.468 11.537C103.316 11.5927 103.178 11.6807 103.064 11.7949C102.95 11.909 102.862 12.0466 102.807 12.1979C102.752 12.3493 102.731 12.5108 102.745 12.671V15.4324H101.806L101.806 8.85864Z"
        fill="white"
      />
      <path
        d="M111.377 14.1551C111.25 14.5872 110.973 14.961 110.596 15.2111C110.219 15.4611 109.765 15.5715 109.314 15.523C109.001 15.5312 108.689 15.4715 108.401 15.3481C108.113 15.2247 107.855 15.0405 107.646 14.8083C107.437 14.5761 107.281 14.3015 107.189 14.0035C107.097 13.7055 107.072 13.3912 107.115 13.0826C107.073 12.7729 107.099 12.458 107.19 12.1591C107.282 11.8602 107.437 11.5843 107.645 11.35C107.853 11.1158 108.11 10.9287 108.397 10.8015C108.684 10.6742 108.996 10.6097 109.31 10.6124C110.635 10.6124 111.434 11.5112 111.434 12.9959V13.3215H108.072V13.3738C108.057 13.5473 108.079 13.7219 108.137 13.8865C108.194 14.0511 108.285 14.202 108.405 14.3295C108.524 14.4571 108.669 14.5584 108.83 14.6271C108.991 14.6957 109.165 14.7302 109.34 14.7283C109.565 14.7551 109.793 14.7149 109.994 14.6128C110.196 14.5107 110.363 14.3514 110.473 14.1551L111.377 14.1551ZM108.072 12.6314H110.477C110.489 12.4727 110.467 12.3133 110.413 12.1634C110.359 12.0136 110.275 11.8766 110.164 11.7612C110.054 11.6459 109.92 11.5547 109.772 11.4937C109.624 11.4326 109.465 11.4029 109.305 11.4066C109.143 11.4045 108.981 11.4348 108.831 11.4957C108.68 11.5565 108.543 11.6467 108.429 11.7608C108.314 11.875 108.223 12.0108 108.162 12.1603C108.101 12.3098 108.07 12.47 108.072 12.6314H108.072Z"
        fill="white"
      />
    </svg>
  );
}

export default SvgComponent;
