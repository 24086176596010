import { KIC_API } from "../../../src/api/index";
import addToPosts from "../../../src/utils/actionModels/addToPosts";
import createPost from "../../../src/utils/actionModels/createPost";
import fetchPost from "../../../src/utils/actionModels/fetchPost";
import fetchPosts from "../../../src/utils/actionModels/fetchPosts";
import removeFromPosts from "../../../src/utils/actionModels/removeFromPosts";
import removePost from "../../../src/utils/actionModels/removePost";
import setState from "../../../src/utils/actionModels/setState";
import updatePost from "../../../src/utils/actionModels/updatePost";
import parser from "../../../src/utils/parsersForEndpoints/breakdown";

const endpoint = `${KIC_API}/api/v3/post/runs`;

const actions = {
  // Actions
  addToPosts: addToPosts(),
  removeFromPosts: removeFromPosts(),
  setState: setState(),

  // Thunks
  createRuns: createPost({ key: "createRuns", endpoint, parser }),
  fetchRun: fetchPost({ key: "fetchRun", endpoint }),
  fetchRuns: fetchPosts({ key: "fetchRuns", endpoint }),
  removeRun: removePost({ key: "removeRun", endpoint }),
  updateRun: updatePost({ key: "updateRun", endpoint, parser }),
};

export default actions;
