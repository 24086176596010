import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 17 16" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M8.39959 1.16335C8.03595 1.52699 8.03595 2.07244 8.39959 2.43608L13.4905 7.52699H1.12686C0.626864 7.52699 0.217773 7.93608 0.217773 8.43608C0.217773 8.93608 0.626864 9.34517 1.12686 9.34517H13.4905L8.39959 14.4361C8.03595 14.7997 8.03595 15.3452 8.39959 15.7088C8.76323 16.0724 9.30868 16.0724 9.67232 15.7088L16.945 8.43608L9.67232 1.16335C9.30868 0.799716 8.76323 0.799716 8.39959 1.16335Z" />
    </svg>
  );
}

export default SvgComponent;
