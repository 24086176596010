import state from "./tags.state";
import actions from "./tags.actions";
import computators from "./tags.computators";
import listeners from "./tags.listeners";

export default {
  ...state,
  ...actions,
  ...computators,
  ...listeners,
};
