import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 95.1 95.1"
      xmlSpace="preserve"
      width="1rem"
      height="1rem"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path d="M84.5 39.3c-.8-8.8-4.7-14.2-11.1-15.4-.3-4.4-1.4-8.8-3.8-12.2C64.5 4.5 57 1.7 43 1.5c-5.2-.1-9.8 0-14.4 1.3-5.7 1.6-11 5.2-13.9 9.4-2.9 4.2-4.4 9.6-4.4 16 0 9.8.1 16.1.3 24.8l.1 4.8c.1 4.8.2 8.9 2.9 13.7 1.2 2.1 2.6 4.3 4.2 6.3V89.5c0 2.2 1.8 3.9 3.9 3.9h42.7c2.2 0 3.9-1.8 3.9-3.9V78.2v-.4c6.5-3.3 11.7-9 14.1-15.8 2.9-7.9 2.5-17.6 2.1-22.7zM63.3 88.5H22.7v-9.3h40.6v9.3zm14.4-28.2c-2.3 6.4-7.5 11.6-14 14H21.3c-1.3-1.7-2.4-3.4-3.4-5.2-2.1-3.7-2.1-6.7-2.2-11.4l-.1-4.8c-.2-8.7-.3-14.9-.3-24.7 0-5.3 1.2-9.9 3.5-13.2 2.3-3.3 6.6-6.1 11-7.4 4-1.1 8.3-1.1 13-1.1 12.4.1 18.5 2.3 22.6 8.1 3.7 5.1 3.6 14.7 1.3 20-3.4 7.7-8.3 11.4-16 12-12 .9-18.9-.4-26.1-4.9-1.2-.7-2.7-.4-3.4.8s-.4 2.8.8 3.5c8.1 5.1 16 6.6 29.1 5.6 9.6-.7 16.1-5.5 20.2-15 .9-2.2 1.6-4.8 1.9-7.7 3.6 1 5.7 4.6 6.3 10.7.4 4.8.7 13.7-1.8 20.7z" />
      <path d="M23.4 31.1c1.4 0 2.5-1.1 2.5-2.5 0-5.9 5.4-10.7 12.1-10.7 1.4 0 2.5-1.1 2.5-2.5s-1.1-2.5-2.5-2.5c-9.4 0-17.1 7-17.1 15.7 0 1.4 1.2 2.5 2.5 2.5z" />
    </svg>
  );
}

export default SvgComponent;
