import { SendRequest } from "../makeSendRequest";
import { PostRunDocument, PostRunInsert, PostRunUpdate } from "./types";

type ListPaginatedRuns = {
  data: PostRunDocument[];
  settings: {
    total: number;
    limit: number;
    page: number | null;
    pages: number;
    next: number | null;
  };
};

export type ListGuidedRunsRequestQueryParams = {
  page?: number;
  search?: string;
  categories?: string[];
  subcategories?: {
    [x: `type_specific.categories.${string}`]: string[];
  };
};

export const createRunsApi = (sendRequest: SendRequest) => ({
  list: async ({
    page,
    search,
    categories,
    subcategories,
  }: ListGuidedRunsRequestQueryParams = {}) => {
    const params = new URLSearchParams();

    if (page) {
      params.append("page", page.toString());
    }

    if (search) {
      params.append("search", search);
    }

    if (categories) {
      params.append("categories", categories.join(","));
    }

    if (subcategories) {
      Object.entries(subcategories).forEach(([key, value]) => {
        params.append(key, value.join(","));
      });
    }

    const queryString = params.toString();

    const response = await sendRequest<ListPaginatedRuns>({
      method: "get",
      path: `post/runs${params.size > 0 ? `?${queryString}` : ""}`,
      version: "v3",
    });

    return response;
  },
  create: async (data: PostRunInsert) => {
    const response = await sendRequest<{
      success: true;
      _id: string;
    }>({
      method: "post",
      path: "post/runs",
      version: "v3",
      data,
    });

    return response;
  },
  update: async (id: string, data: Partial<PostRunUpdate>) => {
    const response = await sendRequest<{
      success: true;
    }>({
      method: "put",
      path: `post/runs/${id}`,
      version: "v3",
      data,
    });

    return response;
  },
  view: async (id: string) => {
    const response = await sendRequest<PostRunDocument>({
      method: "get",
      path: `post/runs/${id}`,
      version: "v3",
    });

    return response;
  },
  delete: async (id: string) => {
    const response = await sendRequest<{
      success: true;
    }>({
      method: "delete",
      path: `post/runs/${id}`,
      version: "v3",
    });

    return response;
  },
});
