import React, { useMemo } from "react";

import { BoxProps, Text } from "../../ui";
import { Callout } from "../../ui/Callout/Callout";

export const FormErrorsCard: React.FC<{ formErrors?: string[] } & BoxProps> = ({
  formErrors,
  ...rest
}) => {
  const filteredErrors = useMemo(
    () => formErrors.filter((error) => !!error),
    [formErrors],
  );

  if (!filteredErrors?.length) return null;
  return (
    <Callout variant="error" {...rest}>
      <Text variant="bodySmall">
        {filteredErrors.map((err, index) => (
          <li key={index}>{err}</li>
        ))}
      </Text>
    </Callout>
  );
};
