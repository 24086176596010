import React from "react";

import { Box } from "../../ui";
import { BoxProps } from "../Box";
import { ColorThemeTokens } from "../palette";

type Variants = "error" | "warning";
type CalloutProps = BoxProps & {
  variant: Variants;
};

const variantStyles: Record<
  Variants,
  { bg: ColorThemeTokens; borderColor: ColorThemeTokens }
> = {
  warning: {
    bg: "backgroundSemanticYellow",
    borderColor: "backgroundSemanticYellow",
  },
  error: { bg: "backgroundSemanticPink", borderColor: "secondary" },
};

export const Callout: React.FC<CalloutProps> = ({
  variant,
  children,
  ...rest
}) => (
  <Box
    px={["sm", "md"]}
    py="sm"
    borderStyle="solid"
    borderWidth="md"
    borderRadius="md"
    {...variantStyles[variant]}
    {...rest}
  >
    {children}
  </Box>
);
