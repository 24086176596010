import state from "./payments.state";
import actions from "./payments.actions";
import computators from "./payments.computators";
import listeners from "./payments.listeners";

export default {
  ...state,
  ...actions,
  ...computators,
  ...listeners,
};
