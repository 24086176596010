import React from "react";
import { Box } from "../Box";
import { useTheme } from "../ThemeProvider";

export const InputTextIconAdornment: React.FC = ({ children }) => {
  const { space } = useTheme();
  return (
    <Box
      position="absolute"
      right={space.sm}
      alignItems="center"
      justifyContent="center"
      alignContent="center"
    >
      {children}
    </Box>
  );
};
