import React from "react";
import { useTheme } from "..";

import { Box, BoxProps } from "../Box";

export type CardProps = BoxProps;

export const Card: React.FC<CardProps> = ({ children, ...props }) => (
  <Box
    m="xxs"
    px={["sm", "md"]}
    py="md"
    bg="backgroundSecondary"
    borderRadius="md"
    css={{ contain: "layout" }}
    {...props}
  >
    {children}
  </Box>
);

export const CardHeader = ({ children, ...props }) => {
  return (
    <Box
      borderTopLeftRadius="md"
      borderTopRightRadius="md"
      mt="-md"
      mx={["-sm", "-md"]}
      {...props}
    >
      {children}
    </Box>
  );
};

export const CardFooter = ({ children, ...props }) => {
  const { space } = useTheme();
  return (
    <Box
      borderBottomLeftRadius="md"
      borderBottomRightRadius="md"
      mb="-md"
      mx={["-sm", "-md"]}
      {...props}
    >
      {children}
    </Box>
  );
};
