import { createStore, persist, createTypedHooks } from "easy-peasy";

import adminBreakdownsInitialState from "./admin/breakdowns/admin.breakdowns.state";
import adminBreakdownsStore from "./admin/breakdowns/admin.breakdowns.store";
import adminGlobalsInitialState from "./admin/globals/admin.globals.state";
import adminGlobalsStore from "./admin/globals/admin.globals.store";
import adminIngredientsInitialState from "./admin/ingredients/admin.ingredients.state";
import adminIngredientsStore from "./admin/ingredients/admin.ingredients.store";
import adminMasterclassesInitialState from "./admin/masterclasses/admin.masterclasses.state";
import adminMasterclassesStore from "./admin/masterclasses/admin.masterclasses.store";
import adminMediaInitialState from "./admin/media/admin.media.state";
import adminMediaStore from "./admin/media/admin.media.store";
import adminPaymentsInitialState from "./admin/payments/admin.payments.state";
import adminPaymentsStore from "./admin/payments/admin.payments.store";
import adminProgramInitialState from "./admin/program/admin.program.state";
import adminProgramStore from "./admin/program/admin.program.store";
import adminRecipesInitialState from "./admin/recipes/admin.recipes.state";
import adminRecipesStore from "./admin/recipes/admin.recipes.store";
import adminTagsInitialState from "./admin/tags/admin.tags.state";
import adminTagsStore from "./admin/tags/admin.tags.store";
import adminTrainersInitialState from "./admin/trainers/admin.trainers.state";
import adminTrainersStore from "./admin/trainers/admin.trainers.store";
import adminUsersInitialState from "./admin/users/admin.users.state";
import adminUsersStore from "./admin/users/admin.users.store";
import authInitialState from "./auth/auth.state";
import auth from "./auth/auth.store";
import computators from "./computators/computators";
import masterclassesInitialState from "./masterclasses/admin.masterclasses.state";
import masterclasses from "./masterclasses/admin.masterclasses.store";
import mediaInitialState from "./media/media.state";
import media from "./media/media.store";
import paymentsInitialState from "./payments/payments.state";
import payments from "./payments/payments.store";
import profileInitialState from "./profile/profile.state";
import profile from "./profile/profile.store";
import tagsInitialState from "./tags/tags.state";
import tags from "./tags/tags.store";
import adminRunsInitialState from "./admin/runs/admin.runs.state";
import adminRunsStore from "./admin/runs/admin.runs.store";

export type StoreModel = typeof model;

export const initStoreState = {
  auth: authInitialState,
  profile: profileInitialState,
  tags: tagsInitialState,
  payments: paymentsInitialState,
  media: mediaInitialState,
  masterclasses: masterclassesInitialState,
  admin: {
    users: adminUsersInitialState,
    media: adminMediaInitialState,
    ingredients: adminIngredientsInitialState,
    recipes: adminRecipesInitialState,
    tags: adminTagsInitialState,
    payments: adminPaymentsInitialState,
    globals: adminGlobalsInitialState,
    breakdowns: adminBreakdownsInitialState,
    program: adminProgramInitialState,
    masterclasses: adminMasterclassesInitialState,
    trainers: adminTrainersInitialState,
    runs: adminRunsInitialState,
  },
};

const model = persist(
  {
    auth,
    profile,
    tags,
    payments,
    media,
    masterclasses,
    computators,
    admin: {
      users: adminUsersStore,
      ingredients: adminIngredientsStore,
      recipes: adminRecipesStore,
      tags: adminTagsStore,
      media: adminMediaStore,
      payments: adminPaymentsStore,
      globals: adminGlobalsStore,
      breakdowns: adminBreakdownsStore,
      program: adminProgramStore,
      masterclasses: adminMasterclassesStore,
      trainers: adminTrainersStore,
      runs: adminRunsStore,
    },
  },
  {
    storage: "localStorage",
    mergeStrategy: "mergeDeep",
    blacklist: ["computators"],
  },
);

const initializeStore = (initialState = initStoreState) =>
  createStore(model, {
    initialState,
    name: "KeepItCleaner",
  });

const typedHooks = createTypedHooks<StoreModel>();
export const useStoreActions = typedHooks.useStoreActions;
export const useStoreDispatch = typedHooks.useStoreDispatch;
export const useStoreState = typedHooks.useStoreState;

export { initializeStore };
