import actions from "./admin.masterclasses.actions";
import computators from "./admin.masterclasses.computators";
import listeners from "./admin.masterclasses.listeners";
import state from "./admin.masterclasses.state";

export default {
  ...state,
  ...actions,
  ...computators,
  ...listeners,
};
