import { RoomTheme } from "../recommendation/types";

export const PreapprovalTypes = ["none", "prenatal", "postnatal"] as const;
type PreapprovalType = (typeof PreapprovalTypes)[number];

export const ProgramTypes = ["none", "gym", "run"] as const;
type ProgramType = (typeof ProgramTypes)[number];

export type Program = {
  _id: string;
  inserted: number;
  updated: number;
  isRepeating: boolean;
  nextProgramId: string;
  name: string;
  internalName: string;
  description: string;
  previewDescription?: string;
  lengthDescription: string;
  imageUrl: string;
  videoUrl?: string;
  previewVideoUrl?: string;

  enrolTitle?: string;
  enrolMessage?: string;

  completeTitle?: string;
  completeMessage?: string;
  completeButton?: string;
  completeImageUrl?: string;

  displayColour: string;
  benefits: string[];
  activityTypes: string[];
  equipment: string[];
  programSelections?: ProgramSelection[];
  typeLabel?: string;
  sortOrder: number;
  preapprovalType: PreapprovalType;
  publishDate: number;

  programType: ProgramType;
  onlyShowInChallenge?: boolean;
  roomTheme?: RoomTheme;
  categories?: Partial<Record<string, true | string[]>>;
  level?: "beginner" | "intermediate" | "advanced" | "alllevels";
  trainerId?: string;
  muxPlaybackId?: string;
};

export type ProgramSelectionPostResponse = {
  _id: string;
  internalName: string;
  title: string;
  post_type: string;
};
export type ProgramSelectionResponse = Omit<ProgramSelection, "posts"> & {
  posts: ProgramSelectionPostResponse[];
};

export type ProgramResponse = Omit<Program, "programSelections"> & {
  programSelections: ProgramSelectionResponse[];
};

export type ProgramSelectionPost = { id: string; onModel: string };

export type ProgramSelection = {
  _id: string;
  inserted: number;
  updated: number;
  programId: string;
  daysPerWeek: number;
  posts: ProgramSelectionPost[];
};

export type PostData = {
  id: string;
  onModel: string;
  title: string;
};

export type ProgramSelectionPayload = Omit<ProgramSelection, "posts"> & {
  posts: PostData[];
};
