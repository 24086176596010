/* eslint-disable operator-assignment */
/* eslint-disable no-param-reassign */
import { action } from "easy-peasy";

import { KIC_API } from "../../src/api/index";
import createPost from "../../src/utils/actionModels/createPost";
import fetchPost from "../../src/utils/actionModels/fetchPost";
import fetchPosts from "../../src/utils/actionModels/fetchPosts";
import removePost from "../../src/utils/actionModels/removePost";
import updatePost from "../../src/utils/actionModels/updatePost";
import parser from "../../src/utils/parsersForEndpoints/masterclass";

const endpoint = `${KIC_API}/api/v2/post/masterclasses`;

const actions = {
  // Actions
  setState: action((state, payload) => {
    const { key, value } = payload;
    state[key] = value;
  }),
  // Thunks
  createMasterclass: createPost({ key: "createMasterclass", endpoint, parser }),
  fetchMasterclass: fetchPost({ key: "fetchMasterclass", endpoint }),
  fetchMasterclasses: fetchPosts({ key: "fetchMasterclasses", endpoint }),
  removeMasterclass: removePost({ key: "removeMasterclass", endpoint }),
  updateMasterclass: updatePost({ key: "updateMasterclass", endpoint, parser }),
};

export default actions;
