import state, { UserState } from "./admin.users.state";
import actions, { UserActions } from "./admin.users.actions";
import computators from "./admin.users.computators";
import listeners, { UserListeners } from "./admin.users.listeners";

export type EasyUserModel = UserActions & UserState & UserListeners;

const userModel: EasyUserModel = {
  ...state,
  ...actions,
  ...computators,
  ...listeners,
};

export default userModel;
