import React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 512 512" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M384 0H128c-23.531 0-42.667 19.135-42.667 42.667v96A10.66 10.66 0 0096 149.334h320a10.66 10.66 0 0010.667-10.667v-96C426.667 19.135 407.531 0 384 0zM202.667 106.667h-64a10.655 10.655 0 01-9.854-6.583 10.654 10.654 0 012.313-11.625l10.667-10.667c4.167-4.167 10.917-4.167 15.083 0a10.634 10.634 0 013.125 7.542h42.667c5.896 0 10.667 4.771 10.667 10.667s-4.772 10.666-10.668 10.666zM416 170.667H96a10.66 10.66 0 00-10.667 10.667V448c0 23.531 19.135 42.667 42.667 42.667v10.667a10.66 10.66 0 0010.667 10.667H160a10.66 10.66 0 0010.667-10.667v-10.667h170.667v10.667a10.66 10.66 0 0010.667 10.667h21.333a10.66 10.66 0 0010.667-10.667v-10.667c23.531 0 42.667-19.135 42.667-42.667V181.333A10.662 10.662 0 00416 170.667zm-213.333 64h-64a10.655 10.655 0 01-9.854-6.583 10.654 10.654 0 012.313-11.625l10.667-10.667c4.167-4.167 10.917-4.167 15.083 0a10.634 10.634 0 013.125 7.542h42.667c5.896 0 10.667 4.771 10.667 10.667s-4.772 10.666-10.668 10.666z" />
    </svg>
  );
}

export default SvgComponent;
