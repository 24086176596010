import { useQuery } from "react-query";

import { useCreateAPIClient } from "./useCreateAPIClient";

export const useApiStage = () => {
  const api = useCreateAPIClient();
  return useQuery({
    queryFn: api.apiStage.get,
    queryKey: ["apiStage"],
  });
};
