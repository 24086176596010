import React, { useEffect, useState } from "react";
import { revenueCatClient } from "../utils/revenueCatClient";
import { useUser } from "../../../hooks";
import { SubscriptionInfo } from "../types";

type SubscriptionInfoWrapped = {
  subscriptionInfo: SubscriptionInfo;
};
export const SubscriptionInfoWrappedContext =
  React.createContext<SubscriptionInfoWrapped>(undefined);

export const SubscriptionInfoProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const user = useUser();

  const [subscriptionInfoWrapped, setSubscriptionInfoWrapped] =
    useState<SubscriptionInfoWrapped>({ subscriptionInfo: undefined });

  const listenerCallback = (subscriptionInfo: SubscriptionInfo) => {
    setSubscriptionInfoWrapped({
      subscriptionInfo,
    });
  };

  useEffect(() => {
    revenueCatClient.addListener(listenerCallback);
    return () => {
      revenueCatClient.removeListener(listenerCallback);
    };
  }, []);

  useEffect(() => {
    revenueCatClient.sync(user?.revenueCatUserId, false);
  }, [user?.revenueCatUserId]);

  return (
    <SubscriptionInfoWrappedContext.Provider value={subscriptionInfoWrapped}>
      {children}
    </SubscriptionInfoWrappedContext.Provider>
  );
};
