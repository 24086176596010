import { Discount, Plan } from "../../api";

export const calculateFinalPrice = (
  plan?: { price: number },
  discount?: Discount,
) => {
  if (!discount) return plan?.price;

  if (discount.unit === "flat") {
    return plan?.price - discount?.amount;
  } else if (discount.unit === "percentage") {
    return plan?.price * (1 - discount?.amount / 100);
  }
};

export const formatFreeTrialLabel = ({
  plan,
  hyphenate = false,
}: {
  plan: Plan;
  hyphenate?: boolean;
}) => {
  if (!plan) return null;

  const plurality = !hyphenate && plan.trial_duration > 1 ? "s" : "";
  const hyphenation = hyphenate ? "-" : " ";
  return `${plan.trial_duration}${hyphenation}${plan.trial_duration_unit}${plurality}`;
};
