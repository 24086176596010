import Cookies from "js-cookie";
import { notifyError } from "./monitoring";

const getTikTokPixel = () => {
  return (window as any).ttq;
};

let _isInitialized = false;
export const initTikTokPixel = (webPixelCode: string) => {
  if (!_isInitialized) {
    _isInitialized = true;
    try {
      const ttq = getTikTokPixel();
      ttq.load(webPixelCode);
      ttq.page();

      return true;
    } catch (error) {
      console.log(error);
      notifyError(error);
    }
  }
  return false;
};

export const trackTikTokPixelEvent = (name: string) => {
  try {
    const ttq = getTikTokPixel();
    ttq.track(name);
    return true;
  } catch (error) {
    notifyError(error);
    return false;
  }
};

export const getTikTokUserPixelId = () => {
  try {
    return Cookies.get("_ttp");
  } catch (error) {
    notifyError(error);
  }
};

export const getTikTokUserClickId = () => {
  try {
    return Cookies.get("ttclid");
  } catch (error) {
    notifyError(error);
  }
};

export const removeInitTikTokPixel = () => {
  delete (window as any).ttq;
};
