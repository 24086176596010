import state from "./admin.tags.state";
import actions from "./admin.tags.actions";
import computators from "./admin.tags.computators";
import listeners from "./admin.tags.listeners";

export default {
  ...state,
  ...actions,
  ...computators,
  ...listeners,
};
