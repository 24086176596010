import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 100.8 100.8" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <g>
        <path d="M14.1 29.2C7 29.2 1.2 35 1.2 42.1S7 55 14.1 55 27 49.2 27 42.1s-5.8-12.9-12.9-12.9zm0 20.8c-4.4 0-7.9-3.5-7.9-7.9s3.5-7.9 7.9-7.9 7.9 3.5 7.9 7.9-3.5 7.9-7.9 7.9z" />
        <path d="M98.9 88.4c-1.6-4.3-3.2-8.7-4.8-12.9-2-5.1-4-10.4-5.8-15.7-2.2-6.5-6-17.3-13.8-22.8-5.9-4.1-19.2-5.7-27.9-6.7-.6-.1-1.2-.1-1.7-.2l-2-22.3c0-1.7-.6-3.4-1.8-4.7S38.2 1 36.4.9s-3.5.5-4.8 1.8c-1.3 1.2-2.1 2.9-2.2 4.6l-1.5 27.6c-.1 1.4 1 2.6 2.4 2.6h.1c1.3 0 2.4-1 2.5-2.4l1.6-27.6c0-.5.2-.9.6-1.2.3-.3.8-.5 1.3-.5s.9.2 1.2.6c.3.3.5.8.5 1.3V8l2.2 24.3c.1 1.2 1 2.1 2.1 2.2.3.2.6.3 1 .3.9.1 1.8.2 2.9.3 7.8.9 20.8 2.4 25.7 5.8 6.5 4.5 9.9 14.4 12 20.3 1.8 5.3 3.9 10.7 5.9 15.8 1.6 4.2 3.3 8.5 4.8 12.8.7 1.9.3 3.2-1.3 3.8-1.7.7-3.5-.1-4.3-1.9L73 58.8c-.4-.9-1.3-1.5-2.3-1.4-1 0-1.9.7-2.2 1.6L55.2 92.5c-.7 1.9-1.9 2.6-3.5 2-1.7-.6-2.6-2.4-2-4.2l9.6-31.1c.2-.8.1-1.6-.4-2.2s-1.2-1-2-1H43.4c-.8 0-1.4.4-1.9.9-.5.4-.9.9-1.1 1.6l-5.8 34.1c-.1 1-1 1.6-2 1.5s-1.6-1-1.5-2V53.6c0-1.4-1.1-2.5-2.5-2.5s-2.5 1.1-2.5 2.5v37.9c-.4 3.6 2.1 6.9 5.8 7.4.3 0 .6.1 1 .1 3.3 0 6.2-2.4 6.7-5.7l5.5-32.4h8.4l-8.6 27.8c-1.4 4.3.8 8.9 5.1 10.4.9.3 1.8.5 2.7.5 3.1 0 5.8-2 7.1-5.4l11.1-28.1L84.1 94c1.8 4.2 6.5 6.2 10.7 4.5 4.1-1.6 5.8-5.7 4.1-10.1z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
