import { KIC_API } from "../../../src/api/index";
import addToPosts from "../../../src/utils/actionModels/addToPosts";
import createPost from "../../../src/utils/actionModels/createPost";
import fetchPost from "../../../src/utils/actionModels/fetchPost";
import fetchPosts from "../../../src/utils/actionModels/fetchPosts";
import removeFromPosts from "../../../src/utils/actionModels/removeFromPosts";
import removePost from "../../../src/utils/actionModels/removePost";
import setState from "../../../src/utils/actionModels/setState";
import updatePost from "../../../src/utils/actionModels/updatePost";
import parser from "../../../src/utils/parsersForEndpoints/trainer";

const endpoint = `${KIC_API}/api/v2/trainers`;

const actions = {
  // Actions
  addToPosts: addToPosts(),
  removeFromPosts: removeFromPosts(),
  setState: setState(),

  // Thunks
  createTrainer: createPost({ key: "createTrainer", endpoint, parser }),
  fetchTrainer: fetchPost({ key: "fetchTrainer", endpoint }),
  fetchTrainers: fetchPosts({ key: "fetchTrainers", endpoint }),
  removeTrainer: removePost({ key: "removeTrainer", endpoint }),
  updateTrainer: updatePost({ key: "updateTrainer", endpoint, parser }),
};

export default actions;
