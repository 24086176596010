import { useQuery } from "react-query";

import { useCreateAPIClient } from "./useCreateAPIClient";

export const usePostCategories = () => {
  const api = useCreateAPIClient();
  const { data, isLoading } = useQuery({
    queryFn: api.postCategories.get,
    queryKey: "post.categories",
  });

  return { postCategories: data, loading: isLoading };
};
