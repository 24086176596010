const listeners = {
  // onLogOut: thunkOn(
  //   (actions, storeActions) => storeActions.auth.logOut,
  //   async (actions, target) => {
  //     actions.clearState();
  //   }
  // ),
};

export default listeners;
