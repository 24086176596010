import React from "react";

function SvgComponent(props) {
  return (
    <svg x={0} y={0} viewBox="0 0 69.3 69.3" xmlSpace="preserve" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M40.3 34.7L68.2 6.8c1.6-1.6 1.6-4.1 0-5.7-1.6-1.6-4.1-1.6-5.7 0L34.7 29 6.8 1.2C5.2-.4 2.7-.4 1.1 1.2c-1.6 1.6-1.6 4.1 0 5.7L29 34.7 1.2 62.5c-1.6 1.6-1.6 4.1 0 5.7.8.7 1.8 1.1 2.8 1.1s2-.4 2.8-1.2l27.8-27.8 27.8 27.8c.8.8 1.8 1.2 2.8 1.2s2-.4 2.8-1.2c1.6-1.6 1.6-4.1 0-5.7L40.3 34.7z" />
    </svg>
  );
}

export default SvgComponent;
