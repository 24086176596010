import styled from "styled-components";
import Icon from "../../../icons";

export const KicIcon = styled(Icon)`
  fill: ${(props) => props.theme.brandColour};
  height: 3rem;
  width: 8rem;
`;

export const StyledIcon = styled(Icon)`
  cursor: pointer;
  fill: ${(props) => props.theme.authIconColour};
  height: 3rem;
  width: 3rem;

  ${(props) =>
    props.big &&
    `
    height: 4rem;
    width: 4rem;
  `}
`;
