import styled from "styled-components";
import css from "@styled-system/css";

import { Box, BoxProps } from "../Box/Box";

type TileProps = BoxProps & {
  hoverTransition?: boolean;
};

const Card: React.FC<TileProps> = styled(Box)((p: any) =>
  !p.hoverTransition
    ? {}
    : css({
        "&:hover": {
          boxShadow: "mdHeavy",
        },
        "transition": "all 0.2s ease-in-out",
      }),
);

export const Tile = Card;

Tile.defaultProps = {
  bg: "white",
  borderRadius: "md",
  boxShadow: "md",
  width: "fit-content",
  overflow: "hidden",
};
