import { RevenueCatPromotionalDuration } from "../../features/subscriptions/types";
import { SendRequest } from "../makeSendRequest";

export const createAdminUserSubscriptionApi = (sendRequest: SendRequest) => ({
  grantPromotionalSubscripion: (
    userId: string,
    duration: RevenueCatPromotionalDuration,
  ) =>
    sendRequest<Record<string, unknown>>({
      method: "post",
      path: "admin/grant-promotional-subscription",
      version: "v3",
      data: { userId, duration },
    }),
  revokePromotionalSubscripions: (userId: string) =>
    sendRequest<Record<string, unknown>>({
      method: "post",
      path: "admin/revoke-promotional-subscription",
      version: "v3",
      data: { userId },
    }),
});
