import styled from "styled-components";

import Icon from "../../../icons";
import { media } from "../../../styles/mediaQueries";

export const Container = styled.div`
  background: #fff;
  border-top: 1px solid ${(props) => props.theme.authBorderColour};
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  padding: 5rem 2rem;
  width: 100%;
  z-index: 1;

  ${media.tablet`
    padding: 5rem;
  `};
`;

export const InnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;

  ${(props) =>
    props.marginTop &&
    `
    margin: 5rem 0 0 0;
  `};

  ${media.tablet`
    flex-direction: row;
  `};
`;

export const BottomContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column-reverse;
  justify-content: space-between;

  ${media.tablet`
    flex-direction: row;
  `}
`;

export const Section = styled.div`
  flex: 1;
  margin: 0 0 3rem 0;

  ${(props) =>
    props.double &&
    `
    flex: 1.7;
  `};

  &:last-child {
    margin: 0;
  }

  ${media.tablet`
    margin: 0;
  `};
`;

export const StoreLink = styled.a`
  margin-right: 1rem;
`;

export const Title = styled.h1`
  font-family: Ttrounds;
  font-size: 1.2rem;
  letter-spacing: 0.15rem;
  margin: 0 0 1rem 0;
  text-transform: uppercase;
`;

export const Label = styled.a`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 0 0 0.7rem 0;
  text-decoration: none;
  width: fit-content;
`;

export const StyledIcon = styled(Icon)`
  fill: ${(props) => props.theme.authIconColour};
  height: 1.5rem;
  margin: 0 0.5rem 0 0;
  width: 1.5rem;

  ${(props) =>
    props.big &&
    `
    height: 4rem;
    margin: 0;
    width: 10rem;
  `};
`;

export const Text = styled.p`
  color: ${(props) => props.theme.primaryFontColour};
  font-size: 1.2rem;
  margin: 0;

  ${(props) =>
    props.pointer &&
    `
    cursor: pointer;
  `};
`;

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  width: fit-content;
`;
