import React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 480 480" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M144 264h16v16h-16zM144 296h16v16h-16zM144 328h16v16h-16zM144 232h16v16h-16zM144 200h16v16h-16zM144 168h16v16h-16zM144 136h16v16h-16zM32 136h16v16H32zM432 136h16v16h-16zM432 328h16v16h-16zM32 328h16v16H32z" />
      <path d="M472 200a8 8 0 008-8v-80a8 8 0 00-8-8H8a8 8 0 00-8 8v80a8 8 0 008 8c22.091 0 40 17.909 40 40s-17.909 40-40 40a8 8 0 00-8 8v80a8 8 0 008 8h464a8 8 0 008-8v-80a8 8 0 00-8-8c-22.091 0-40-17.909-40-40s17.909-40 40-40zm-55.476 47.956c3.532 24.61 22.867 43.944 47.476 47.476V360H16v-64.568c30.614-4.394 51.87-32.773 47.476-63.388C59.944 207.435 40.61 188.1 16 184.568V120h448v64.568c-30.614 4.394-51.87 32.773-47.476 63.388z" />
      <path d="M240 160c-17.673 0-32 14.327-32 32s14.327 32 32 32c17.673 0 32-14.327 32-32s-14.327-32-32-32zm0 48c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16-7.163 16-16 16zM336 256c-17.673 0-32 14.327-32 32 0 17.673 14.327 32 32 32 17.673 0 32-14.327 32-32 0-17.673-14.327-32-32-32zm0 48c-8.837 0-16-7.163-16-16s7.163-16 16-16 16 7.163 16 16-7.163 16-16 16zM218.344 298.324l127.997-127.997 11.314 11.314-127.997 127.997z" />
    </svg>
  );
}

export default SvgComponent;
