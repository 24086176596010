import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";

import { Box } from "../../../ui";
import Icon from "../../../icons";
import { marketingUrl } from "../../../hooks/useMarketingRedirect";

export const OnboardingHeader: React.FC = ({ children }) => {
  const router = useRouter();

  const canNavigateBack = window.history.state.idx > 0;

  const onPressBack = () => {
    router.back();
  };

  return (
    <Box
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      py={["xs", "sm"]}
      px={["sm", "md"]}
    >
      <Box flex={1} alignItems={"flex-start"}>
        {canNavigateBack && (
          <Box cursor="pointer" onClick={onPressBack}>
            <Icon name="caret-left-outline" />
          </Box>
        )}
      </Box>
      <Box flex={1} alignItems="center">
        <Link href={marketingUrl}>
          <a>
            <Icon name="kic-logo" size="ixs" />
          </a>
        </Link>
      </Box>
      <Box flexDirection="row" justifyContent="flex-end" flex={1}>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};
