import { AxiosError } from "axios";

import { createActivityLogApi } from "./activityLog/makeActivityLogApi";
import { getAuthTokens } from "./auth";
import { createChallengeApi } from "./challenges";
import { createDailyQuoteApi } from "./daily-quote/makeDailyQuoteApi";
import { createDiscountApi } from "./discount/createDiscountApi";
import { createExerciseMediaApi } from "./exercise/createExerciseMediaApi";
import { createExploreSectionApi } from "./explore-section/makeExploreSectionApi";
import { createExploreProgramGroupApi } from "./exploreProgramGroup/createExploreProgramGroupApi";
import { createHomeScreenSectionApi } from "./home-sections/makeHomeScreenSectionsApi";
import { makeSendRequest } from "./makeSendRequest";
import { createMasterclassApi } from "./masterclass/makeMasterclassApi";
import { createMediaApi } from "./media/createMediaApi";
import { createProgramsApi } from "./programs";
import { createRecipesProvider } from "./recipes";
import { createRecommendationApi } from "./recommendation/makeRecommendationApi";
import { createRunsApi } from "./runs/createRunsApi";
import { createSeriesApi } from "./series";
import { createStripeApi } from "./stripe/createStripeApi";
import { createSubscriptionPlansApi } from "./subscriptionPlans/createSubscriptionPlansApi";
import { createTagsProvider } from "./tags";
import { createTrainersApi } from "./trainers/createTrainersApi";
import {
  ApiPaginatedResponse,
  ApiStage,
  DatabaseStage,
  Discount,
  Plan,
  PostCategory,
} from "./types";
import { createUsersApi } from "./users/makeUsersApi";
import { createVimeoApi } from "./vimeo/makeVimeoApi";
import { createAdminUserSubscriptionApi } from "./adminUserSubscription/createAdminUserSubscriptionApi";
import { createRunTypesApi } from "./runTypes/createRunTypesApi";
import { createMuxApi } from "./mux/makeMuxApi";

export { KIC_API } from "./makeSendRequest";
export * from "./types";

export const createAPIClient = () => {
  const sendRequest = makeSendRequest(() =>
    getAuthTokens().then((tokens) => tokens.auth),
  );

  return {
    users: createUsersApi(sendRequest),
    recipes: createRecipesProvider(sendRequest),
    activityLog: createActivityLogApi(sendRequest),
    tags: createTagsProvider(sendRequest),
    programs: createProgramsApi(sendRequest),
    series: createSeriesApi(sendRequest),
    exerciseMedia: createExerciseMediaApi(sendRequest),
    discount: createDiscountApi(sendRequest),
    subscriptionPlans: createSubscriptionPlansApi(sendRequest),
    recommendation: createRecommendationApi(sendRequest),
    exploreSection: createExploreSectionApi(sendRequest),
    masterclasses: createMasterclassApi(sendRequest),
    homeSections: createHomeScreenSectionApi(sendRequest),
    dailyQuote: createDailyQuoteApi(sendRequest),
    trainers: createTrainersApi(sendRequest),
    globals: {
      get: (name) =>
        sendRequest({
          method: "get",
          path: `globals/authenticated/${name}`,
          version: "v1",
        }).catch(makeHandleError(`Error getting "${name}" global`)),
      put: (id, data) =>
        sendRequest({
          method: "put",
          path: `globals/${id}`,
          version: "v1",
          data,
        }).catch(makeHandleError(`Error putting "${id}" global`)),
    },

    // Posts
    postCategories: {
      get: () =>
        sendRequest<PostCategory[]>({
          method: "get",
          path: "post-categories",
        }).catch(makeHandleError("Error fetching all postCategories")),
    },

    // Admin
    homepage: {
      get: () =>
        sendRequest({ method: "get", path: "homepage", version: "v1" }).catch(
          makeHandleError('Error getting "homepage"'),
        ),
    },

    // Membership
    plans: {
      list: (discountCode?: string) =>
        sendRequest<ApiPaginatedResponse<Plan[]>>({
          method: "get",
          path: `subscriptions${
            !discountCode ? "" : `?coupon=${discountCode}`
          }`,
          version: "v1",
          options: {
            requiresAuth: false,
          },
        }).then(({ data }) => data),
    },

    coupon: {
      verify: ({
        discountCode,
        planId,
      }: {
        discountCode: string;
        planId: string;
      }) =>
        sendRequest<Discount>({
          method: "get",
          path: `discounts/check/${discountCode}?subscription=${planId}`,
          version: "v1",
          options: {
            requiresAuth: false,
          },
        }).catch(makeHandleError("Error in 'coupon.verify'")),
    },
    media: createMediaApi(sendRequest),
    challenge: createChallengeApi(sendRequest),
    vimeo: createVimeoApi(sendRequest),
    exploreProgramGroup: createExploreProgramGroupApi(sendRequest),
    mux: createMuxApi(sendRequest),
    // Api Stage
    apiStage: {
      get: () =>
        sendRequest<{
          stage: ApiStage;
          databaseStage: DatabaseStage;
        }>({
          method: "get",
          path: "stage",
          version: "v3",
        }).catch(makeHandleError('Error getting "stage"')),
    },
    runs: createRunsApi(sendRequest),
    stripeApi: createStripeApi(sendRequest),
    adminUserSubscription: createAdminUserSubscriptionApi(sendRequest),
    runTypes: createRunTypesApi(sendRequest),
  };
};

const makeHandleError = (message: string) => (err: AxiosError) => {
  console.error(message, err);
  throw err;
};
