import { useStoreState } from "easy-peasy";
import { useMemo } from "react";
import { hasRoleAccess } from "../utils/utils";
import { User } from "../api";

export const useUser = () => {
  const profile = useStoreState((state) => state.profile.userData) as User;

  return profile;
};

export const isAdmin = (user: User) => {
  const roles = user?.roles;
  return hasRoleAccess(roles, 5);
};

export const useIsAdmin = () => {
  const user = useUser();
  const result = useMemo(() => isAdmin(user), [user.roles]);
  return result;
};
