import React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 25 24" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z" />
      <path d="M17.5 22H7.5C5.85 22 4.5 20.65 4.5 19V10.04C4.5 9.49001 4.95 9.04001 5.5 9.04001C6.05 9.04001 6.5 9.49001 6.5 10.04V19C6.5 19.55 6.95 20 7.5 20H17.5C18.05 20 18.5 19.55 18.5 19V10.04C18.5 9.49001 18.95 9.04001 19.5 9.04001C20.05 9.04001 20.5 9.49001 20.5 10.04V19C20.5 20.65 19.15 22 17.5 22Z" />
      <path d="M21.5 13C21.24 13 20.99 12.9 20.79 12.71L12.5 4.41L4.21001 12.71C3.82001 13.1 3.19001 13.1 2.80001 12.71C2.41001 12.32 2.41001 11.69 2.80001 11.3L11.79 2.29C12.18 1.9 12.81 1.9 13.2 2.29L22.2 11.29C22.59 11.68 22.59 12.31 22.2 12.7C22 12.9 21.75 12.99 21.49 12.99L21.5 13Z" />
    </svg>
  );
}

export default SvgComponent;
