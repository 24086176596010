import state from "./admin.globals.state";
import actions from "./admin.globals.actions";
import computators from "./admin.globals.computators";
import listeners from "./admin.globals.listeners";

export default {
  ...state,
  ...actions,
  ...computators,
  ...listeners,
};
