export type UserState = {
  users: any[];
  pageSettings: Record<string, any>;
  pageNumber: number;
};

const initialState: UserState = {
  users: [],
  pageSettings: {},
  pageNumber: undefined,
};

export default initialState;
