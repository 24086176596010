/* eslint-disable operator-assignment */
/* eslint-disable no-param-reassign */
import { thunk, action } from "easy-peasy";
import axios from "axios";
import { logAxiosError } from "../../../src/utils/utils";
import { KIC_API } from "../../../src/api/index";
import initialState from "./admin.payments.state";

const actions = {
  appendToList: action((state, payload) => {
    const { key, data } = payload;
    state[key].unshift(data);
  }),
  clearState: action((state) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = value;
    }
  }),
  createDiscount: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          `${KIC_API}/api/v1/discounts`,
          payload,
          { headers },
        );

        actions.appendToList({ key: "discounts", data: response.data });
        return response.data;
      } catch (error) {
        logAxiosError(error, "createDiscount");
        return error.response.data;
      }
    },
  ),
  createSubscription: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          `${KIC_API}/api/v1/subscriptions`,
          payload,
          { headers },
        );

        getStoreActions().payments.appendToList({
          key: "subscriptions",
          data: response.data,
        });
      } catch (error) {
        logAxiosError(error, "createSubscription");
      }
    },
  ),
  fetchDiscounts: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { pageNumber, limit, search } = payload;

        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(
          `${KIC_API}/api/v1/discounts?page=${pageNumber}${
            limit ? `&limit=${limit}` : ""
          }${search ? `&search=${search}` : ""}`,
          { headers },
        );

        actions.setState({ key: "discounts", data: response.data.data });
        actions.setState({
          key: "discountsPageSettings",
          data: response.data.settings,
        });
      } catch (error) {
        logAxiosError(error, "fetchDiscounts");
      }
    },
  ),
  removeDiscount: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        await axios.delete(`${KIC_API}/api/v1/discounts/${payload}`, {
          headers,
        });

        actions.removeFromList({ key: "discounts", id: payload });
      } catch (error) {
        logAxiosError(error, "removeDiscount");
      }
    },
  ),
  removeSubscription: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        await axios.delete(`${KIC_API}/api/v1/subscriptions/${payload}`, {
          headers,
        });

        getStoreActions().yoga.removeFromList({
          key: "subscriptions",
          id: payload,
        });
      } catch (error) {
        logAxiosError(error, "removeSubscription");
      }
    },
  ),
  updateDiscount: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { id, ...rest } = payload;

        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(
          `${KIC_API}/api/v1/discounts/${id}`,
          rest,
          { headers },
        );

        actions.updateList({ key: "discounts", data: response.data });

        return response.data;
      } catch (error) {
        logAxiosError(error, "updateDiscounts");
        return error.response.data;
      }
    },
  ),
  updateSubscription: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { id, ...rest } = payload;

        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(
          `${KIC_API}/api/v1/subscriptions/${id}`,
          rest,
          { headers },
        );

        getStoreActions().payments.updateList({
          key: "subscriptions",
          data: response.data,
        });
      } catch (error) {
        logAxiosError(error, "updateSubscription");
      }
    },
  ),
  removeFromList: action((state, payload) => {
    const { key, id } = payload;
    state[key] = state[key].filter((recipe) => recipe._id !== id);
  }),
  setState: action((state, payload) => {
    const { key, data } = payload;
    state[key] = data;
  }),
  updateList: action((state, payload) => {
    const { key, data } = payload;
    state[key] = state[key].map((item) =>
      item._id === data._id ? data : item,
    );
  }),
};

export default actions;
