import React, { useCallback } from "react";
import styled from "styled-components";

import Icon, { IconProps } from "../../icons";
import { Box, BoxProps } from "../Box";
import { Text, TextProps } from "../Text";
import { textVariants } from "../Text/textVariants";
import { LoadingIndicator } from "../LoadingIndicator";
import { ColorThemeTokens } from "../palette";
import { Theme } from "../theme";

export const ButtonVariants = [
  "primary",
  "link",
  "outline",
  "passive",
  "buttonFilledBrand",
  "buttonFilledLight",
  "buttonSmallFilledBrand",
  "buttonSmallFilledLight",
] as const;

const _Box = styled(Box)<{ theme: Theme; hoverColor: string }>`
  &:hover {
    background-color: ${(props) => props.theme.colors[props.hoverColor]};
    transition: all 0.3s ease-in-out;
  }
`;

export interface ButtonProps extends BoxProps {
  text: string;
  onClick: () => void;
  variant?: ReadonlyArrayValues<typeof ButtonVariants>;
  isLoading?: boolean;
  disabled?: boolean;
  iconName?: IconProps["name"];
  contentColor?: ColorThemeTokens;
}

const getVariantStyles = ({
  variant,
  disabled,
  isLoading,
  px,
  py,
  contentColor,
}: Partial<ButtonProps>) =>
  ((
    {
      primary: {
        box: {
          px,
          py,
          borderRadius: "full",
          border: "none",
          backgroundColor:
            disabled || isLoading ? "backgroundTertiary" : "backgroundBrand",
        },
        text: {
          py: "xxs",
          px: "sm",
        },
      },
      link: {
        box: {
          px: "xs",
          py: "xxs",
          border: "none",
          backgroundColor: "unset",
        },
        text: {
          ...textVariants.imageTag,
          fontWeight: 600,
          color: !disabled && !isLoading ? "contentTeal" : "contentContrastLow",
        },
      },
      outline: {
        box: {
          px,
          py,
          borderStyle: "solid",
          borderWidth: "md",
          borderRadius: "full",
          borderColor:
            !disabled && !isLoading ? "backgroundBrand" : "backgroundTertiary",
          backgroundColor: "transparent",
        },
        text: {
          color:
            !disabled && !isLoading
              ? "contentContrastHigh"
              : "contentContrastLow",
          py: "xxs",
          px: "sm",
        },
      },
      passive: {
        box: {
          px,
          py,
          borderStyle: "solid",
          borderWidth: "md",
          borderRadius: "full",
          borderColor:
            !disabled && !isLoading
              ? "backgroundContrastHigh"
              : "backgroundTertiary",
          backgroundColor: "transparent",
        },
        text: {
          color:
            !disabled && !isLoading
              ? "contentContrastHigh"
              : "contentContrastLow",
          py: "xxs",
          px: "sm",
        },
      },
      buttonFilledBrand: {
        box: {
          px,
          py,
          borderRadius: "full",
          border: "none",
          backgroundColor: "backgroundBrand",
          opacity: disabled ? 0.3 : 1,
          hoverColor: "backgroundTealHover",
        },
        text: {
          py: "xxs",
          px: "sm",
          color: "white",
        },
      },
      buttonFilledLight: {
        box: {
          px,
          py,
          borderRadius: "full",
          border: "none",
          backgroundColor: "backgroundSecondary",
          opacity: disabled ? 0.3 : 1,
          hoverColor: "backgroundSandHover",
        },
        text: {
          py: "xxs",
          px: "sm",
        },
      },
      buttonSmallFilledBrand: {
        box: {
          px: "sm",
          py: "xs",
          borderRadius: "full",
          border: "none",
          backgroundColor: "backgroundBrand",
          opacity: disabled ? 0.3 : 1,
          hoverColor: "backgroundTealHover",
        },
        text: {
          py: "xxs",
          color: "white",
        },
      },
      buttonSmallFilledLight: {
        box: {
          px: "sm",
          py: "xs",
          borderRadius: "full",
          border: "none",
          backgroundColor: "backgroundSecondary",
          opacity: disabled ? 0.3 : 1,
          hoverColor: "backgroundSandHover",
        },
        text: {
          py: "xxs",
          color: contentColor ? contentColor : "contentContrastHigh",
        },
      },
    } as Record<
      ButtonProps["variant"],
      {
        box?: Partial<BoxProps & { hoverColor: ColorThemeTokens }>;
        text?: Partial<TextProps>;
      }
    >
  )[variant]);

const getTextVariant = (
  variant: ReadonlyArrayValues<typeof ButtonVariants>,
) => {
  if (
    variant === "buttonSmallFilledBrand" ||
    variant === "buttonSmallFilledLight"
  ) {
    return "buttonSmall";
  }

  if (variant === "buttonFilledBrand" || variant === "buttonFilledLight") {
    return "buttonMedium";
  }

  return "buttonMedium";
};

export const Button = ({
  text,
  onClick = () => undefined,
  variant = "buttonFilledBrand",
  isLoading = false,
  disabled = false,
  iconName,
  contentColor,
  px = ["lg", "xl"],
  py = ["sm", "sm"],
  ...rest
}: ButtonProps) => {
  const _onClick = useCallback(
    (e: Event) => {
      e.preventDefault();
      if (!disabled && !isLoading) onClick();
    },
    [disabled, onClick],
  );

  const variantStyles = getVariantStyles({
    variant,
    isLoading,
    disabled,
    px,
    py,
    contentColor,
    ...rest,
  });

  const textVariant = getTextVariant(variant);

  return (
    <_Box
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      onClick={_onClick}
      style={{
        cursor: disabled || isLoading ? "not-allowed" : "pointer",
      }}
      as="button"
      disabled={disabled}
      {...variantStyles.box}
      {...rest}
    >
      {!isLoading ? (
        <Box
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="row"
          flex={1}
        >
          {iconName && <Icon size="imd" name={iconName} />}

          <Box whiteSpace="nowrap">
            <Text variant={textVariant} {...variantStyles.text}>
              {text}
            </Text>
          </Box>
        </Box>
      ) : (
        <LoadingIndicator />
      )}
    </_Box>
  );
};
