import React, { useMemo } from "react";

import { Discount, Plan } from "../../../../api";
import { Box, BoxProps, LoadingIndicator } from "../../../../ui";

import { PlanItem } from "../PlanItem";

export interface PlansListProps extends BoxProps {
  plans: (Plan & {
    discount?: Discount & {
      saleHeader?: string;
    };
  })[];
  selectedPlanId: string;
  onSelectPlan: (planId: string) => void;
  isLoading?: boolean;
}

const InnerPlansList: React.FC<PlansListProps> = ({
  plans,
  selectedPlanId,
  onSelectPlan,
  isLoading = false,
  ...props
}) => {
  const isActiveSale = useMemo(
    () => plans.some(({ discount }) => discount),
    [plans],
  );

  if (isLoading) return <LoadingIndicator size="large" />;

  return (
    <Box flexDirection="column" {...props}>
      {plans.map((plan, index) => (
        <Box
          marginBottom={index !== plans.length - 1 ? "sm" : "none"}
          key={index}
        >
          <PlanItem
            data-cy={`subscription-option-${index}`}
            plan={plan}
            discount={plan?.discount}
            saleHeader={plan?.discount?.saleHeader}
            showDescription={!isActiveSale}
            isSelected={selectedPlanId === plan._id}
            onPress={() => onSelectPlan(plan._id)}
          />
        </Box>
      ))}
    </Box>
  );
};

export const PlansList = InnerPlansList;
