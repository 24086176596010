/* eslint-disable operator-assignment */
/* eslint-disable no-param-reassign */
import { thunk } from "easy-peasy";
import axios from "axios";
import { logAxiosError } from "../../src/utils/utils";
import { KIC_API } from "../../src/api/index";

const actions = {
  uploadMedia: thunk(
    async (actions, payload, { getStoreActions, getStoreState }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const { file } = payload;
        const formData = new FormData();

        formData.append("image", file);

        const headers = {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        };

        const response = await axios.post(
          `${KIC_API}/api/v1/media/upload`,
          formData,
          { headers },
        );

        return response.data;
      } catch (error) {
        logAxiosError(error, "uploadMedia");
        return error.response.data;
      }
    },
  ),
};

export default actions;
