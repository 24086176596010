import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.0555 10.0555C10.5712 9.53973 11.2707 9.25 12 9.25C12.7293 9.25 13.4288 9.53973 13.9445 10.0555C14.4603 10.5712 14.75 11.2707 14.75 12C14.75 12.7293 14.4603 13.4288 13.9445 13.9445C13.4288 14.4603 12.7293 14.75 12 14.75C11.2707 14.75 10.5712 14.4603 10.0555 13.9445C9.53973 13.4288 9.25 12.7293 9.25 12C9.25 11.2707 9.53973 10.5712 10.0555 10.0555ZM12 10.75C11.6685 10.75 11.3505 10.8817 11.1161 11.1161C10.8817 11.3505 10.75 11.6685 10.75 12C10.75 12.3315 10.8817 12.6495 11.1161 12.8839C11.3505 13.1183 11.6685 13.25 12 13.25C12.3315 13.25 12.6495 13.1183 12.8839 12.8839C13.1183 12.6495 13.25 12.3315 13.25 12C13.25 11.6685 13.1183 11.3505 12.8839 11.1161C12.6495 10.8817 12.3315 10.75 12 10.75Z"
        fill="#181818"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.8806 12C6.14365 15.584 8.85254 17.25 12 17.25C15.1475 17.25 17.8564 15.584 20.1194 12C17.8564 8.41604 15.1475 6.75 12 6.75C8.85254 6.75 6.14365 8.41604 3.8806 12ZM2.35688 11.6141C4.84664 7.46452 8.05833 5.25 12 5.25C15.9417 5.25 19.1534 7.46452 21.6431 11.6141C21.7856 11.8516 21.7856 12.1484 21.6431 12.3859C19.1534 16.5355 15.9417 18.75 12 18.75C8.05833 18.75 4.84664 16.5355 2.35688 12.3859C2.21437 12.1484 2.21437 11.8516 2.35688 11.6141Z"
        fill="#181818"
      />
    </svg>
  );
}

export default SvgComponent;
