import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 18.4 18.4" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M14.36 4h-3.28a1.64 1.64 0 00-1.69 1.61 1.64 1.64 0 001.69 1.58h1a7.61 7.61 0 010 .88c-.09 1.07-.16 2 .95 5.64a2.43 2.43 0 01-.6 2.61 4.65 4.65 0 01-4.48.8 2.91 2.91 0 01-2-2.4 6.83 6.83 0 01.73-4.45A15.3 15.3 0 008.1 3.42h1.2A1.64 1.64 0 0011 1.84 1.64 1.64 0 009.3.26H6a1.64 1.64 0 00-1.67 1.58A1.64 1.64 0 006 3.42h1.1a14.34 14.34 0 01-1.39 6.44 7.78 7.78 0 00-.81 5 3.84 3.84 0 002.69 3.21 5.63 5.63 0 001.86.31A5.35 5.35 0 0013.1 17a3.42 3.42 0 00.9-3.58c-1.06-3.47-1-4.3-.91-5.27v-1h1.26a1.64 1.64 0 001.69-1.58A1.64 1.64 0 0014.36 4zm-9-2.19A.64.64 0 016 1.26h3.3a.64.64 0 01.69.58.64.64 0 01-.69.58H6a.64.64 0 01-.67-.58zm9 4.35h-3.28a.64.64 0 01-.69-.58.64.64 0 01.69-.58h3.28a.64.64 0 01.69.58.64.64 0 01-.69.61z" />
    </svg>
  );
}

export default SvgComponent;
