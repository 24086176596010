import { SendRequest } from "../makeSendRequest";
import { MobileSubscriptionPlan } from "./types";

export const createSubscriptionPlansApi = (sendRequest: SendRequest) => ({
  viewMobilePlan: (id: string) =>
    sendRequest<MobileSubscriptionPlan>({
      method: "get",
      path: "subscription-plans/view",
      version: "v3",
      params: { id },
    }),
  listMobilePlans: () =>
    sendRequest<MobileSubscriptionPlan[]>({
      method: "get",
      path: "subscription-plans/list-mobile",
      version: "v3",
    }),
  upsertMobilePlan: (mobileSubUpdate: Partial<MobileSubscriptionPlan>) =>
    sendRequest<MobileSubscriptionPlan>({
      method: "put",
      path: "subscription-plans/upsert-mobile-plan",
      version: "v3",
      data: mobileSubUpdate,
    }),
});
