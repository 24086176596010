import React, { useCallback } from "react";

import Icon, { IconProps } from "../../icons";
import { Box, BoxProps } from "../Box";
import { LoadingIndicator } from "../LoadingIndicator";

export const IconButtonVariants = [
  "buttonSmallFilledBrand",
  "buttonSmallFilledLight",
] as const;

export interface IconButtonProps extends BoxProps {
  onClick: () => void;
  variant?: ReadonlyArrayValues<typeof IconButtonVariants>;
  isLoading?: boolean;
  disabled?: boolean;
  iconName?: IconProps["name"];
}

const getVariantStyles = ({
  variant,
  disabled,
  px,
  py,
}: Partial<IconButtonProps>) =>
  ((
    {
      buttonSmallFilledBrand: {
        box: {
          px: px ? px : "sm",
          py: py ? py : "sm",
          borderRadius: "full",
          border: "none",
          backgroundColor: "backgroundBrand",
          opacity: disabled ? 0.3 : 1,
        },
      },
      buttonSmallFilledLight: {
        box: {
          px: px ? px : "sm",
          py: py ? py : "sm",
          borderRadius: "full",
          border: "none",
          backgroundColor: "backgroundSecondary",
          opacity: disabled ? 0.3 : 1,
        },
      },
    } as Record<IconButtonProps["variant"], { box?: Partial<BoxProps> }>
  )[variant]);

export const IconButton: React.FC<IconButtonProps> = ({
  onClick = () => undefined,
  variant = "buttonSmallFilledBrand",
  isLoading = false,
  disabled = false,
  iconName,
  px,
  py,
  ...rest
}) => {
  const _onClick = useCallback(
    (e: Event) => {
      e.preventDefault();
      if (!disabled && !isLoading) onClick();
    },
    [disabled, onClick],
  );

  const variantStyles = getVariantStyles({
    variant,
    isLoading,
    disabled,
    px,
    py,
    ...rest,
  });

  return (
    <Box
      flexDirection="row"
      alignItems="center"
      justifyContent="center"
      onClick={_onClick}
      style={{ cursor: disabled || isLoading ? "not-allowed" : "pointer" }}
      as="button"
      disabled={disabled}
      {...variantStyles.box}
      {...rest}
    >
      {!isLoading ? (
        <Box
          alignItems="center"
          justifyContent="center"
          display="flex"
          flexDirection="row"
          flex={1}
        >
          {iconName && <Icon size="imd" name={iconName} />}
        </Box>
      ) : (
        <LoadingIndicator />
      )}
    </Box>
  );
};
