import state, { AuthState } from "./auth.state";
import actions, { AuthActions } from "./auth.actions";
import computators from "./auth.computators";

export type EasyAuthModel = AuthState & AuthActions;

const authModel: EasyAuthModel = {
  ...state,
  ...actions,
  ...computators,
};

export default authModel;
