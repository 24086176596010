import { SendRequest } from "../makeSendRequest";
import { Challenge, ChallengeResponse } from "./types";
import { toChallenge } from "./utils";

export type UpdateSortOrdersPayload = {
  sortOrders: { [challengeId: string]: number };
};

export const createChallengeApi = (sendRequest: SendRequest) => ({
  list: async () =>
    (
      await sendRequest<ChallengeResponse[]>({
        method: "get",
        path: "challenge/list",
        version: "v3",
      })
    ).map((c) => toChallenge(c)),

  createOrUpdate: async (challenge: Partial<Challenge>) =>
    toChallenge(
      await sendRequest<ChallengeResponse>({
        method: "put",
        path: "challenge",
        version: "v3",
        data: challenge,
      }),
    ),

  updateSortOrders: async (updatePayload: UpdateSortOrdersPayload) =>
    (
      await sendRequest<ChallengeResponse[]>({
        method: "put",
        path: "challenge/sortOrders",
        version: "v3",
        data: updatePayload,
      })
    ).map((c) => toChallenge(c)),

  view: async (id: string) =>
    toChallenge(
      await sendRequest<ChallengeResponse>({
        method: "get",
        path: `challenge/${id}`,
        version: "v3",
      }),
    ),

  deleteChallenge: async (id: string) =>
    sendRequest<{ result: boolean }>({
      method: "delete",
      path: `challenge/${id}`,
      version: "v3",
    }),
});
