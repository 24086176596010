import React from "react";

function SvgComponent({ props }) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" {...props}>
      <path
        d="M6.99992 6.99984C5.38992 6.99984 4.08325 5.69317 4.08325 4.08317C4.08325 2.47317 5.38992 1.1665 6.99992 1.1665C8.60992 1.1665 9.91659 2.47317 9.91659 4.08317C9.91659 5.69317 8.60992 6.99984 6.99992 6.99984ZM6.99992 2.33317C6.03742 2.33317 5.24992 3.12067 5.24992 4.08317C5.24992 5.04567 6.03742 5.83317 6.99992 5.83317C7.96242 5.83317 8.74992 5.04567 8.74992 4.08317C8.74992 3.12067 7.96242 2.33317 6.99992 2.33317Z"
        fill="#212120"
      />
      <path
        d="M10.5001 12.8332C10.1792 12.8332 9.91675 12.5707 9.91675 12.2498V11.0832C9.91675 10.1207 9.12925 9.33317 8.16675 9.33317H5.83341C4.87091 9.33317 4.08341 10.1207 4.08341 11.0832V12.2498C4.08341 12.5707 3.82091 12.8332 3.50008 12.8332C3.17925 12.8332 2.91675 12.5707 2.91675 12.2498V11.0832C2.91675 9.47317 4.22341 8.1665 5.83341 8.1665H8.16675C9.77675 8.1665 11.0834 9.47317 11.0834 11.0832V12.2498C11.0834 12.5707 10.8209 12.8332 10.5001 12.8332Z"
        fill="#212120"
      />
    </svg>
  );
}

export default SvgComponent;
