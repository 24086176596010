import { refreshAuthToken } from "./fetch";
import { decodeToken, isTokenExpired } from "./jwt";
import { getCookie, setCookie } from "./storage";

export const TOKEN_EXPIRATION_BUFFER_SECONDS = 5 * 60;

export const getAuthTokens = () =>
  Promise.resolve(shouldTokenRefresh())
    .then(getLatestTokens)
    .then(decodeTokens)
    .then(updateCookies)
    .then(formatTokens);

const shouldTokenRefresh = () =>
  isTokenExpired(getCookie("auth"), TOKEN_EXPIRATION_BUFFER_SECONDS);

const getLatestTokens = (shouldRefresh: boolean) =>
  (shouldRefresh
    ? refreshAuthToken(getCookie("refresh"))
    : Promise.resolve({
        token: getCookie("auth"),
        refreshToken: getCookie("refresh"),
      })
  ).then((data) => ({ ...data, shouldRefresh }));

const decodeTokens = ({
  token,
  refreshToken,
  ...data
}: PromiseFunctionReturnType<typeof getLatestTokens>) => ({
  ...data,
  auth: {
    token,
    payload: decodeToken(token),
  },
  refresh: {
    token: refreshToken,
    payload: decodeToken(refreshToken),
  },
});

const updateCookies = ({
  shouldRefresh,
  ...data
}: PromiseFunctionReturnType<typeof decodeTokens>) => {
  if (shouldRefresh) {
    setCookie("auth", data.auth.token, data.auth.payload.expiry);
    setCookie("refresh", data.refresh.token, data.refresh.payload.expiry);
  }

  return data;
};

const formatTokens = ({
  auth,
  refresh,
}: PromiseFunctionReturnType<typeof updateCookies>) => ({
  auth: auth.token,
  refresh: refresh.token,
});
