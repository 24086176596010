import Cookies from "js-cookie";
import { getEnvConfig } from "./appConfig/envConfig";
import { notifyError } from "./monitoring";

const env = getEnvConfig();

const getFacebookPixelSdk = () => {
  return (window as any).fbq;
};

type PixelInitProps = {
  _id?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  allowTracking: boolean;
};

export const initPixel = ({
  _id,
  email,
  first_name,
  last_name,
  allowTracking,
}: PixelInitProps) => {
  try {
    if (allowTracking) grantConsent();
    else revokeConsent();

    const pixel = getFacebookPixelSdk();
    pixel("init", env.kic_web_pixel_id, {
      external_id: _id,
      em: email,
      fn: first_name,
      ln: last_name,
    });
    pixel("track", "PageView");

    return true;
  } catch (e) {
    console.log("PIXEL: Could not initialise pixel.");
    return false;
  }
};

export const revokeConsent = () => {
  try {
    const pixel = getFacebookPixelSdk();
    pixel("consent", "revoke");
    return true;
  } catch (e) {
    console.log("PIXEL: Could not revoke consent.");
    return false;
  }
};

export const grantConsent = () => {
  try {
    const pixel = getFacebookPixelSdk();
    pixel("consent", "grant");
    return true;
  } catch (e) {
    console.log("PIXEL: Could not grant consent.");
    return false;
  }
};

export const trackPixelEvent = (
  name: string,
  data?: Record<string, unknown>,
) => {
  try {
    const pixel = getFacebookPixelSdk();
    pixel("track", name, data);
    return true;
  } catch (e) {
    console.log(`PIXEL: Could not track "${name}" event.`);
    return false;
  }
};

export const getFbBrowserFingerprint = () => {
  try {
    return Cookies.get("_fbp");
  } catch (error) {
    notifyError(error);
  }
};

export const getFbClickFingerprint = () => {
  try {
    return Cookies.get("_fbc");
  } catch (error) {
    notifyError(error);
  }
};
