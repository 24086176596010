import { SendRequest } from "../makeSendRequest";

export const createDiscountApi = (sendRequest: SendRequest) => ({
  view: async (id: string) =>
    sendRequest({
      method: "get",
      path: `discounts/${id}`,
      version: "v1",
    }),
});
