import React from "react";
import styled from "styled-components";
import Modal, { Props as ReactModalProps } from "react-modal";

interface ReactModalAdapterProps extends ReactModalProps {
  className?: string;
}

const ReactModalAdapter: React.FC<ReactModalAdapterProps> = ({
  className = "react-modal",
  ...props
}) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  return (
    <Modal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  );
};

export const StyledModal = styled(ReactModalAdapter)<{
  theme?: { adminBorderRadius: string };
}>`
  &__overlay {
    backdrop-filter: blur(0px);
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4rem;
    transition: ease 0.5s;
    z-index: 10;

    &.ReactModal__Overlay--after-open {
      background-color: rgba(0, 0, 0, 0.25);
      backdrop-filter: blur(2px);
    }
    &.ReactModal__Overlay--before-close {
      background-color: rgba(0, 0, 0, 0);
      backdrop-filter: blur(0px);
    }
  }

  &__content {
    border-radius: ${(props) => props.theme?.adminBorderRadius};
    display: flex;
    flex-direction: column;
    outline: none;
    overflow: hidden;
    opacity: 0;
    transition: ease 0.5s;

    &.ReactModal__Content--after-open {
      opacity: 1;
    }
    &.ReactModal__Content--before-close {
      opacity: 0;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 1rem; */
`;
