import { endOfWeek, startOfWeek, subWeeks } from "date-fns";
import jwt from "jsonwebtoken";
import Router from "next/router";

export const logAxiosError = (error, functionName) => {
  const { response, request, message, config } = error;

  console.log(`***** ${functionName} error *****`);

  if (response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log("AXIOS::ERROR:STATUS", response.status);
    console.log("AXIOS::ERROR::DATA", response.data);
    console.log("AXIOS::ERROR::HEADERS", response.headers);
  }
  if (request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log("AXIOS::ERROR::REQUEST", request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log("AXIOS::ERROR::MESSAGE", message);
  }

  if (config) {
    console.log("CONFIG", config);
  }
};

export const getTokenExpiryEpoch = (token, subtract = 0) => {
  const { exp } = jwt.decode(token);
  return exp - subtract;
};

export const isObjectEmpty = (obj) =>
  !obj || (Object.entries(obj).length === 0 && obj.constructor === Object);

export const getFormattedDate = (epoch) => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const hours = [
    12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
    11,
  ];
  const dateObj = new Date(epoch * 1000);
  const year = dateObj.getFullYear();
  const monthIndex = dateObj.getMonth();
  const month = months[monthIndex];
  const date = dateObj.getDate();
  const dayIndex = dateObj.getDay();
  const day = days[dayIndex];
  const hourIndex = dateObj.getHours();
  const hour = hours[hourIndex];
  const minute = dateObj.getMinutes();
  const dayPeriod = hourIndex > 11 ? "pm" : "am";

  return {
    year,
    month,
    date,
    day,
    monthIndex,
    hour,
    hourIndex,
    minute,
    dayPeriod,
  };
};

export const displayWeek = (amountOfWeeks) => {
  const start = getFormattedDate(
    startOfWeek(subWeeks(new Date(), amountOfWeeks), {
      weekStartsOn: 1,
    }).getTime() / 1000,
  );
  const end = getFormattedDate(
    endOfWeek(subWeeks(new Date(), amountOfWeeks), {
      weekStartsOn: 1,
    }).getTime() / 1000,
  );
  return `${start.month} ${start.date} - ${end.date}`;
};

export const hasEpochExpired = (expiryEpoch, advance = 0) => {
  if (!expiryEpoch) {
    return true;
  }

  const today = new Date().getTime() + advance;
  const expiryEpochMS = expiryEpoch * 1000;

  return expiryEpochMS < today;
};

export const reducer = (prevState, updatedProperty) => ({
  ...prevState,
  ...updatedProperty,
});

export const deleteUnusedKeys = (object) => {
  for (const key in object) {
    if (!object[key] || object[key].length === 0) {
      delete object[key];
    }
  }

  return object;
};

export function makeRedirect(ctx, toUrl, configs = {}) {
  const { req, res } = ctx;
  const { noNextUrl, shallow, dynamicRoute } = configs;
  const isServer = !!req;
  if (isServer) {
    res.writeHead(302, {
      Location: noNextUrl ? toUrl : `${toUrl}?next=${req.url}`,
    });
    res.end();
  } else {
    dynamicRoute
      ? Router.push(dynamicRoute.pathname, dynamicRoute.asPath, { shallow })
      : Router.push(noNextUrl ? toUrl : `${toUrl}?next=${ctx.asPath}`, toUrl, {
          shallow,
        });
  }
}

export const hasRoleAccess = (roles, strength = 5) => {
  if (!roles || roles.length === 0) {
    return false;
  }

  const orderedRoles = roles.sort((a, b) => b.strength - a.strength);

  return orderedRoles[0].strength >= strength;
};

export const isAdminPage = (pathname) => {
  const slicedString = pathname.slice(0, 7);
  return pathname === "/admin" || slicedString === "/admin/";
};
