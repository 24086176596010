import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 640 512" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M624 232H480c-9.3 0-17.76 5.37-21.72 13.79l-35.78 76.04-79.28-303.89C340.47 7.36 330.91 0 320 0c-.19 0-.41 0-.62.02-11.12.28-20.62 8.2-22.88 19.12l-75.84 366.52-37.53-136.03c-2.87-10.41-12.35-17.62-23.15-17.62H16c-8.84 0-16 7.16-16 16v16c0 8.84 7.16 16 16 16h125.72l59.16 214.38A23.974 23.974 0 00224 512c.25 0 .53 0 .78-.02 11.09-.36 20.47-8.27 22.72-19.12l75.19-363.44 70.09 268.64c2.53 9.77 10.94 16.91 21 17.83 10.5 1.14 19.62-4.53 23.94-13.67L495.22 280H624c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16z" />
    </svg>
  );
}

export default SvgComponent;
