import { useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";

const CHECKOUT_REFERRAL_QUERY_KEY = "checkoutReferralQuery";

type ReferralParams = {
  campaignId?: string;
  referrerEmail?: string;
  next?: string;
};

export const useReferralParam = () => {
  const hasHydratedStorage = useStoreRefferalParams();
  const [params, setParams] = useState<ReferralParams>({});

  useEffect(() => {
    const referralParams =
      JSON.parse(window.sessionStorage.getItem(CHECKOUT_REFERRAL_QUERY_KEY)) ??
      {};
    setParams(referralParams);
  }, [hasHydratedStorage]);

  const clearParams = () =>
    window.sessionStorage.removeItem(CHECKOUT_REFERRAL_QUERY_KEY);

  return { ...params, clearParams };
};

const useStoreRefferalParams = () => {
  const router = useRouter();
  const [hasHydratedStorage, setHasHydratedStorage] = useState(false);

  const referralCampaignId = useMemo(() => {
    if (router.query.campaignId && !Array.isArray(router.query.campaignId)) {
      return router.query.campaignId;
    }
  }, [router.query]);

  const referrerEmail = useMemo(() => {
    if (
      router.query.referrerEmail &&
      !Array.isArray(router.query.referrerEmail)
    ) {
      return router.query.referrerEmail;
    }
  }, [router.query]);

  useEffect(() => {
    if (referrerEmail && referralCampaignId) {
      window.sessionStorage.setItem(
        CHECKOUT_REFERRAL_QUERY_KEY,
        JSON.stringify(router.query),
      );
    }
    setHasHydratedStorage(true);
  }, [referrerEmail, referralCampaignId]);

  return hasHydratedStorage;
};
