import axios from "axios";
/* eslint-disable no-param-reassign */
import { action, Thunk, thunk, Action } from "easy-peasy";
import { StoreModel } from "..";

import { KIC_API } from "../../src/api/index";
import parseIntroStart from "../../src/utils/parseIntroStart";
import { logAxiosError } from "../../src/utils/utils";
import { ProfileState } from "./profile.state";
import { EasyProfileModel } from "./profile.store";

type ProfileModelThunk<Payload> = Thunk<
  EasyProfileModel,
  Payload,
  any,
  StoreModel
>;

export type ProfileActions = {
  fetchUserProfile: ProfileModelThunk<any>;
  setAllowTracking: Action<EasyProfileModel>;
  setShownConsent: Action<EasyProfileModel>;
  setUserData: Action<EasyProfileModel, ProfileState["userData"]>;
  updateUserData: Action<EasyProfileModel>;
  clearUserSessions: ProfileModelThunk<any>;
  updateUserProfile: ProfileModelThunk<any>;
  updateUserPassword: ProfileModelThunk<any>;
};

const actions: ProfileActions = {
  fetchUserProfile: thunk<EasyProfileModel, any, any, StoreModel>(
    async (actions, payload, { getStoreState, getStoreActions }) => {
      let token;
      if (!payload.token) {
        await getStoreActions().auth.checkTokenExpiration();
        token = getStoreState().auth.authData.token;
      } else {
        token = payload.token;
      }

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(`${KIC_API}/api/v2/users/profile`, {
          headers,
        });

        const {
          data: { data },
        } = response;

        actions.setUserData({
          ...data,
          meal_type: data.meal_type?.name === null ? [] : data.meal_type,
          difficulty: parseIntroStart({ date: data.intro_start })?.difficulty,
        });

        return data;
      } catch (error) {
        logAxiosError(error, "fetchUserProfile");
        return error.response.data;
      }
    },
  ),
  setAllowTracking: action<EasyProfileModel, any>((state, payload) => {
    state.allowTracking = payload;
  }),
  setShownConsent: action<EasyProfileModel, any>((state, payload) => {
    state.hasShownConsent = payload;
  }),
  setUserData: action<EasyProfileModel, any>((state, payload) => {
    state.userData = payload;
  }),
  updateUserData: action<EasyProfileModel, any>((state, payload) => {
    const mergedUserData = { ...state.userData, ...payload };

    state.userData = {
      ...mergedUserData,
      meal_type:
        mergedUserData.meal_type?.name === null ? [] : mergedUserData.meal_type,
    };
  }),
  clearUserSessions: thunk<EasyProfileModel, any, any, StoreModel>(
    async (actions, payload, { getStoreState, getStoreActions }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.get(
          `${KIC_API}/api/v2/users/clear_sessions`,
          { headers },
        );

        actions.updateUserData(response.data);

        return response.data;
      } catch (error) {
        logAxiosError(error, "clearUserSessions");
        return error.response.data;
      }
    },
  ),
  updateUserProfile: thunk<EasyProfileModel, any, any, StoreModel>(
    async (actions, payload, { getStoreState, getStoreActions }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token, id } = getStoreState().auth.authData;
      if (!id || !token) {
        console.log("Skipping updateUserProfile() due to missing authData");
        return;
      }

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.put(
          `${KIC_API}/api/v2/users/${id}`,
          payload,
          { headers },
        );

        actions.updateUserData(response.data);

        return response.data;
      } catch (error) {
        logAxiosError(error, "updateUserProfile");
        return error.response.data;
      }
    },
  ),
  updateUserPassword: thunk<EasyProfileModel, any, any, StoreModel>(
    async (actions, payload, { getStoreState, getStoreActions }) => {
      await getStoreActions().auth.checkTokenExpiration();

      const { token } = getStoreState().auth.authData;

      try {
        const headers = { Authorization: `Bearer ${token}` };

        const response = await axios.post(
          `${KIC_API}/api/v2/users/change_password`,
          payload,
          { headers },
        );

        return response.data;
      } catch (error) {
        // logAxiosError(error, "updateUserPassword");
        return error.response.data;
      }
    },
  ),
};

export default actions;
