import React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 512 512" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M510.419 357.056c-1.963-3.136-5.397-5.056-9.088-5.056H10.665c-3.691 0-7.125 1.92-9.067 5.056a10.625 10.625 0 00-.469 10.368C16.105 397.397 46.206 416 79.699 416h352.597c33.472 0 63.573-18.603 78.592-48.555 1.622-3.328 1.473-7.232-.469-10.389zM285.246 140.885c1.749-3.947 2.752-8.299 2.752-12.885 0-17.643-14.357-32-32-32s-32 14.357-32 32c0 4.587 1.003 8.939 2.752 12.885C130.089 154.176 53.758 232 43.966 330.667h424.043c-9.792-98.624-86.144-176.491-182.763-189.782zm-39.211 61.76c-59.669 3.947-111.061 42.901-130.88 99.243-1.536 4.373-5.675 7.125-10.069 7.125-1.173 0-2.368-.192-3.541-.597-5.547-1.963-8.469-8.043-6.528-13.611 22.656-64.427 81.387-108.949 149.611-113.451 5.653-.363 10.944 4.053 11.349 9.941.384 5.889-4.054 10.966-9.942 11.35z" />
    </svg>
  );
}

export default SvgComponent;
