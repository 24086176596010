import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { media } from "./mediaQueries";

Modal.setAppElement("body");

export const Container = styled.div`
  flex: 1;
`;

export const ProfileContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Title = styled.h1`
  color: ${(props) => props.theme.primaryFontColour};
  font-size: 2rem;
  font-weight: 600;
  margin: 0;
  text-transform: capitalize;

  ${(props) =>
    props.center &&
    `
    text-align: center;
  `}

  ${media.mobileL`
    font-size: 2.7rem;
  `};

  ${media.tablet`
    font-size: 3.6rem;
  `};
`;

export const Text = styled.p`
  color: ${(props) => props.theme.primaryFontColour};
  font-size: 1.2rem;
  margin: 0;
  text-align: justify;
  font-weight: ${({ bold }) => (!bold ? "regular" : "bold")};
  ${(props) =>
    props.center &&
    `
    text-align: center;
  `}
  ${media.mobileL`
    font-size: 1.4rem;
  `};

  ${media.tablet`
    font-size: 1.6rem;
  `};
`;

export const Input = styled.input`
  border: 1px solid ${(props) => props.theme.inputBorderColour};
  border-radius: ${(props) => props.theme.borderRadius};
  font-size: 1.6rem;
  padding: 1.6rem;
  outline: none;
  width: 100%;

  ${(props) =>
    props.padding &&
    `
    padding: ${props.padding};
  `};

  ${(props) =>
    props.disabled &&
    `
      background: #f2f2f2;
  `}
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 3rem 0 0 0;
`;

export const InputWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

/** ********* Modal styles ************/

export const ReactModalAdapter = ({ className, ...props }) => {
  const contentClassName = `${className}__content`;
  const overlayClassName = `${className}__overlay`;
  return (
    <Modal
      portalClassName={className}
      className={contentClassName}
      overlayClassName={overlayClassName}
      {...props}
    />
  );
};
