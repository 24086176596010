import { useRouter } from "next/router";
import React, { useMemo, useState } from "react";
import { a, useSpring } from "react-spring";

import { useMeasure, usePrevious } from "../../../../hooks/customHooks";
import { Content, Frame, StyledIcon, Title, Wrapper } from "./styles";

const Tree = ({
  children,
  name,
  style,
  defaultOpen = false,
  icon,
  route,
  asRoute,
  small,
  disableLink,
}) => {
  const router = useRouter();
  const [isOpen, setOpen] = useState(defaultOpen);
  const previous = usePrevious(isOpen);
  const [bind, { height: viewHeight }] = useMeasure();
  const { height, opacity, transform } = useSpring({
    from: { height: 0, opacity: 0, transform: "translate3d(20px,0,0)" },
    to: {
      height: isOpen ? viewHeight : 0,
      opacity: isOpen ? 1 : 0,
      transform: `translate3d(${isOpen ? 0 : 20}px,0,0)`,
    },
  });

  const selector = () => {
    if (route === "/admin") {
      return router.pathname === route;
    }

    return router.asPath.includes(asRoute || route);
  };

  const selected = useMemo(() => selector(), [router]);

  const onClick = () => {
    if (route && !disableLink) {
      router.push(route, asRoute);
    } else {
      setOpen(!isOpen);
    }
  };

  return (
    <Frame>
      <Wrapper onClick={() => onClick()}>
        <StyledIcon name={icon} small={small} selected={selected} />
        <Title style={style} small={small} selected={selected}>
          {name}
        </Title>
      </Wrapper>
      <Content
        style={{
          opacity,
          height: isOpen && previous === isOpen ? "auto" : height,
        }}
      >
        <a.div style={{ transform }} {...bind}>
          {children}
        </a.div>
      </Content>
    </Frame>
  );
};

export default React.memo(Tree);
