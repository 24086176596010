const themes = {
  light: {
    borderRadius: "0.4rem",

    // Colours
    brandColor: "#8DE0D6",
    brandColour: "#8DE0D6",
    errorRed: "#890301",
    successGreen: "#4fbe79",
    textSuccessGreen: "#3c995f",
    facebookBlue: "#3b5998",
    appleBlack: "#000000",

    // General fonts
    fontSizeSmall: "1.5rem",
    primaryFontColour: "#292d31",
    secondaryFontColour: "#7f8183",
    linkTextColour: "#0073aa",
    headingFontFamily: '"Ttrounds", sans-serif',
    CTAButtonFont: '"Ttrounds", sans-serif',

    // Icons
    iconColour: "#292d31",

    // Borders
    borderColour: "#d4d4d4",

    // Subscriptions
    primaryBannerBackground: "#f96dbc",

    // Inputs
    inputBorderColour: "#d4d4d4",

    /**
     *
     *  Auth Styles
     *
     * **** */

    authBorderColour: "#dbdbdb",
    authIconColour: "#292d31",

    // Inputs
    authInputBorderColour: "#cacacb",

    // Buttons
    CTAButtonBorderRadius: "50rem",
    CTAButtonPrimaryColour: "#2FDCC3",
    CTAButtonPrimaryTextColour: "#ffffff",
    CTAButtonSecondaryColour: "#aaabad",
    CTAButtonSecondaryTextColour: "#fffffff",

    /**
     *
     *  Admin Styles
     *
     * **** */

    adminBlue: "#0085ba",
    adminTitleColour: "#292d31",
    headerBackground: "#23282d",
    containerBorderColour: "#E1E1E1",
    adminBorderRadius: ".4rem",
    adminIconColour: "#292d31",
    shadow: "0 4px 8px 0 rgba(0, 0, 0, 0.15), 0 6px 20px 0 rgba(0, 0, 0, 0.05)",

    // Side Bar
    sideBarBackground: "#23282d",
    sideBarDropDownBackground: "#32373c",
    sideBarItemTextColour: "#f0f5fab3",
    sideBarItemSelectedTextColour: "#2FDCC3",
    selectedSideBarItemBackground: "#0273a9",

    // Inputs
    focusInputBlue: "#2684ff",

    adminCTAButtonPrimaryColour: "#0085ba",
    adminCTAButtonPrimaryTextColour: "#ffffff",
    adminCTAButtonHoverPrimaryColour: "#008ec2",
    adminCTAButtonPrimaryBorderColour: "#0073aa #006799 #006799",
    adminCTAButtonPrimaryShadowColour: "#006799",
    adminCTAButtonPrimaryTextShadow:
      "0 -1px 1px #006799, 1px 0 1px #006799, 0 1px 1px #006799, -1px 0 1px #006799",

    adminCTAButtonSecondaryColour: "#dedede",
    adminCTAButtonSecondaryTextColour: "#555555",
    adminCTAButtonHoverSecondaryColour: "#f7f7f7",
    adminCTAButtonSecondaryBorderColour: "#cccccc",
    adminCTAButtonSecondaryShadowColour: "#ccc",
    adminCTAButtonSecondaryTextShadow: "none",

    adminCTAButtonCareColour: "#ba0000",
    adminCTAButtonCareTextColour: "#ffffff",
    adminCTAButtonHoverCareColour: "#c20000",
    adminCTAButtonCareBorderColour: "#aa0000 #990000 #990000",
    adminCTAButtonCareShadowColour: "#990000",
    adminCTAButtonCareTextShadow:
      "0 -1px 1px #990000, 1px 0 1px #990000, 0 1px 1px #990000, -1px 0 1px #990000",

    adminAddNewButtonTextColour: "#0073aa",
    adminAddNewButtonBorder: "#ccc",
    adminAddNewButtonBackground: "#f7f7f7",
    adminAddNewButtonHoverTextColour: "#ffffff",
    adminAddNewButtonHoverBackground: "#00a0d2",
    adminAddNewButtonHoverBorder: "#008EC2",

    adminBackground: "#f6f9fc",
  },
};

export { themes };
