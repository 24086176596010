import { useWindowSize } from "./customHooks";

export const useIsMobile = () => {
  const isMobile = useWindowSize().width < 768;
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);

  return {
    isMobile,
    isIOS,
    isAndroid,
  };
};
