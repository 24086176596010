import { SendRequest } from "../makeSendRequest";
import {
  PopulatedHomeScreenSection,
  HomeSectionEditInput,
  Section,
} from "./types";

export const createHomeScreenSectionApi = (sendRequest: SendRequest) => ({
  update: async (data: HomeSectionEditInput) => {
    const response = await sendRequest<{
      success: true;
    }>({
      method: "put",
      path: `homepage-section`,
      version: "v3",
      data,
    });

    return response;
  },
  findOneBySection: async (section: Section) => {
    const response = await sendRequest<PopulatedHomeScreenSection>({
      method: "get",
      path: `homepage-section/${section}`,
      version: "v3",
    });

    return response;
  },
});
