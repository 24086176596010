import Link from "next/link";
import React from "react";

import {
  BottomContainer,
  Container,
  StoreLink,
  InnerContainer,
  Label,
  Section,
  StyledIcon,
  Text,
  Title,
  Wrapper,
} from "./styles";
import Icon from "../../../icons";
import { useAppConfig } from "../../../utils/appConfig/AppConfigProvider";

const Footer = () => {
  const year = new Date().getFullYear();
  const appConfig = useAppConfig();

  return (
    <Container>
      <InnerContainer>
        <Section>
          <StyledIcon name="kic-icon" big="true" />
        </Section>
        <Section>
          <Title>Join the community!</Title>
          <Label href={appConfig.instagram_url} target="_blank">
            <StyledIcon name="instagram" />
            <Text>Instagram</Text>
          </Label>
          <Label href={appConfig.facebook_url} target="_blank">
            <StyledIcon name="facebook-square" />
            <Text>Facebook</Text>
          </Label>
          <Label href={appConfig.youtube_url} target="_blank">
            <StyledIcon name="youtube" />
            <Text>YouTube</Text>
          </Label>
        </Section>
        <Section>
          <Title>Support</Title>
          <Link href="/faqs">
            <Label>
              <Text>FAQs</Text>
            </Label>
          </Link>
          <Link href="/gift-cards">
            <Label>
              <Text>Gift Cards</Text>
            </Label>
          </Link>
          <Link href="/contact-us">
            <Label>
              <Text>Contact Us</Text>
            </Label>
          </Link>
        </Section>
      </InnerContainer>
      <InnerContainer marginTop>
        <BottomContainer>
          <div>
            <StoreLink
              href="https://itunes.apple.com/us/app/keep-it-cleaner/id1438803205?ls=1&mt=8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="app-store-button" width={120} />
            </StoreLink>
            <StoreLink
              href="https://play.google.com/store/apps/details?id=com.kic.kicapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="play-store-button" width={135} />
            </StoreLink>
          </div>
          <Wrapper>
            <Link href="/legal">
              <Text pointer>Legal &nbsp;</Text>
            </Link>
            <Text>{` | © ${year} Kic App Pty Ltd. All Rights Reserved.`}</Text>
          </Wrapper>
        </BottomContainer>
      </InnerContainer>
    </Container>
  );
};

export default React.memo(Footer);
