import React from "react";
import { useRouter } from "next/router";

import { Button } from "../../../ui";
import { OnboardingHeader } from "../OnboardingHeader";

export const UnAuthenticatedHeader: React.FC = () => {
  const router = useRouter();
  const isLoginRoute = /^\/login$/.test(router.pathname);

  return (
    <OnboardingHeader>
      <Button
        mr="xs"
        variant="buttonSmallFilledBrand"
        onClick={() => {
          isLoginRoute ? router.push("/onboarding") : router.push("/login");
        }}
        text={isLoginRoute ? "Sign up" : "Log in"}
      />
    </OnboardingHeader>
  );
};
