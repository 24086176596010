export const bumpMedicalCheckCopy = {
  prenatal: {
    header: `Hey Mumma, our number one priority is to make sure that you and your baby are safe while completing our KICBUMP prenatal Pilates program. Before we get started please confirm:`,
    markdown: null,
    consent1:
      "I have received clearance from my healthcare professional to start KICBUMP's prenatal Pilates program and have been advised on the physical warning signs to cease exercise.",
    consent2:
      "I will continue to follow the advice of my doctor / obstetrician and modify or pause my exercise routine when recommended.",
    emailOptIn:
      "I would love to receive notifications and emails about the KICBUMP program, please keep me in the loop!",
  },
  postnatal: {
    header: `Hey Mumma, congratulations! Your body has gone through some incredible changes, so before we get started on KICBUMP's postnatal Pilates program please confirm the below:`,
    markdown: `Note! Our postnatal Pilates is a little different to our usual Kic sessions! \n- This is a 10-week program \n- Our sessions are developed to be completed in order from session 1 to 10 \n- Our experts recommend completing each session 3-4 times before moving onto the next.\n\nNo matter where you are on your postpartum journey we recommend beginning from session 1.`,
    consent1:
      "I have attended my six-week postpartum checkup with my healthcare professional and have received clearance to commence exercise.",
    consent2:
      "I will continue to follow the advice of my doctor / obstetrician and modify or pause my exercise routine when recommended.",
    emailOptIn:
      "I would love to receive notifications and emails about the KICBUMP program, please keep me in the loop!",
  },
};
