import { KIC_API } from "../../../src/api/index";
import addToPosts from "../../../src/utils/actionModels/addToPosts";
import createPost from "../../../src/utils/actionModels/createPost";
import fetchPost from "../../../src/utils/actionModels/fetchPost";
import fetchPosts from "../../../src/utils/actionModels/fetchPosts";
import removeFromPosts from "../../../src/utils/actionModels/removeFromPosts";
import removePost from "../../../src/utils/actionModels/removePost";
import setState from "../../../src/utils/actionModels/setState";
import updatePost from "../../../src/utils/actionModels/updatePost";
import parser from "../../../src/utils/parsersForEndpoints/masterclass";

const endpoint = `${KIC_API}/api/v2/post/masterclasses`;

const actions = {
  // Actions
  addToPosts: addToPosts(),
  removeFromPosts: removeFromPosts(),
  setState: setState(),

  // Thunks
  createMasterclass: createPost({ key: "createMasterclass", endpoint, parser }),
  fetchMasterclass: fetchPost({ key: "fetchMasterclass", endpoint }),
  fetchMasterclasses: fetchPosts({ key: "fetchMasterclasses", endpoint }),
  removeMasterclass: removePost({ key: "removeMasterclass", endpoint }),
  updateMasterclass: updatePost({ key: "updateMasterclass", endpoint, parser }),
};

export default actions;
