const trainerParserForApi = (data) => {
  const {
    _id,
    photo,
    signature,
    cover_photo: coverPhoto,
    card_photo: cardPhoto,
    avatar,
    ...rest
  } = data;

  const signatureUrl = signature?.url || null;
  const photoUrl = photo?.url || null;
  const coverPhotoUrl = coverPhoto?.url || null;
  const cardPhotoUrl = cardPhoto?.url || null;
  const avatarPhotoUrl = avatar?.url || null;

  const body = {
    ...rest,
    signature: signatureUrl,
    photo: photoUrl,
    cover_photo: coverPhotoUrl,
    card_photo: cardPhotoUrl,
    avatar: avatarPhotoUrl,
  };
  return {
    _id,
    body,
    data,
  };
};

export default trainerParserForApi;
