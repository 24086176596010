import { fromPairs } from "lodash";
import FlagProvider from "@unleash/proxy-client-react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useUser } from "../../hooks";
import { parseToggle, unleashClient } from "./unleash";
import { logUserAttribute, notifyError } from "../../utils/monitoring";
import { useLogFeatureFlagConfiguration } from "../Users/hooks/useLogFeatureFlagConfiguration";

const TogglesReadyContext = React.createContext(false);

export const useTogglesReady = () => {
  const togglesReadyContext = useContext(TogglesReadyContext);

  if (togglesReadyContext === undefined) {
    notifyError(
      "useTogglesReadyContext has to be used within <ToggleProvider>",
    );
  }

  return togglesReadyContext;
};

export const ToggleProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const user = useUser();

  const logFeatureFlagConfiguration = useLogFeatureFlagConfiguration();

  const [unleashStarted, setUnleashStarted] = useState(false);
  const [togglesReady, setTogglesReady] = useState(
    (unleashClient as any).started, // extra hack to not break on fast refresh
  );

  const unleashContext = useMemo(
    () => ({
      userId: user._id,
    }),
    [user._id],
  );

  const startUnleash = useCallback(async () => {
    await unleashClient.updateContext(unleashContext);
    await unleashClient.start();
    setTogglesReady(true);
  }, [unleashContext, unleashClient]);

  useEffect(() => {
    if (!unleashStarted) {
      setUnleashStarted(true);
      startUnleash();
    }
  }, [startUnleash]);

  useEffect(() => {
    if (togglesReady) {
      unleashClient.updateContext(unleashContext);

      const featureFlags = fromPairs(
        unleashClient
          .getAllToggles()
          .map((toggle) => [toggle.name, parseToggle(toggle)]),
      ) as unknown as JSON;

      logUserAttribute("FeatureFlags", featureFlags);
      if (user._id) logFeatureFlagConfiguration.mutate(featureFlags);
    }
  }, [togglesReady, unleashContext, unleashClient, user?._id]);

  return (
    <TogglesReadyContext.Provider value={togglesReady}>
      <FlagProvider unleashClient={unleashClient} startClient={false}>
        {children}
      </FlagProvider>
    </TogglesReadyContext.Provider>
  );
};
