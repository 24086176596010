import React from "react";
import styled from "styled-components";
import { animated } from "react-spring";

const Container = styled(animated.div)`
  margin: 0 0 2.5rem 0;

  &:last-child {
    margin: 0;
  }
`;

const Label = styled.p`
  color: ${(props) => props.theme.primaryFontColour};
  font-size: 1.7rem;
  font-weight: 600;
  margin: 0;
  text-decoration: none;
  text-transform: capitalize;
`;

const Item = ({
  label,
  external,
  asPath,
  route,
  toggleMenu,
  router,
  style,
}) => {
  const onClick = () => {
    toggleMenu();
    router.push(route, asPath);
  };

  return (
    <Container style={style}>
      {external ? (
        <Label as="a" href={route} target="_blank" onClick={toggleMenu}>
          {label}
        </Label>
      ) : (
        <Label onClick={onClick}>{label}</Label>
      )}
    </Container>
  );
};

export default React.memo(Item);
