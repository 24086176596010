import React from "react";

import { css } from "@styled-system/css";
import { Image } from "../Image/Image";
import { Box, BoxProps } from "../Box/Box";
import { Tag } from "./Tag";

type TileHeroProps = {
  imageSrc: string;
  tags?: string[];
  itemInFocus: boolean;
} & BoxProps;
export const TileHero: React.FC<TileHeroProps> = (props) => {
  return (
    <Box overflow="hidden" {...props}>
      <Image
        src={props.imageSrc}
        height="100%"
        width="100%"
        css={{ objectFit: "cover" }}
      />

      <Box
        position="absolute"
        m="xs"
        flexDirection="row"
        gap="xs"
        opacity={props.itemInFocus ? "1" : "0"}
        css={css({
          transition: "all 0.2s ease-in-out",
        })}
      >
        {(props.tags ?? []).map((tag) => (
          <Tag key={tag} title={tag} />
        ))}
      </Box>
    </Box>
  );
};
