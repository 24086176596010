import React, { useMemo } from "react";
import {
  BorderProps,
  ColorProps,
  LayoutProps,
  SpaceProps,
} from "styled-system";

import { Box } from "../Box";

import { Theme } from "../theme";
import { useInputTextStyles } from "./useInputTextStyles";
import { InputTextIconAdornment } from "./InputTextIconAdornment";
import { InputTextErrorAdornment } from "./InputTextErrorIconAdornment";
import { _Input, makeErrorInputStyle } from "./BaseInputText";

export type ReactInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type InputTextProps = {
  hasError?: boolean;
  endAdornment?: React.ReactNode;
} & SpaceProps<Theme> &
  LayoutProps<Theme> &
  ColorProps<Theme> &
  BorderProps<Theme> &
  Omit<ReactInputProps, "style"> &
  TestIDProp;

export const InputText: React.FC<InputTextProps> = ({
  hasError,
  endAdornment,
  "data-cy": testId,
  ...props
}) => {
  const isErrorState = !!hasError;

  const endAdornmentComponent = useMemo(() => {
    if (endAdornment) {
      return endAdornment;
    } else if (isErrorState) {
      return <InputTextErrorAdornment />;
    } else return null;
  }, [isErrorState, endAdornment]);

  const { boxStyles, inputStyles } = useInputTextStyles(props);

  return (
    <Box
      justifyContent="center"
      position="relative"
      flexGrow={1}
      {...boxStyles}
    >
      <_Input
        css={isErrorState ? makeErrorInputStyle : null}
        data-cy={testId}
        {...inputStyles}
      />
      {endAdornmentComponent && (
        <InputTextIconAdornment>{endAdornmentComponent}</InputTextIconAdornment>
      )}
    </Box>
  );
};
