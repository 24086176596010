import styled from "styled-components";

import Icon from "../../../icons";
import { media } from "../../../styles/mediaQueries";

export const Container = styled.div`
  background: ${(props) => props.theme.sideBarBackground};
  display: flex;
  flex-direction: row;
  padding: 1rem 1rem 1rem 1rem;
  position: absolute;
  z-index: 1;

  ${(props) =>
    props.open &&
    `
    flex-direction: column;
    min-height: 100vh;
  `};

  ${media.mobileL`
    flex-direction: column;
    min-height: 100vh;
    padding: 1rem 1rem 1rem 1rem;
    position: relative;
    z-index: 0;
  `}

  ${media.tablet`
    min-height: 100vh;
    padding: 2rem 1rem 1rem 1rem;
    position: relative;
  `}
`;

export const Brand = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 0 0 1rem;

  ${(props) =>
    props.open &&
    `
    margin: 1rem 0 3rem 0;
  `};

  ${media.mobileL`
    margin: 0 0 3rem 0;
  `}
`;

export const Wrapper = styled.div`
  display: none;
  margin: 0 0 2rem 0;

  ${(props) =>
    props.open &&
    `
    display: block;
  `};

  ${media.mobileL`
    display: block;
  `}
`;

export const StyledIcon = styled(Icon)`
  fill: ${(props) => props.theme.brandColour};
  height: 2rem;
  width: 2rem;

  ${(props) =>
    props.menubutton &&
    `
    fill: ${props.theme.sideBarItemTextColour}
  `};

  ${media.mobileL`
    ${(props) =>
      props.menubutton &&
      `
      display: none;
    `}  
  `}

  ${media.tablet`
    height: 3rem;
    margin: 0 1rem 0 0;
    width: 3rem;
  `}
`;

export const Text = styled.div`
  color: ${(props) => props.theme.brandColour};
  display: none;
  font-size: 1.5rem;
  font-weight: 800;

  ${media.tablet`
    display: block;
    font-size: 1.3rem;
  `}
`;

export const Separator = styled.div`
  border-top: 1px solid #b3b8bd;
  margin: 0 0 2rem 0;
  width: 100%;
`;
