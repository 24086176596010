import React from "react";
import { useRouter } from "next/router";
import styled from "styled-components";

import { isAdminPage } from "../../utils/utils";
import { UnAuthenticatedHeader } from "../headers/UnauthHeader/UnAuthenticatedHeader";

import CookieConsentPopup from "../CookieConsentPopup";
import Footer from "../dashboard/Footer";
import { useAuth, useRedirect, useUpdateUserAttributionEffect } from "./hooks";
import AdminHeader from "../headers/AdminHeader";
import SideBar from "../admin/SideBar";
import { AuthenticatedHeader } from "../headers/AuthHeader/AuthenticatedHeader";
import { UnsubscribedHeader } from "../headers/UnsubscribedHeader";
import { Box, LoadingIndicator } from "../../ui";
import { useTogglesReady } from "../../features/toggle/ToggleProvider";
import { useSubscriptionInfo } from "../../features/subscriptions/hooks/useSubscriptionInfo";
import { useUser } from "../../hooks";

export const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const { pathname, asPath } = useRouter();
  const requiresAuth = authenticatedRoutes.some((r) => pathname.startsWith(r));
  const { user, isAdmin, hasValidSubscription, isAuthenticated } = useAuth();
  const isAdminPages = isAdminPage(pathname);
  const willRedirect = useRedirect({
    requiresAuth,
    pathname,
    asPath,
    isAuthenticated: !!user,
    isAdmin,
    isAdminPages,
  });
  useUpdateUserAttributionEffect();

  const isTogglesReady = useTogglesReady();
  const isLoggedIn = !!useUser()?._id;
  const subscriptionInfo = useSubscriptionInfo();

  if (willRedirect) {
    return null;
  }

  if (!isTogglesReady || (isLoggedIn && !subscriptionInfo)) {
    return (
      <Box flex={1} justifyContent="center" height="100vh">
        <LoadingIndicator size="large" />
      </Box>
    );
  }

  if (isAdminPages && isAdmin) {
    return <Admin>{children}</Admin>;
  }

  const isOnboarding = pathname === "/onboarding";

  const renderHeader = () => {
    if (isAuthenticated) {
      if (hasValidSubscription) {
        return <AuthenticatedHeader />;
      } else {
        return <UnsubscribedHeader />;
      }
    } else {
      return <UnAuthenticatedHeader />;
    }
  };

  return (
    <Box
      flexDirection="column"
      justifyContent="space-between"
      overflow={isOnboarding ? ["auto", "hidden"] : "auto"}
      height={isOnboarding ? ["auto", "100vh"] : "auto"}
      width="100%"
    >
      {renderHeader()}
      {children}
      {!isOnboarding && <Footer />}
      <CookieConsentPopup />
    </Box>
  );
};

const Admin = ({ children }: { children: React.ReactNode }) => (
  <>
    <SideBar />
    <Wrapper>
      <AdminInnerWrapper>
        <AdminHeader />
        {children}
      </AdminInnerWrapper>
    </Wrapper>
  </>
);

const authenticatedRoutes = [
  "/admin",
  "/profile",
  "/dashboard",
  "/masterclasses",
];

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
`;

const AdminInnerWrapper = styled.div`
  background: #f6f9fc;
  width: 100%;
`;
