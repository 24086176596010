import { SendRequest } from "../makeSendRequest";
import { ActivityLog } from "./types";

export const createActivityLogApi = (sendRequest: SendRequest) => ({
  log: async (activityLog: ActivityLog) => {
    const response = await sendRequest<{ result: { sessionId: string } }>({
      method: "post",
      path: "activity-log/log-progress",
      data: activityLog,
      version: "v3",
    });

    return response?.result?.sessionId;
  },
});
