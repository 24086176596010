import React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 512 512" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M469.625 362.665l-361.007-18.5L143.156 206.3c2.292-9.146 10.167-15.625 19.583-16.115l157.813-8.302c11.656-.615 20.781-10.229 20.781-21.896 0-11.635-9.094-21.25-20.708-21.896l-167.927-9.323c-5.688-.323-11.115-2.969-14.865-7.26L72.031 46.311a10.677 10.677 0 00-13.948-1.854l-32 21.333a10.655 10.655 0 00-4.594 7.073c-.5 2.885.219 5.854 1.979 8.198l53.646 71.531a21.293 21.293 0 013.01 20.031L12.241 361.088C4.71 370.286 0 381.878 0 394.665c0 25.754 18.353 47.298 42.667 52.254v11.746a10.66 10.66 0 0010.667 10.667h64a10.66 10.66 0 0010.667-10.667v-10.667h298.667v10.667a10.66 10.66 0 0010.667 10.667h32a10.66 10.66 0 0010.667-10.667v-12.181c18.352-4.77 32-21.326 32-41.152-.002-23.427-18.981-42.511-42.377-42.667zM53.333 415.998c-11.76 0-21.333-9.573-21.333-21.333 0-11.76 9.573-21.333 21.333-21.333 11.76 0 21.333 9.573 21.333 21.333.001 11.76-9.572 21.333-21.333 21.333zm320.011 0c-.167 0-.333 0-.49-.01l-234.667-10.667c-5.885-.271-10.448-5.26-10.177-11.135.26-5.896 5.625-10.313 11.135-10.177l234.667 10.667c5.885.271 10.448 5.26 10.177 11.135-.26 5.729-4.979 10.187-10.645 10.187zm95.989 0c-5.891 0-10.667-4.776-10.667-10.667 0-5.892 4.776-10.667 10.667-10.667S480 399.44 480 405.332c0 5.89-4.776 10.666-10.667 10.666z" />
    </svg>
  );
}

export default SvgComponent;
