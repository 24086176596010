import { EnvConfig } from "./envConfig";
import { notifyError } from "../monitoring";

export type UnleashConfig = {
  url: string;
  clientKey: string;
  refreshInterval: number;
  appName: string;
  bootstrapOverride: boolean;
};

export const getUnleashConfig = ({ envConfig }: { envConfig: EnvConfig }) => {
  // Safeguard so that the app does not crash in off chance the client key is missing
  // Will unleash will return default values or false when client key does not match
  const clientKey =
    envConfig.kic_web_unleash_client_key || "kic_web_unleash_client_key";

  if (!envConfig.kic_web_unleash_client_key) {
    notifyError(`Unleash clientKey missing from ${envConfig.appStage}`);
  }

  return {
    url: "https://eu.app.unleash-hosted.com/eucc0024/api/frontend",
    clientKey,
    refreshInterval: 9999999,
    appName: "kic-web",
    bootstrapOverride: false, // use cache instead of bootstrap, if we have it
  };
};
