import React, { useMemo } from "react";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeElementsOptions } from "@stripe/stripe-js";
import { getStripe } from "../../../providers/stripe/stripeProvider";
import { useCheckout } from "../../Checkout/CheckoutContext";
import { usePlanPrice } from "../hooks/usePlanPrice";
import { usePlanLocale } from "../../SubscriptionPlans/hooks/usePlanLocale";
import { useTheme } from "../../../ui";

export const PaymentProvider: React.FC<{ type: "subscription" | "setup" }> = ({
  type,
  children,
}) => {
  const { selectedDiscount, selectedPlan } = useCheckout();
  const { discountedPrice, originalPrice } = usePlanPrice({
    plan: selectedPlan,
    discount: selectedDiscount,
  });
  const { planLocale } = usePlanLocale({ plan: selectedPlan });
  const finalPrice = Math.max(
    Math.floor((discountedPrice ?? originalPrice) * 100) || 0,
    0,
  );

  const stripePromise = useMemo(getStripe, []);
  const { radii } = useTheme();

  const options: StripeElementsOptions = {
    mode: type,
    currency: planLocale?.currency?.toLowerCase() || "aud",
    ...(type === "subscription" && {
      amount: finalPrice,
    }),
    appearance: {
      theme: "stripe",
      variables: { borderRadius: `${radii.full}px` },
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};

export const usePayments = () => {
  const stripe = useStripe();
  const elements = useElements();

  return { stripe, elements };
};
