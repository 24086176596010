import React from "react";

import { Text, Flex, Option, Checkbox, Check } from "./styles";

export const CheckSection = ({
  text,
  flexProps = {},
  onClick,
  isChecked,
}: {
  text: string;
  flexProps?: ExtractStyledProps<typeof Flex>;
  onClick: () => void;
  isChecked: boolean;
}) => (
  <Flex direction="row" mb="sm" {...flexProps}>
    <Text sub mr="xs">
      {text}
    </Text>
    <Option onClick={onClick}>
      <Checkbox>
        <Check checked={isChecked} />
      </Checkbox>
    </Option>
  </Flex>
);
