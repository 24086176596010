import React, { useContext, useEffect, useMemo, useState } from "react";
import { useUser } from "../../hooks";
import { AppConfig, getAppConfig, _makeAppConfig } from "./appConfig";
import { getEnvConfig } from "./envConfig";
import { getSocialConfig } from "./socialConfig";
import { getUnleashConfig } from "./unleashConfig";

const ConfigContext = React.createContext<AppConfig>(null);
export const useAppConfig = () => useContext(ConfigContext);

export const AppConfigProvider: React.FC<{
  initialConfig?: Partial<AppConfig>;
}> = ({ children, initialConfig }) => {
  const defaultAppConfig = useMemo(() => {
    const envConfig = getEnvConfig();
    const socialConfig = getSocialConfig();
    const unleashConfig = getUnleashConfig({ envConfig });

    return {
      ..._makeAppConfig({
        envConfig,
        socialConfig,
        unleashConfig,
      }),
      ...initialConfig,
    };
  }, [initialConfig]);

  const [appConfig, setAppConfig] = useState<AppConfig>(defaultAppConfig);
  const user = useUser();

  useEffect(() => {
    setAppConfig({ ...getAppConfig(), ...initialConfig });
  }, [user._id]);

  return (
    <ConfigContext.Provider value={appConfig}>
      {children}
    </ConfigContext.Provider>
  );
};
