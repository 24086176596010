import axios from "axios";
import { thunk } from "easy-peasy";

import { logAxiosError } from "../utils";

const removePost = ({ key, endpoint }) =>
  thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    await getStoreActions().auth.checkTokenExpiration();

    const { token } = getStoreState().auth.authData;

    try {
      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.delete(`${endpoint}/${payload}`, {
        headers,
      });

      actions.removeFromPosts(payload);

      return response;
    } catch (error) {
      logAxiosError(error, key);
      return error.response.data;
    }
  });

export default removePost;
