import React from "react";

const SvgComponent = (props) => (
  <svg
    id="prefix__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 500 500"
    width="1rem"
    height="1rem"
    {...props}
  >
    <defs>{/* <style>{'.prefix__cls-1{fill:#231f20}'}</style> */}</defs>
    <title>{"KIC_Insignia"}</title>
    <path
      className="prefix__cls-1"
      d="M250 500C112.15 500 0 387.85 0 250S112.15 0 250 0s250 112.15 250 250-112.15 250-250 250zm0-474C126.48 26 26 126.48 26 250s100.48 224 224 224 224-100.48 224-224S373.52 26 250 26z"
    />
    <path
      className="prefix__cls-1"
      d="M364.6 255.38a12.51 12.51 0 00-12.5 12.5 102.93 102.93 0 01-205.86 0 12.5 12.5 0 10-25 0 127.92 127.92 0 10255.85 0 12.51 12.51 0 00-12.49-12.5zM188.14 166.79l-3.33-9.32a4 4 0 00-5.34-2.28l-13.92 6.23 1.39-16.15A4 4 0 00163 141h-10a4 4 0 00-3.94 4.3l1.39 16.15-13.92-6.23a4 4 0 00-5.34 2.28l-3.33 9.32a4 4 0 002.81 5.18l15.19 3.62-10.08 10.93a4 4 0 00.47 5.79l7.77 6.1a4 4 0 002.44.84 4.06 4.06 0 00.69-.06 4 4 0 002.67-1.81l8.18-13.15 8.18 13.14a4 4 0 005.81 1l7.77-6.1a4 4 0 00.47-5.79l-10.08-10.93 15.18-3.58a4 4 0 002.81-5.18zM374.37 157.68c-11-8.6-25-13.15-40.32-13.15-17.53 0-35.75 6.14-48.73 16.42a12.5 12.5 0 007.77 22.3 12.36 12.36 0 007.75-2.7c8.68-6.87 21.07-11 33.13-11 9.81 0 18.47 2.71 25 7.83a12.5 12.5 0 1015.36-19.72z"
    />
  </svg>
);

export default SvgComponent;
