import React from "react";

function SvgComponent(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M7.0092 13.8333L3.33254 10.2511C3.12056 10.0459 3.00094 9.76697 3.00001 9.47579C2.99907 9.18462 3.11689 8.90501 3.32754 8.69848C3.5382 8.49194 3.82444 8.37539 4.12328 8.37449C4.42213 8.37357 4.70912 8.48836 4.9211 8.69361L7.0092 10.7281L13.0537 4.84855C13.1566 4.74096 13.2807 4.65466 13.4186 4.5948C13.5565 4.53495 13.7053 4.50276 13.8562 4.50017C14.0071 4.49758 14.157 4.52463 14.297 4.5797C14.4369 4.63478 14.564 4.71676 14.6708 4.82075C14.7775 4.92474 14.8617 5.04861 14.9182 5.18497C14.9747 5.32133 15.0025 5.46739 14.9998 5.61443C14.9971 5.76147 14.9642 5.90648 14.9027 6.04081C14.8413 6.17515 14.7527 6.29605 14.6423 6.39631L7.0092 13.8333Z"
        fill="#008552"
      />
    </svg>
  );
}

export default SvgComponent;
