import { CustomerInfo } from "@revenuecat/purchases-js";
import { KIC_ACCESS_ENTITLEMENT_IDENTIFIER, SubscriptionInfo } from "../types";

export const customerInfoHasEntitlement = (customerInfo: CustomerInfo) => {
  return !!customerInfo?.entitlements?.active?.[
    KIC_ACCESS_ENTITLEMENT_IDENTIFIER
  ];
};

export const subscriptionInfoHasEntitlement = (
  subscriptionInfo: SubscriptionInfo,
) => {
  return customerInfoHasEntitlement(subscriptionInfo?.customerInfo);
};
