import { CustomerInfo } from "@revenuecat/purchases-js";

export const KIC_ACCESS_ENTITLEMENT_IDENTIFIER = "Kic Subscription";

export const REVENUE_CAT_USER_ID_PREFIX = "RCUID_";
export type RevenueCatUserId = `${typeof REVENUE_CAT_USER_ID_PREFIX}${string}`;

export type SubscriptionInfo = {
  revenueCatUserId: RevenueCatUserId;
  customerInfo: CustomerInfo;
};

export type RevenueCatPromotionalDuration =
  | "daily"
  | "three_day"
  | "weekly"
  | "monthly"
  | "two_month"
  | "three_month"
  | "six_month"
  | "yearly"
  | "lifetime";

export type FetchedRevenueCatSubscription = {
  auto_resume_date: string | null;
  billing_issues_detected_at: string | null;
  expires_date: string;
  grace_period_expires_date: string | null;
  is_sandbox: boolean;
  original_purchase_date: string;
  ownership_type: string;
  period_type: "normal" | "trial" | "intro";
  purchase_date: string;
  refunded_at: string | null;
  store:
    | "app_store"
    | "mac_app_store"
    | "play_store"
    | "amazon"
    | "stripe"
    | "promotional";
  store_transaction_id: string;
  unsubscribe_detected_at: string | null;
} & { name: "Monthly" | "Quarterly" | "Yearly" | "Promotional" };

export type FetchedUserSubscription = {
  subscriptions: Record<string, FetchedRevenueCatSubscription>;
};
