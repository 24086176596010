import styled from "styled-components";
import { ReactModalAdapter } from "../../../styles/authCommonStyles";

export const StyledModal = styled(ReactModalAdapter)`
  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    backdrop-filter: blur(6px);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    transition: ease 0.5s;
    z-index: 10;

    &.ReactModal__Overlay--after-open {
      background-color: #0000008c;
      backdrop-filter: blur(6px);
    }
    &.ReactModal__Overlay--before-close {
      background-color: #00000000;
      backdrop-filter: blur(0px);
    }
  }

  &__content {
    align-items: center;
    background: #fff;
    border-radius: ${(props) => props.theme.borderRadius};
    box-shadow: 1px 1px 7px 1px #00000052;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    outline: none;
    padding: 2rem;
    position: relative;
    width: 90%;
    transition: ease 0.5s;

    &.ReactModal__Content--after-open {
      opacity: 1;
    }
    &.ReactModal__Content--before-close {
      opacity: 0;
    }
  }
`;
