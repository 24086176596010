import actions from "./admin.breakdowns.actions";
import computators from "./admin.breakdowns.computators";
import listeners from "./admin.breakdowns.listeners";
import state from "./admin.breakdowns.state";

export default {
  ...state,
  ...actions,
  ...computators,
  ...listeners,
};
