import axios from "axios";
import { thunk } from "easy-peasy";

import { logAxiosError } from "../utils";

const updatePost = ({ key, endpoint, parser }) =>
  thunk(async (actions, payload, { getStoreActions, getStoreState }) => {
    await getStoreActions().auth.checkTokenExpiration();

    const { token } = getStoreState().auth.authData;

    try {
      const { _id, body } = parser(payload);

      const headers = { Authorization: `Bearer ${token}` };

      const response = await axios.put(`${endpoint}/${_id}`, body, { headers });

      const { data } = response;

      return { data };
    } catch (error) {
      logAxiosError(error, key);
      return error.response.data;
    }
  });

export default updatePost;
