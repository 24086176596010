import "react-datepicker/dist/react-datepicker.css";
import "react-mde/lib/styles/css/react-mde-all.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../src/styles/fonts.css";

import axios from "axios";
import { Store, StoreProvider } from "easy-peasy";
import App from "next/app";
import Head from "next/head";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { QueryClient, QueryClientProvider } from "react-query";
import { GoogleOAuthProvider } from "@react-oauth/google";

import { AppConfigProvider } from "../src/utils/appConfig/AppConfigProvider";
import withStore from "../store/with-store";
import { AuthWrapper } from "../src/components/AuthWrapper";
import GlobalStyle from "../src/styles/GlobalStyles";
import { themes } from "../src/styles/themes";
import {
  initialise,
  setScreen,
  ErrorBoundary,
  logEvent,
} from "../src/utils/monitoring";
import { ErrorBoundaryPage } from "../src/components/ErrorPage";
import { getBaseDomain } from "../config";
import { ThemeProvider } from "../src/ui/ThemeProvider";
import { WebFontUrls } from "../src/styles/fonts";
import { LocationProvider } from "../src/providers/Location/locationProvider";
import { getEnvConfig } from "../src/utils/appConfig/envConfig";
import { ReactQueryDevtools } from "react-query/devtools";
import { ToggleProvider } from "../src/features/toggle/ToggleProvider";
import { StoreModel } from "../store";
import { IsoCountry } from "../src/api";
import { SubscriptionInfoProvider } from "../src/features/subscriptions/components/SubscriptionInfoProvider";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnMount: "always",
      staleTime: 5 * 60 * 1000, // 5m
    },
  },
});

interface MyAppProps {
  Component: ReactNode;
  reduxStore: Store<StoreModel>;
  pageProps: { body: Record<string, unknown> };
}

class MyApp extends App<MyAppProps> {
  componentDidMount() {
    logEvent({
      eventName: "WebAppLoaded",
    });
    axios.interceptors.response.use(
      (res) => res,
      (ctx) => {
        if (
          [
            "Route requires authentication with a bearer token",
            "TokenExpiredError - jwt expired",
            "JsonWebTokenError - jwt malformed",
            "token is now invalid",
            "JsonWebTokenError - invalid signature",
            "Invalid body parameters specified: token",
          ].includes(ctx.response?.data?.error?.message)
        ) {
          const logOut = this.props.reduxStore.getActions().auth.logOut;
          logOut();
          this.props.router.push("/login");
        }

        return Promise.reject(ctx);
      },
    );

    const { allowTracking } = this.props.reduxStore.getState().profile;
    const user = this.props.reduxStore.getState().profile.userData;
    initialise(allowTracking, user);

    setScreen(this.props.router.pathname);
    this.props.router.events.on("routeChangeComplete", (screen) =>
      setScreen(screen),
    );
  }

  render() {
    const { Component, pageProps, reduxStore } = this.props;
    const env = getEnvConfig();

    const baseDomain = getBaseDomain({ appStage: env.appStage });
    return (
      <>
        <Head>
          {[16, 32, 96, 128, 196].map((s) => (
            <link
              key={s}
              rel="icon"
              type="image/png"
              href={`/static/favicons/favicon_${s}.png`}
              sizes={`${s}x${s}`}
            />
          ))}
          <meta name="viewport" content="width=device-width, initial-scale=1" />

          {/* Primary Meta Tags */}
          <meta property="title" content="Home" />
          <meta
            name="description"
            content="Kic offers a world-class fitness training program, run tracker, meal planner, hundreds of recipes, playlists, interval timer and more."
          />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content={baseDomain} />
          <meta property="og:title" content="Home" />
          <meta
            property="og:description"
            content="Kic offers a world-class fitness training program, run tracker, meal planner, hundreds of recipes, playlists, interval timer and more."
          />
          <meta
            property="og:image"
            content="https://cdn.keepitcleaner.com.au/rebuild/admin/temp/2023/1/1674794310924.png"
          />

          {/* Twitter */}
          <meta name="twitter:card" content="summary" />
          <meta property="twitter:url" content={baseDomain} />
          <meta property="twitter:title" content="Home" />
          <meta
            property="twitter:description"
            content="Kic offers a world-class fitness training program, run tracker, meal planner, hundreds of recipes, playlists, interval timer and more."
          />
          <meta
            property="twitter:image"
            content="https://cdn.keepitcleaner.com.au/rebuild/admin/temp/2023/1/1674794310924.png"
          />

          {/* monitoring */}
          <meta property="fb:app_id" content={env.kicweb_facebook_app_id} />
          <meta property="fb:page_id" content="1095700433778583" />
          <meta name="apple-itunes-app" content="app-id=1438803205" />

          {/* Styles - Custom Fonts */}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          {WebFontUrls.map((url) => (
            <link key={url} href={url} rel="stylesheet" />
          ))}

          {/* Scripts */}
          <script src="/static/scripts/branch.js"></script>
          <script src="/static/scripts/pixel.js"></script>
          <script src="/static/scripts/tiktok.js"></script>
          <script src="/static/scripts/segment.js"></script>
          <script src="/static/scripts/googleClickId.js"></script>
          <script
            src="https://apps.rokt.com/integrations/referral-tag/referral-tag.js"
            data-fp-domain={baseDomain}
            async
          ></script>
        </Head>
        <ErrorBoundary FallbackComponent={ErrorBoundaryPage}>
          <QueryClientProvider client={queryClient}>
            <StoreProvider store={reduxStore}>
              <AppConfigProvider>
                <ToggleProvider>
                  <SubscriptionInfoProvider>
                    <ThemeProvider legacyTheme={themes.light}>
                      <LocationProvider
                        iso={pageProps?.body?.iso as IsoCountry}
                      >
                        <GlobalStyle />
                        <Wrapper>
                          <GoogleOAuthProvider
                            clientId={env.kic_web_google_oauth_client_id}
                          >
                            <AuthWrapper>
                              <Component {...pageProps} />
                            </AuthWrapper>
                          </GoogleOAuthProvider>
                        </Wrapper>
                      </LocationProvider>
                    </ThemeProvider>
                  </SubscriptionInfoProvider>
                </ToggleProvider>
              </AppConfigProvider>
            </StoreProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ErrorBoundary>
      </>
    );
  }
}

export default withStore(MyApp);
