import state, { ProfileState } from "./profile.state";
import actions, { ProfileActions } from "./profile.actions";
import listeners, { ProfileListeners } from "./profile.listeners";

export type EasyProfileModel = ProfileState & ProfileListeners & ProfileActions;

const profileModel: EasyProfileModel = {
  ...state,
  ...actions,
  ...listeners,
};

export default profileModel;
