/* eslint-disable operator-assignment */
/* eslint-disable no-param-reassign */
import { action, thunk } from "easy-peasy";
import axios from "axios";
import { logAxiosError } from "../../src/utils/utils";
import { KIC_API } from "../../src/api/index";
import initialState from "./payments.state";

const actions = {
  appendToList: action((state, payload) => {
    const { key, data } = payload;
    state[key].unshift(data);
  }),
  clearState: action((state) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(initialState)) {
      state[key] = value;
    }
  }),
  fetchSubscriptions: thunk(async (actions, payload) => {
    try {
      const { pageNumber, limit, search } = payload;

      const response = await axios.get(
        `${KIC_API}/api/v1/subscriptions?page=${pageNumber}${
          limit ? `&limit=${limit}` : ""
        }${search ? `&search=${search}` : ""}`,
      );

      actions.setState({ key: "subscriptions", data: response.data.data });
      actions.setState({
        key: "subscriptionsPageSettings",
        data: response.data.settings,
      });
    } catch (error) {
      logAxiosError(error, "fetchSubscriptions");
    }
  }),
  removeFromList: action((state, payload) => {
    const { key, id } = payload;
    state[key] = state[key].filter((recipe) => recipe._id !== id);
  }),
  setState: action((state, payload) => {
    const { key, data } = payload;
    state[key] = data;
  }),
  updateList: action((state, payload) => {
    const { key, data } = payload;
    state[key] = state[key].map((item) =>
      item._id === data._id ? data : item,
    );
  }),
};

export default actions;
