import React, { useState } from "react";

import { IconButton } from "../../../ui";
import { OnboardingNavModal } from "./components/OnBoardingNavModal";
import { OnboardingHeader } from "../OnboardingHeader";

export const UnsubscribedHeader: React.FC = () => {
  const [onBoardingNavModalVisible, setOnBoardingNavModalVisible] =
    useState(false);

  const onModalClose = () => {
    setOnBoardingNavModalVisible(false);
  };
  return (
    <OnboardingHeader>
      <IconButton
        variant="buttonSmallFilledLight"
        onClick={() => {
          setOnBoardingNavModalVisible(!onBoardingNavModalVisible);
        }}
        iconName="user-outline"
      />
      <OnboardingNavModal
        isVisible={onBoardingNavModalVisible}
        onModalClose={onModalClose}
      />
    </OnboardingHeader>
  );
};
