import React from "react";

function SvgComponent({ props }) {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.71967 3.96967C7.01256 3.67678 7.48744 3.67678 7.78033 3.96967L12.2803 8.46967C12.5732 8.76256 12.5732 9.23744 12.2803 9.53033L7.78033 14.0303C7.48744 14.3232 7.01256 14.3232 6.71967 14.0303C6.42678 13.7374 6.42678 13.2626 6.71967 12.9697L10.6893 9L6.71967 5.03033C6.42678 4.73744 6.42678 4.26256 6.71967 3.96967Z"
        fill="#575754"
      />
    </svg>
  );
}

export default SvgComponent;
