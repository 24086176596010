import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 320 512" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M305.5 247.3l-19.6-21.1-8.7-28.5c-7.9-26.1-23.3-47.8-43.2-62.8 19.3-13.4 32-35.7 32-60.9 0-40.8-33.2-74-74-74s-74 33.2-74 74c0 15.7 4.9 30.3 13.3 42.3-14.7 3.8-28.4 9.8-45.3 17.4l-8.6 3.8c-24 10.5-43.4 30.1-54.9 55.2l-11.8 28c-7.3 17.2-5.7 35.7 4.2 50.7C24.6 286 41.2 295 58.4 295c12.5 0 24-4.7 32.9-12.9.2 1.4.4 2.7.6 4.1l-14.2 38.9-16.3 44.6L14 422.1c-18.7 20.4-18.7 52.4 0 72.9 9.6 10.5 23.2 16.6 37.2 16.6 14 0 27.6-6 37.2-16.6l46.7-51.2c9.6-10.6 17-22.9 22-36.5l8.7-23.9 10.6 10.7c.5.5.8 1.3.9 1.9l15.6 74.2C198 494.5 219 512 242.8 512c4 0 8.1-.5 12.1-1.5 27.4-6.9 44-34.6 38-62.9l-15.6-74.1c-2.8-13.2-7.8-25.6-14.7-36.7 1.8.2 3.6.3 5.5.3 20.3 0 38.9-12.6 47.4-32.2 8.6-19.5 4.7-41.6-10-57.6zM192 32c23.2 0 42 18.8 42 42s-18.8 42-42 42-42-18.8-42-42 18.8-42 42-42zm-65 364.3c-3.5 9.7-8.8 18.4-15.6 25.9l-46.7 51.2c-3.7 4.1-8.6 6.2-13.6 6.2-4.9 0-9.8-2.1-13.6-6.2-7.5-8.2-7.5-21.5 0-29.7l46.7-51.2c3.1-3.4 5.5-7.4 7.1-11.8l16.3-44.6.1.1 1 1.2 29 29.3-10.7 29.6zm141.1-91.1c-4.4 0-9-1.8-13-6.2l-23.5-25.3c-5-5.4-8.8-12.1-11-19.4-11.4-37.5-11.5-39.9-17.5-48.5l-20.9 91.7 43 43.4c10.4 10.6 17.7 24.2 20.8 39.3l15.6 74.1c2.4 11.3-4 22.6-14.4 25.2-1.5.4-3 .5-4.4.5-9.4 0-16.7-7.6-18.6-16.3l-15.6-74.2c-1.4-6.9-4.7-13-9.5-17.8L136 307.8c-11.6-13.8-15.7-31.2-11.9-47.7l15.8-69.3c-9 3.2-18.1 7.3-35.4 15-8 3.5-14.5 10-18.3 18.4L75 250.8c-3.7 8.7-10.1 12.3-16.6 12.3-12.5 0-25.2-13.4-18.2-29.9L51.7 206c8.3-18.2 21.9-31.9 38.6-39.2 34.2-15.1 48.7-22.8 76.6-22.8 36.3 0 68.3 25.3 79.8 63l10.3 33.9c.3 1 .8 2 1.6 2.8L282 269c14.1 15.3.9 36.2-13.9 36.2z" />
    </svg>
  );
}

export default SvgComponent;
