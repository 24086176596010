import React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 108 100.4"
      xmlSpace="preserve"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path d="M107.6 100c.3-.3.4-.7.4-1.1l-7.1-97.4c-.1-.8-.7-1.4-1.5-1.4H8.6C7.8 0 7.2.6 7.1 1.4L0 98.8c0 .4.1.8.4 1.1s.7.5 1.1.5h105c.4 0 .8-.1 1.1-.4zM3.1 97.4L10 3h88l6.9 94.4H3.1z" />
      <path d="M60.7 45.4c-.8 0-1.5.7-1.5 1.5 0 1.4-.5 2.7-1.5 3.7s-2.3 1.5-3.7 1.5c-2.9 0-5.2-2.3-5.2-5.2 0-.8-.7-1.5-1.5-1.5s-1.5.7-1.5 1.5c0 4.5 3.7 8.2 8.2 8.2 2.2 0 4.3-.9 5.8-2.4 1.6-1.5 2.4-3.6 2.4-5.8 0-.9-.7-1.5-1.5-1.5z" />
    </svg>
  );
}

export default SvgComponent;
