import { RecipeListParams } from "./types";

export const formatParams = ({
  searchTerm,
  mealTypes,
  allergyTypes,
  dietTypes,
  dietGoal,
  limit = 20,
  page = 1,
}: RecipeListParams) => ({
  page: page.toString(),
  limit: limit.toString(),
  ...(dietGoal && { "type_specific.goals": `["${dietGoal}"]` }),
  ...(searchTerm && { search: searchTerm }),
  // These properties & their types don't match up because the API is silly
  ...(dietTypes?.length && {
    "type_specific.meal_types": JSON.stringify(dietTypes),
  }),
  ...(mealTypes?.length && {
    "type_specific.recipe_types": JSON.stringify(mealTypes),
  }),
  ...(allergyTypes?.length && {
    "type_specific.diet_types": JSON.stringify(allergyTypes),
  }),
});
