import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PaymentProvider } from "../Payments/components/PaymentContext";
import { useUrlParam } from "../../hooks";
import { ExtendedDiscount, ExtendedPlan, usePlans } from "./hooks";
import { useGetPartnerDetails } from "../Onboarding/utils/useGetPartner";

type CheckoutContextData = {
  plans: ExtendedPlan[];
  plansLoading: boolean;

  setDiscountCode: (discountCode: string) => void;
  discountsLoading: boolean;

  selectedPlan?: ExtendedPlan;
  selectedDiscount?: ExtendedDiscount;
  discountCodeError?: string;

  showTrialBanner: boolean;

  setSelectedPlanId: (planId: string) => void;
  userHasManuallyRemovedDiscount: boolean;
  setUserHasManuallyRemovedDiscount: React.Dispatch<
    React.SetStateAction<boolean>
  >;
};

const CheckoutContext = createContext<CheckoutContextData>({
  plans: [],
  plansLoading: true,
  discountsLoading: true,
  showTrialBanner: false,
  setSelectedPlanId: () => null,
  setDiscountCode: () => null,
  userHasManuallyRemovedDiscount: false,
  setUserHasManuallyRemovedDiscount: () => null,
});

export const CheckoutProvider: React.FC = ({ children }) => {
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [discountCodeError, setDiscountCodeError] = useState("");
  const [userHasManuallyRemovedDiscount, setUserHasManuallyRemovedDiscount] =
    useState(false);
  const { plans, plansLoading, applyDiscount, discountsLoading } = usePlans({
    setDiscountError: setDiscountCodeError,
    userHasManuallyRemovedDiscount,
  });
  const partnerQueryParam = useUrlParam({
    paramName: "partner",
  });
  const partner = useGetPartnerDetails(partnerQueryParam);
  const preselectedPlanName = useUrlParam({
    paramName: "plan",
    defaultValue: "monthly",
  });
  const preselectedCoupon = useUrlParam({
    paramName: "coupon",
  });

  // TODO remove this hax
  // Quick hack to fix an url issue sent out by marketing
  const preselectedPlan = plans?.find(({ name }) => {
    if (preselectedPlanName === "mothly") {
      return plans.find(({ name }) => name.toLowerCase() === "monthly");
    }
    return name.toLowerCase() === preselectedPlanName;
  });

  useEffect(() => {
    const partnerDefaultPlanName = partner?.defaultPlanName;

    if (partnerDefaultPlanName) {
      const partnerPlan = plans.find(
        ({ name }) => name.toLowerCase() === partnerDefaultPlanName,
      );

      if (partnerPlan) {
        setSelectedPlanId(partnerPlan._id);
        return;
      }
    }

    if (preselectedPlan) {
      setSelectedPlanId(preselectedPlan._id);
    }
  }, [preselectedPlan?._id]);

  const selectedPlan = useMemo(
    () => plans?.find((p) => p._id === selectedPlanId),
    [selectedPlanId, plans],
  );
  const selectedDiscount = useMemo(
    () => selectedPlan?.discount,
    [selectedPlan],
  );
  const showTrialBanner = useMemo(
    () => plans?.every(({ discount }) => !discount),
    [plans],
  );

  const setDiscountCode = useCallback(
    (discountCode: string) => {
      setDiscountCodeError("");
      applyDiscount(selectedPlanId, discountCode);
    },
    [selectedPlanId, setDiscountCodeError, applyDiscount],
  );

  useEffect(() => {
    if (plansLoading || !selectedPlanId) return;
    setDiscountCode(preselectedCoupon);
  }, [plansLoading, !!selectedPlanId]);

  return (
    <CheckoutContext.Provider
      value={{
        plans,
        plansLoading,
        setDiscountCode,
        discountsLoading,
        discountCodeError,
        selectedPlan,
        selectedDiscount,
        showTrialBanner,
        setSelectedPlanId,
        userHasManuallyRemovedDiscount,
        setUserHasManuallyRemovedDiscount,
      }}
    >
      <PaymentProvider type="subscription">{children}</PaymentProvider>
    </CheckoutContext.Provider>
  );
};

export const useCheckout = () => useContext(CheckoutContext);
