import { useMemo } from "react";
import { CurrencyType, Plan } from "../api";
import { useLocation } from "../providers/Location/locationProvider";

export const useLocale = () => {
  const { language } = window.navigator;
  const location = useLocation();
  const iso = location.Iso;

  return useMemo(() => {
    return {
      locale: language,
      iso,
      getPlanLocale: (plan: Plan) => {
        return (
          plan?.locales?.find((locale) => locale.iso === iso) ??
          plan?.locales?.find((locale) => locale.iso === "AU")
        );
      },
      formatPrice: ({
        price,
        currency,
        forceDecimals,
      }: {
        price: number;
        currency: CurrencyType;
        forceDecimals: boolean;
      }) => {
        const priceWithTwoFractionDigits = Math.round(price * 100) / 100;
        const fractionDigits =
          forceDecimals || !Number.isInteger(priceWithTwoFractionDigits)
            ? 2
            : 0;

        const payload = {
          style: "currency",
          currency: currency,
          currencyDisplay: "narrowSymbol",
          minimumFractionDigits: fractionDigits,
          maximumFractionDigits: fractionDigits,
        };
        try {
          const formattedPrice = new Intl.NumberFormat(
            language,
            payload,
          ).format(priceWithTwoFractionDigits);
          return currency === "AUD" ? `${formattedPrice} AUD` : formattedPrice;
        } catch {
          const formattedPrice = new Intl.NumberFormat(language, {
            ...payload,
            currencyDisplay: "symbol",
          }).format(priceWithTwoFractionDigits);

          const symbolFormattedPrice = formattedPrice.startsWith("A$")
            ? formattedPrice.slice(1)
            : formattedPrice;

          return currency === "AUD"
            ? `${symbolFormattedPrice} AUD`
            : symbolFormattedPrice;
        }
      },
    };
  }, [language, iso]);
};
