const recipeParserForApi = (data) => {
  const {
    _id,
    image_lede: imageLede,
    thumbnail,
    publish_date: publishDate,
    type_specific: typeSpecific,
    ...rest
  } = data;

  const imageUrl = imageLede?.url || null;
  const thumbnailUrl = thumbnail?.url || null;
  const pubDateSeconds = Math.floor(publishDate.getTime() / 1000);
  const mealTypeIds = typeSpecific.meal_types.map((type) => type._id);
  const dietTypeIds = typeSpecific.diet_types.map((type) => type._id);
  const goalIds = typeSpecific.goals.map((goal) => goal._id);
  const recipeTypeIds = typeSpecific.recipe_types.map((type) => type._id);
  const tagIds = typeSpecific.tags.map((tag) => tag._id);
  const time = typeSpecific.time.value;
  const ingredients = typeSpecific.ingredients.map(
    ({ ingredient, amount, unit }) => ({
      ingredient: ingredient?.value,
      amount,
      unit,
    }),
  );
  const method = typeSpecific.method.map((step) => step.trim());

  const body = {
    ...rest,
    image_lede: imageUrl,
    thumbnail: thumbnailUrl,
    publish_date: pubDateSeconds,
    type_specific: {
      ...typeSpecific,
      meal_types: mealTypeIds,
      diet_types: dietTypeIds,
      goals: goalIds,
      recipe_types: recipeTypeIds,
      tags: tagIds,
      time,
      ingredients,
      method,
    },
  };

  return {
    _id,
    body,
    data,
  };
};

const recipeCloneParser = (parsed) => {
  parsed.publish_date = new Date();
  parsed.status = "pending_review";
  parsed.visibility = "private";
  parsed.title = `${parsed.title} **COPY**`;

  delete parsed.id;
  delete parsed.inserted;
  delete parsed.updated;
  delete parsed.__v;
  delete parsed._id;
  delete parsed.favourited;
  delete parsed.liked;
  delete parsed.old_id;
  delete parsed.totalLikes;

  return parsed;
};

export { recipeParserForApi as default, recipeCloneParser };
