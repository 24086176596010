import * as React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 640 512" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M232 256c0 13.2-10.8 24-24 24s-24-10.8-24-24 10.8-24 24-24 24 10.8 24 24zm252-49.7l150 120c6.7 5.4 8 15.2 2.8 22.1l-78.2 104.3c-4.8 6.5-13.7 8.3-20.7 4.3l-150.4-86C312.1 484.4 150.8 492.8 61 403c-65.5-65.5-78.1-163.8-38-242-14.4-11.8-23-29.7-23-49 0-35.3 28.7-64 64-64 19.2 0 36.9 8.9 49 23.5 65.3-33.6 144.4-30.8 207 9.3l86.6 63.6c5.3 4.2 7.3 11.3 5.1 17.7l-10.2 29.5c-1.1 3.2-.1 6.7 2.6 8.9l25.2 20.2c2.6 2.1 6.2 2.3 9.1.6l27.4-16.2c5.8-3.4 13-2.9 18.2 1.2zM85.5 88.3C79.7 83 72 80 64 80c-17.7 0-32 14.3-32 32 0 8.1 3.3 15.4 8.4 21.2 15.2-20.6 27.3-31.9 45.1-44.9zM280 256c0-39.7-32.3-72-72-72s-72 32.3-72 72 32.3 72 72 72 72-32.3 72-72z" />
    </svg>
  );
}

export default SvgComponent;
