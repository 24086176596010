import * as React from "react";

function SvgComponent(props) {
  return (
    <svg width="23" height="22" viewBox="0 0 23 22" {...props}>
      <path
        id="facebook-icon"
        d="M22.0263 11.0263C22.0263 5.21579 17.3105 0.5 11.4999 0.5C5.68942 0.5 0.973633 5.21579 0.973633 11.0263C0.973633 16.1211 4.59469 20.3632 9.39469 21.3421V14.1842H7.28942V11.0263H9.39469V8.39474C9.39469 6.36316 11.0473 4.71053 13.0789 4.71053H15.7105V7.86842H13.6052C13.0263 7.86842 12.5526 8.3421 12.5526 8.92105V11.0263H15.7105V14.1842H12.5526V21.5C17.8684 20.9737 22.0263 16.4895 22.0263 11.0263Z"
        fill="#1877F2"
      />
    </svg>
  );
}

export default SvgComponent;
