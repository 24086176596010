import React from "react";
import Router from "next/router";
import {
  Container,
  Header,
  Brand,
  StyledIcon,
  Tabs,
  ButtonWrapper,
} from "./styles";
import Item from "./Item";
import CTAButton from "../../../admin/common/CTAButton";

const SlideMenu = ({ style, toggleMenu, tabs, logOut, trail }) => {
  return (
    <Container style={style}>
      <Header>
        <Brand name="kic-icon" />
        <StyledIcon name="times" onClick={toggleMenu} />
      </Header>
      <Tabs>
        {trail.map(({ x, opacity }, index) => (
          <Item
            key={tabs[index].route}
            {...tabs[index]}
            toggleMenu={toggleMenu}
            router={Router}
            style={{
              opacity,
              transform: x.interpolate((x) => `translateX(${x}px)`),
            }}
          />
        ))}
      </Tabs>
      <ButtonWrapper>
        <CTAButton
          text="Log Out"
          onClick={logOut}
          buttonStyle={{ width: "100%" }}
        />
      </ButtonWrapper>
    </Container>
  );
};

export default React.memo(SlideMenu);
