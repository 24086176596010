export const formatCategories = (
  categories: { name: string; subcategories?: { name: string }[] }[],
) =>
  categories.reduce(
    (obj, c) => ({
      ...obj,
      [c.name]: !c.subcategories?.length
        ? true
        : c.subcategories.map((s) => s.name),
    }),
    {},
  );
