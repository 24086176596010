import axios from "axios";
import { getEnvConfig } from "../utils/appConfig/envConfig";

const env = getEnvConfig();

export type GetToken = () => Promise<string>;

/**
 * keepitcleaner-rest-api URL
 *
 * - Local:         "http://localhost:7070"
 * - Devel:         "https://api.rebuild.test.keepitcleaner.com.au"
 * - Prod:          "https://api.rebuild.keepitcleaner.com.au"
 * - Chef's choice: env.kicweb_api
 **/
export const KIC_API = env.kicweb_api;

export const makeSendRequest =
  (getToken: GetToken) =>
  async <T>({
    method,
    path,
    version = "v2",
    data,
    params,
    options = {
      requiresAuth: true,
    },
    headers = {},
  }: {
    method: CallMethod;
    path: string;
    version?: Version;
    data?: any;
    params?: Record<
      string,
      string | string[] | number | Record<string, string | string[] | number>
    >;
    options?: {
      requiresAuth: boolean;
    };
    headers?: Record<string, string>;
  }) => {
    const token = await (options.requiresAuth ? getToken() : null);

    return getAPICalls<T>(
      `${KIC_API}/api/${version}/${path}`,
      token
        ? {
            Authorization: `Bearer ${token}`,
            ...headers,
          }
        : {
            ...headers,
          },
      params,
    )[method](data);
  };

export type SendRequest = ReturnType<typeof makeSendRequest>;

export type CallMethod = "get" | "put" | "post" | "delete";

export type Version = "v1" | "v2" | "v3";

export const getAPICalls = <T>(
  route: string,
  headers: Record<string, string>,
  params: Record<
    string,
    string | string[] | number | Record<string, string | string[] | number>
  > = {},
) => ({
  get: () => axios.get<T>(route, { headers, params }).then(({ data }) => data),
  put: (data) =>
    axios.put<T>(route, data, { headers, params }).then(({ data }) => data),
  post: (data) =>
    axios.post<T>(route, data, { headers, params }).then(({ data }) => data),
  delete: () =>
    axios.delete<T>(route, { headers, params }).then(({ data }) => data),
});
