import { set } from "lodash";
import { useState, useEffect, useCallback, useMemo } from "react";
import z from "zod";

import { useDebounceValue } from "./useDebounceValue";

export const useForm = <T extends z.ZodType<any, any, any>>(
  formSchema: T,
  initialData: z.infer<typeof formSchema>,
  options = {
    validateFieldsOnlyOnTouch: true,
  },
) => {
  type FormSchema = z.infer<typeof formSchema>;

  const [formData, setFormData] = useState<FormSchema>(initialData);
  const [fieldsTouched, setFieldsTouched] = useState<(keyof FormSchema)[]>([]);
  const [formErrors, setFormErrors] = useState<z.ZodIssue[]>([]);
  const debouncedFormErrors = useDebounceValue(formErrors, 500, [formErrors]);

  const formErrorMessages = useMemo(
    () =>
      formErrors.map(
        (e) => `${e.path.join().replaceAll(",", "-")} : ${e.message}`,
      ),
    [formErrors],
  );
  const debouncedFormErrorMessages = useDebounceValue(formErrorMessages, 500, [
    formErrorMessages,
  ]);

  const validate = useCallback(
    (forceValidateAllFields = false) => {
      if (formData.isRepeating) formData.nextProgramId = "";

      const result = formSchema.safeParse(formData);

      if (result.success === true) {
        setFormErrors([]);
      } else {
        setFormErrors(
          result.error.errors.filter(
            (e) =>
              forceValidateAllFields ||
              !options.validateFieldsOnlyOnTouch ||
              fieldsTouched.includes(e.path[0]),
          ),
        );
      }

      return result.success;
    },
    [formData, formSchema, options.validateFieldsOnlyOnTouch, fieldsTouched],
  );

  useEffect(() => {
    validate();
  }, [validate]);

  useEffect(() => {
    setFormData(initialData);
  }, [initialData]);

  const makeUpdateFormField =
    (fieldKey: keyof FormSchema) =>
    ({ target: { value } }) => {
      setFormData((data) => set({ ...data }, fieldKey, value));

      if (fieldsTouched.includes(fieldKey)) return;
      setFieldsTouched((touched) => [...touched, fieldKey]);
    };

  const hasError = (fieldKey: keyof FormSchema) =>
    debouncedFormErrors?.some((e) => e.path.includes(fieldKey.toString()));

  return {
    formData,
    fieldsTouched,
    makeUpdateFormField,
    hasError,
    formErrors: formErrorMessages,
    debouncedFormErrors: debouncedFormErrorMessages,
    validate,
  };
};
