import { thunkOn, ThunkOn } from "easy-peasy";
import { StoreModel } from "../..";
import { EasyUserModel } from "./admin.users.store";

export type UserListeners = {
  onLogOut: ThunkOn<EasyUserModel>;
};

const listeners = {
  onLogOut: thunkOn<EasyUserModel, any, StoreModel>(
    (actions, storeActions) => storeActions.auth.logOut,
    (actions, target) => {
      actions.clearState();
    },
  ),
};

export default listeners;
