import { hotjar } from "react-hotjar";
import { getEnvConfig } from "./appConfig/envConfig";

let _isInitialized = false;

export const initializeHotjar = () => {
  const { kicweb_hotjar_site_id } = getEnvConfig();
  const kicweb_hotjar_tracking_code_version = 6;
  if (!!kicweb_hotjar_site_id) {
    hotjar.initialize(
      kicweb_hotjar_site_id,
      kicweb_hotjar_tracking_code_version,
    );
    _isInitialized = true;
  }
};

export const identifyUser = ({ userId }: { userId: string }) => {
  if (!_isInitialized) return;

  hotjar.identify(userId, {});
};
