import React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 1024 1024" {...props}>
      <g stroke={props.fill} strokeMiterlimit={10} strokeWidth={10.33}>
        <path d="M329.91,1015H250.52c-10.78,0-19.52-8.38-19.52-18.71V592.22c0-10.33,8.74-18.71,19.52-18.71h79.39c10.79,0,19.52,8.38,19.52,18.71V996.29C349.43,1006.62,340.7,1015,329.91,1015Zm-59.86-37.43h40.34V610.94H270.05Z" />
        <path d="M783.72,1015H704.33c-10.79,0-19.53-8.38-19.53-18.71V592.22c0-10.33,8.74-18.71,19.53-18.71h79.39c10.78,0,19.52,8.38,19.52,18.71V996.29C803.24,1006.62,794.5,1015,783.72,1015Zm-59.87-37.43h40.34V610.94H723.85Z" />
        <path d="M810.81,610.94H223.17c-10.79,0-19.53-8.38-19.53-18.72V553.76c0-40.87,34.71-74.13,77.37-74.13H753c42.65,0,77.37,33.26,77.37,74.13v38.46c0,10.34-8.74,18.72-19.52,18.72ZM242.69,573.51H791.27V553.76c0-20.25-17.19-36.72-38.32-36.72H281c-21.11,0-38.32,16.47-38.32,36.72Z" />
        <path d="M758.41,517.07H275.59c-10.79,0-19.53-8.38-19.53-18.72V44.18c0-10.34,8.74-18.72,19.53-18.72H758.41c10.78,0,19.53,8.38,19.53,18.72V498.35C777.94,508.69,769.19,517.07,758.41,517.07ZM295.13,479.64H738.91V62.9H295.13Z" />
      </g>
    </svg>
  );
}

export default SvgComponent;
