import React from "react";

function SvgComponent(props) {
  return (
    <svg
      id="prefix__Layer_1"
      x={0}
      y={0}
      viewBox="0 0 100 97.6"
      xmlSpace="preserve"
      {...props}
    >
      <title>{props.alt ?? ""}</title>
      <path d="M69.7 44.4c-.7.4-1.1 1.3-.7 2 1.8 3.7 2.7 7.6 2.7 11.8 0 14.9-12.1 27-27 27-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5c16.5 0 30-13.4 30-30 0-4.6-1-9-3-13.1-.3-.7-1.2-1-2-.7z" />
      <path d="M76.6.6c-6.9-1.7-13.7-.6-19 3.3-3.7 2.6-5.6 4.4-7 8.7-2.5-.5-5.1-.7-7.8-.7-23.7 0-43 19.3-43 43 0 5 .9 10 2.6 14.7 4.9 13.6 16.3 23.7 30.3 27.1 3.3.8 6.7 1.2 10.1 1.2 23.7 0 43-19.3 43-43v-1.7c2-.4 2.7-1 3.9-1.9.4-.3.8-.7 1.4-1.1 10.9-7.9 12.2-24.7 3-37.4C89.7 6.7 83.5 2.3 76.6.6zm12.8 47.2c-.7.5-1.2.9-1.6 1.2-.8.6-1.1.9-2.2 1.2-.2-.7-.9-1.1-1.6-1.1-.8.1-1.4.8-1.3 1.6.1 1.3.2 2.7.2 4.1 0 22.1-18 40-40 40-3.2 0-6.4-.4-9.4-1.1-13.1-3-23.7-12.5-28.3-25.1-1.6-4.4-2.4-9-2.4-13.7 0-22.1 18-40 40-40 3.3 0 6.6.4 9.8 1.2.8.2 1.6-.3 1.8-1.1.2-.7-.2-1.5-.9-1.7 1.2-3.3 2.7-4.6 5.8-6.9C64 3 69.9 2 75.9 3.5c6.2 1.5 11.8 5.4 15.8 11 8.3 11.5 7.2 26.4-2.3 33.3z" />
      <path d="M67.2 12.8c-2.1 1.5-3.6 3.6-4.4 6.2-.3.8.2 1.6 1 1.9.1 0 .3.1.4.1l.3.3c6.5 4.2 11.6 10.2 14.8 17.2-.1.2-.1.5 0 .7.1.7.8 1.2 1.5 1.2h.3c1.7-.3 3.3-1 4.7-2 6.1-4.4 6.8-13.7 1.8-20.7-5.2-7.1-14.3-9.3-20.4-4.9zm16.7 23.1c-.6.4-1.3.8-2 1.1-3.4-7.5-8.9-13.8-15.8-18.2.7-1.4 1.6-2.6 2.9-3.5 4.7-3.4 11.9-1.5 16 4.2 4.1 5.6 3.6 13-1.1 16.4z" />
    </svg>
  );
}

export default SvgComponent;
