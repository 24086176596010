import { SendRequest } from "../makeSendRequest";
import type { MediaItem, MediaListResponse } from "./types";

export type MediaFetchParams = {
  page?: number;
  limit?: number;
  search?: string;
  type?: "audio" | "image";
  sortOrder?: "asc" | "desc";
  sortField?: "size" | "width" | "height";
};

export const createMediaApi = (sendRequest: SendRequest) => ({
  upload: async (data: FormData) =>
    sendRequest<MediaItem>({
      method: "post",
      path: "media/admin/upload",
      version: "v1",
      data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),
  list: (
    params: MediaFetchParams = {
      page: 1,
    },
  ) => {
    const queryParams = new URLSearchParams();

    Object.entries(params).forEach(([key, value]) => {
      if (value) {
        queryParams.append(key, value.toString());
      }
    });

    return sendRequest<MediaListResponse>({
      method: "get",
      path: `media?${queryParams.toString()}`,
      version: "v1",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
});
