import React from "react";

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 480 480" width="1rem" height="1rem" {...props}>
      <title>{props.alt ?? ""}</title>
      <path d="M328 0H152c-13.254 0-24 10.746-24 24v288c0 13.254 10.746 24 24 24h48v-16h-48a8 8 0 01-8-8V24a8 8 0 018-8h176a8 8 0 018 8v288a8 8 0 01-8 8h-48v16h48c13.254 0 24-10.746 24-24V24c0-13.254-10.746-24-24-24zm0 0M24 304h88v-16H24a8 8 0 01-8-8V56a8 8 0 018-8h88V32H24C10.746 32 0 42.746 0 56v224c0 13.254 10.746 24 24 24zm0 0" />
      <path d="M192 40h16v16h-16zm0 0M224 40h64v16h-64zm0 0M168 80h16v32h-16zm0 0M200 80h16v32h-16zm0 0M232 80h16v32h-16zm0 0M264 80h16v32h-16zm0 0M296 80h16v32h-16zm0 0M232 136h56v16h-56zm0 0M216 280h48c13.254 0 24-10.746 24-24s-10.746-24-24-24h-48c-13.254 0-24 10.746-24 24s10.746 24 24 24zm0-32h48a8 8 0 010 16h-48a8 8 0 010-16zm0 0M88 64h24v16H88zm0 0M32 104h16v24H32zm0 0M64 104h16v24H64zm0 0M96 104h16v24H96zm0 0M56 64h16v16H56zm0 0M64 264h48v-16H64a8 8 0 010-16h48v-16H64c-13.254 0-24 10.746-24 24s10.746 24 24 24zm0 0M456 32h-88v16h88a8 8 0 018 8v224a8 8 0 01-8 8h-88v16h88c13.254 0 24-10.746 24-24V56c0-13.254-10.746-24-24-24zm0 0" />
      <path d="M368 64h56v16h-56zm0 0M432 104h16v24h-16zm0 0M400 104h16v24h-16zm0 0M368 104h16v24h-16zm0 0M368 216v16h48a8 8 0 010 16h-48v16h48c13.254 0 24-10.746 24-24s-10.746-24-24-24zm0 0M360 472v-87.145c.148-11.94-8.273-22.273-20-24.535a24.118 24.118 0 00-19.512 5.39c-.16.13-.32.267-.472.411-9.102-8.172-22.899-8.172-32 0a24.923 24.923 0 00-24-4.762v-40.504c.152-11.94-8.274-22.273-20-24.535a23.99 23.99 0 00-19.508 5.332A23.995 23.995 0 00216 320v97.871l-21.23-12.734a21.965 21.965 0 00-21.274-.801l-1.273.633a22.127 22.127 0 00-2.957 37.793l50.086 35.765A7.967 7.967 0 00224 480h128a8 8 0 008-8zm-16-8H226.559l-48-34.281a6.137 6.137 0 01.8-10.473l1.274-.621a6.077 6.077 0 015.879.223l33.351 20A8 8 0 00232 432V320a7.995 7.995 0 019.398-7.879 8.661 8.661 0 016.602 8.734V384a8 8 0 0016 0 8 8 0 0116 0 8 8 0 0016 0 8 8 0 0116 0 8 8 0 0016 0 7.995 7.995 0 019.398-7.879 8.661 8.661 0 016.602 8.734zm0 0M192 136h24v16h-24zm0 0M192 168h96v16h-96zm0 0M208 200h64v16h-64zm0 0M64 152h48v16H64zm0 0M80 184h32v16H80zm0 0M368 152h40v16h-40zm0 0M368 184h24v16h-24zm0 0" />
    </svg>
  );
}

export default SvgComponent;
